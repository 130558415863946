import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ChilexpressService } from 'src/app/core/services/chilexpress/chlexpress.service';

@Component({
  selector: 'app-escanear-qr',
  templateUrl: './escanear-qr.component.html',
  styleUrls: ['./escanear-qr.component.scss']
})
export class EscanearQRComponent implements OnInit, OnDestroy {

  errorlectura = 0;
  datoqr = "";
  consultar;
  datosprc;
  DatosConfig;
  constructor(private router: Router, private sweetAlertService: SweetAlertService, private ChilexpressService: ChilexpressService) { }

  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.consultar = true;
    this.errorlectura = 0;
    this.ConectarQR();
    this.obtenerdatos();

  }

  async obtenerdatos(){
    this.DatosConfig = await this.ChilexpressService.GetConfig();
  }

  async consultarOT() {
    var dataot = {
      "nroOt": this.datoqr,
      "dataToken": {
        "codSistema": 16,
        "glsUsuario": "UsrTest",
        "version": "1"
      }
    }
    this.sweetAlertService.swalLoading();
    var respot = await this.ChilexpressService.consultaDetalleOt(dataot);
    var respott= false;
    if (respot["status"] == true) {
      respott = this.validarOT(respot["data"])
    }
    
    if (respot["status"] == true && this.validarOT(respot["data"])== true) {
      this.datosprc["not"] = this.datoqr;
      this.datosprc["emailEntrega"] = respot["data"]["ot"]["emailEntrega"];
      this.datosprc["emailRemitente"] = respot["data"]["ot"]["emailRemitente"];
      this.datosprc["altoPza"] = respot["data"]["ot"]["altoPza"];
      this.datosprc["anchoPza"] = respot["data"]["ot"]["altoPza"];
      this.datosprc["largoPza"] = respot["data"]["ot"]["largoPza"];
      this.datosprc["glsDimensiones"] = respot["data"]["ot"]["glsDimensiones"];
      this.datosprc["destino"] = respot["data"]["direccion"]["comuna"];
      this.datosprc["origen"] = respot["data"]["direccion"]["direccion"];
      this.datosprc["ntcc"] = respot["data"]["ot"]["nroTCC"];
      //console.log("datos proceso", this.datosprc);
      localStorage.setItem("datosproc", JSON.stringify(this.datosprc));
      var respotcc = await this.ChilexpressService.validaTcc(this.datosprc["ntcc"]);
      this.sweetAlertService.swalClose();
      if (respotcc["status"] == true) {
        if (respotcc["datTCC"]["status"]["statusCode"] == 0) {
          var respdetallecarro = await this.crearDetalleCarro(respot["data"]);
          //console.log(respdetallecarro);

          if (respdetallecarro["status"] == true && respdetallecarro["detalle"][0]["statusCode"] == 0) {
            this.router.navigate(['qrEscaneado']);
          }
          else {
            await this.sweetAlertService.ErroFrecuente();
            this.router.navigate(['menuOpciones']);
          }
        }
        else {
          await this.sweetAlertService.ErroFrecuente();
          this.router.navigate(['menuOpciones']);
        }
      }
      else {
        await this.sweetAlertService.ErroFrecuente();
        this.router.navigate(['menuOpciones']);

      }
    }
    else {
      await this.sweetAlertService.ErroFrecuente();
      this.router.navigate(['menuOpciones']);
    }
  }
  async crearDetalleCarro(OT) {
    var detallecarro = {
      "detalleCarro": {
        "idDetalle": 0,
        "idCarro": this.datosprc["idcarro"],
        "nroOt": this.datosprc["not"],
        "numCasillero": 0,//actualizar numero casillero
        "codOficinaOrigen":  this.DatosConfig["CodOfilocal"],//referenciar de config
        "codOficinaDestino": OT["oficina"]["codOficinaDestino"],
        "codProdOt": OT["ot"]["codProdOT"],
        "codServicioOt": OT["ot"]["codServicio"],
        "codTipoOt": OT["ot"]["codTipoOt"],
        "nroTcc": this.datosprc["ntcc"],
        "codOrigenOt": OT["ot"]["codOrigenOT"],
        "pesoOt": OT["ot"]["pesoOT"],
        "largoPza": OT["ot"]["largoPza"],
        "anchoPza": OT["ot"]["anchoPza"],
        "altoPza": OT["ot"]["altoPza"],
        "fechaCreacionOt": OT["ot"]["fechaCreacion"],// actualizar con servicio
        "codEstadoOt": OT["codEstadoOt"],
        "indPorCobrar": OT["indPorPagar"],
        "rutUsCreacion": this.datosprc["rutcli"],
        "ipCreacion": OT["ot"]["ipCreacion"],// ip traer de act cservicio
        "tipoEntrega": 0,
        "nroReferencia": 0,//pin de seguridad 
        "codUpv": OT["ot"]["codUPV"],
        "valorTotal": 0,
        "coberturaExtendida": 0,
        "valorDeclarado": OT["valorDeclarado"],
        "codCoberturaOrigen": "LCON",// donde esta el locker
        "codCoberturaDestino": OT["direccion"]["codCobertura"],
        "glsCoberturaDestino": OT["direccion"]["comuna"],// traer de OT
        "direccionOt": OT["direccion"]["glsZonaReparto"],
        "numeracionOt": OT["direccion"]["nroDireccion"],
        "complementoOt": OT["direccion"]["complemento"],
        "latDir": OT["direccion"]["latitud"],
        "lonDir":OT["direccion"]["longitud"],
        "codEstadoDetalle": OT["codEstadoOt"],
        "glsEstadoDetalle": "Creada" // me la tiene que pasar dani
      }
    };
    //console.log(detallecarro);
    localStorage.setItem("detallesCarro", JSON.stringify(detallecarro));

    var respcraredtallecarro = await this.ChilexpressService.CrearDetalleCarro(detallecarro);
    return respcraredtallecarro;
  }

  async scanQr() {
    //this.datoqr = "2266455380";
    await this.consultarOT();
    //this.router.navigate(['qrEscaneado']);
  }

  scanNumOrdenTransporte() {
    this.router.navigate(['numOrdenTransporte']);
  }

  volver() {
    this.router.navigate(['menuOpciones']);
  }

  async ErrorQrScan() {
    if (this.errorlectura < 3) {
      var resp = await this.sweetAlertService.OTnoAdmitidaCHE();
      this.errorlectura++;
    }
    else {
      var resp = await this.sweetAlertService.ErroFrecuente();
      if (resp) {
        this.router.navigate(['/']);
      }
    }
  }

  async LockerFull() {
    var resp = await this.sweetAlertService.ErrorLockerFull();
    if (resp) {
      this.router.navigate(['/']);
    }
  }

  async ConectarQR() {
    await this.ChilexpressService.ConectarQR();
    await this.ChilexpressService.LecturQR();
    this.optenerQR();
  }

  async optenerQR() {
    if (this.consultar == true) {
      await this.delay(1500);
      var resp = await this.ChilexpressService.LecturQR();
      if (resp["status"] == true) {
        if (resp["data"]["rut"] != "") {
          this.scanQr();
        }
        else {
          this.optenerQR();
        }
      }
      else {
        this.optenerQR();
      }
    }

  }

  validarOT(data) {
    var otreal = this.datoqr.substring(0,this.datoqr.length-1);
    var otdv = this.datoqr.substring(this.datoqr.length-1,this.datoqr.length);
    var resto = parseInt(otreal) % 7;
    //console.log((data["indBloqueo"]));
    

    if (resto == parseInt(otdv)) {
      if (data["ot"]["codProducto"] == 1 || data["ot"]["codProducto"] == 3) {
        if (data["codEstadoOt"] == 0 && (data["ot"]["codOrigenOT"] == 5 || (data["ot"]["codOrigenOT"] == 8 &&  data["ot"]["codCanalOrigen"] == 10))) {
          if (data["valorDeclarado"] < 200000 && data["ot"]["codUPV"] < 1) {
            if (data["indBloqueo"] < 1) {
              if (data["ot"]["codServicio"] !=  8 && data["ot"]["codServicio"] !=  1 && data["ot"]["codServicio"] != 10) {
                if (data["servicioSobredimensionado"] < 1) {
                  if (data["ot"]["largoPza"] < 100 && data["ot"]["anchoPza"] < 100 ||data["ot"]["altoPza"] < 120 ) {
                    //console.log("OT true");
                    
                    return true;
                  } else { console.log("muy grande el pedido");return false }
                } else { console.log("sobredimencionado");return false }
              } else { console.log("cod serv errorneo"); return false }
            } else { console.log("ot bloqueada"); return false }
          } else { console.log("valor ot no corresponde"); return false }
        } else { console.log("cod estado ot error");return false }
      }
      else { console.log("Cod producto error"); return false }
    }
    else { console.log("no se divide entre 7"); return false }
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnDestroy(): void {
    this.consultar = false;
  }

}
