import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-escanear-qr-reporte-problema',
  templateUrl: './escanear-qr-reporte-problema.component.html',
  styleUrls: ['./escanear-qr-reporte-problema.component.scss']
})
export class EscanearQrReporteProblemaComponent implements OnInit {

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
  }

  identificacionManual()
  {
    this.router.navigate(['identManualReporteProblema']);
  }

  lecturaCorrecta()
  {
    this.router.navigate(['reportarProblema']);
  }

}
