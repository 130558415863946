import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from 'src/app/core/services/auh/auth.service';
import { login } from '../../core/models/login';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { ValidatorsService } from 'src/app/core/services/miscellaneous/validators.service';

@Component({
  selector: 'app-flujo-carga-courier',
  templateUrl: './flujo-carga-courier.component.html',
  styleUrls: ['./flujo-carga-courier.component.scss']
})
export class FlujoCargaCourierComponent implements OnInit {

  constructor(private router: Router) { }

  izq = false;

  ngOnInit(): void {
    var reiniciado =  localStorage.getItem("Reiniciado");
    if (reiniciado == "no") {
      localStorage.setItem("Reiniciado","si")
      window.location.reload();
    }
  }

  izqbtn() {
    this.izq = true;
  }

  derbtn() {
    if (this.izq == true) {
      this.router.navigate(['login']);
    }
    else{
      this.izq = false;
    }
  }

  change()
  {
    this.router.navigate(['inicio2']);
  }

}
