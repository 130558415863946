<!-- <div style="color: black; position: relative; left: 950px;">
    <div>
        <h1>Selecciona tu casillero</h1><br>
        <h3>Selecciona un locker entre los disponibles para ingresar tu encomienda</h3>
    </div>
    <app-locker [Admin]="Admin" (changeStep)="GetLocker($event)" style="position: relative; right: 900px; bottom: 100px; ">
    </app-locker>
    <div style="color: black; position: relative; left: 200px; top: 50px;">
        <button (click)="continuar()">FINALIZAR OPERACIÓN</button>
    </div>
</div> -->

<div class="wrapper">
    <div class="container ">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold">Selecciona un Casillero</h1>
                <h3>El casillero seleccionado se abrirá.</h3>
                <app-locker [Admin]="Admin" (changeStep)="GetLocker($event)">
                </app-locker>
            </div>
        </div>
    </div>
    <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100"
                    (click)="continuar()">Finalizar operación</button>
            </div>
        </div>
    </div>
</div>