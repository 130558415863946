<div class="d-flex tecladoshow" *ngIf="shoekb">
    <div class="col-12 centrar-teclado" >
        <!-- Teclado -->
        <div class="caja-botones col-12">
          <div class=" boton col-4" *ngFor="let number of numberbutton; index as i; last as last">
              <div *ngIf="!last" class="contenido-boton text-center animated bounceIn" (click)="setnota(i)">
                  {{number}}</div>
              <div *ngIf="last" class="contenido-boton text-center button-borrar bgi-del"
                  (click)="borar()"><div><i class="pi pi-chevron-left"></i></div>
              </div>
          </div>
      </div>
    </div>
  </div>
  
  <div class="row">
      <div class="col-12 text-center mt-5">
        <div class="row mt-5">
          <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Ingresa tu número de orden de transporte</div>
        </div>
        <div style="color: black; position: relative; right: 30px; top: 10px;">¿Dónde encuentro mi número de OT?</div>
        <div style="position: relative; right: 20px; top: 30px;">
          <input type="text" placeholder="ingrese registro" [formControl]="numOrden">
        </div>
      </div>
  </div>
  <div class="row" style="position: relative; top: 700px;">
    <button style="width: 330px; position: relative; left: 900px; top: 60px;" (click)="continuar()">PRESIONE PARA CONTINUAR</button>
    <button style="width: 330px; position: relative; left: 950px; top: 60px;" (click)="volver()">VOLVER</button>
  </div>
  
  