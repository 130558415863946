<div style="position: relative; top: 50px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea el código QR para finalizar</div>
        <span style="color: black; position: relative; left: 1000px;">Escanea el código QR en pantalla con la aplicación Driv.in de Chilexpress para confirmar envíos retirados</span>
      </div>
      <img src="./assets/img/qrFinalizado.PNG" style="width: 250px; position: relative; top: 80px;">
    </div>
    <div class="row" style="position: relative; left: 1350px; top: 200px;">
        <button (click)="finalizar()" style="position: relative; right: 100px;">Finalizar</button>
    </div>
</div>
