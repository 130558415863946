import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-opciones2',
  templateUrl: './menu-opciones2.component.html',
  styleUrls: ['./menu-opciones2.component.scss']
})
export class MenuOpciones2Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  volver()
  {
    this.router.navigate(['inicio2']);
  }

  cerrarSesion()
  {
    this.router.navigate(['login2']);
  }

  entregarEncomienda()
  {
    sessionStorage.setItem("TipoAccion","CargaCarrier");
    this.router.navigate(['escanearQR2']);
    //this.router.navigate(['identManual']);
  }

  retirarEncomienda()
  {
    sessionStorage.setItem("TipoAccion","RetiroCarrier");
    this.router.navigate(['escanearQR2']);
   // this.router.navigate(['identManual']);
  }

  retirarPlazoCumplido()
  {
    sessionStorage.setItem("TipoAccion","PlazoCumplido");
    
    this.router.navigate(['escanearQR2']);
    //this.router.navigate(['identManual']);
  }

  revisarCasillero()
  {
    sessionStorage.setItem("TipoAccion","RevisarCasillero");
    this.router.navigate(['escanearQR2']);
    //this.router.navigate(['identManual']);
  }

  reportarProblema()
  {
    sessionStorage.setItem("TipoAccion","ReportarProblema");
    //this.router.navigate(['escanearQR2']);
    this.router.navigate(['identManual']);
  }

}
