<!-- <div style="color: black; position: relative; left: 950px;">
    <div>
        <h1>Encomiendas a retirar</h1><br>
        <h3>Los casilleros se abrirán de forma secuencial al presionar continuar</h3>
        <h3>Total de envíos a retirar</h3>
        <span>18</span>
    </div>
    <app-locker [Admin]="Admin" (changeStep)="GetLocker($event)" style="position: relative; right: 900px;">

    </app-locker>
    <div style="color: black; position: relative; left: 200px; top: 300px;">
        <button (click)="continuar()">Continuar</button>
        <button (click)="volver()">Volver</button>
    </div>
</div>RetiroCarrier

 -->
 <div class="wrapper">
    <div class="container">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Encomiendas a retirar</h1>
                <h3>Los casilleros se abrirán de forma secuencial al presionar continuar.</h3>
            </div>
            <div class="col-auto mx-auto">
                <div class="btn-light p-3 border-lg pb-1">
                    <h3>Total de envíos a retirar</h3>
                    <h1>{{detallescarro.length}}</h1>
                </div>
            </div>
            <div class="col-12 text-center mt-2">
                <h4>N° Planilla de transferencia: 100526</h4>
            </div>
            <div class="col-12 d-flex justify-content-center mt-3">
                <app-locker [Admin]="Admin" (changeStep)="GetLocker($event)">

                </app-locker>
            </div>
            
        </div>
    </div>
    <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="continuar()">Continuar</button>
            </div>
        </div>
    </div>
</div>