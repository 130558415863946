<!-- <div style="position: relative; top: 50px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea el código QR para finalizar</div>
        <span style="color: black; position: relative; left: 1000px;">Escanea el código QR en pantalla con la aplicación Driv.in de Chilexpress para confirmar piezas cargadas</span>
      </div>
      <img src="./assets/img/qrFinalizado.PNG" style="width: 250px; position: relative; top: 80px;">
    </div>
    <div class="row" style="position: relative; left: 1350px; top: 200px;">
        <button (click)="finalizar()" style="position: relative; right: 100px;">Finalizar</button>
    </div>
</div> -->


<div class="wrapper">
  <div class="container mt-5">
      <div class="row text-center">
          <div class="col-12 text-center">
              <h1 class="display-3 font-weight-semibold mt-5">Escanea el código QR para finalizar</h1>
              <h3 class="font-weight-normal">Escanea el código QR en pantalla con la<br> aplicación Driv.in de Chilexpress para<br> confirmar envíos retirados.</h3>
              <div class="box-qr mx-auto text-center" style="height: 400px;">
                  <img class="mt-5 inside-img-qr-1" style="width:300px" src="assets/img/qr-finalizar.gif">
                  <img class="mt-5 inside-img-qr-2" style="width:300px" src="assets/img/qr-finalizar.png">
              </div>
          </div>
          <div class="col-12">
              <img src="assets/img/local.svg" class="mt-5" style="height: 120px;">
              <h4>Nombre OC:</h4>
              <h1 class="mt-n2">Locker Bandera</h1>
              <h3 class="mt-4">Código OC: 2301</h3>
              <h3 class="mt-n2">RUT: 96.756.430-3</h3>
              <h4 class="mt-5">01-04-2021</h4>
          </div>
      </div>
  </div>

</div>
