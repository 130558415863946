import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-reporte-enviado',
  templateUrl: './reporte-enviado.component.html',
  styleUrls: ['./reporte-enviado.component.scss']
})
export class ReporteEnviadoComponent implements OnInit {

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
  }

  continuar()
  {
    this.router.navigate(['menuOpciones2']);
  }

}
