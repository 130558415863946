import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
//import { TarjetaLockerService } from '../../../../core/services/http/tarjeta-locker.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
//import { ApiLockerService } from '../../../../core/services/http/api-locker.service';
import { ChilexpressService } from '../../../core/services/chilexpress/chlexpress.service';
import { PruebaService } from '../../../core/services/chilexpress/prueba.service';


@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss']
})
export class LockerComponent implements OnInit, OnDestroy {

  Locker;
  pedidos;
  timer = false;
  TipoCasSelt = localStorage.getItem("TipoCasSelt");
  nroplanilla = 0;
  nroplanillaOt = 0;

  procesando = false;
  datosprc;
  detallescarro
  DatosConfig = {};
  //Admin = false;

  //output
  @Output() changeStep = new EventEmitter<any>();
  @Input() DatosPedido: any;
  @Input() Admin: any;
  constructor(private PruebaService: PruebaService, private chilexpressService: ChilexpressService, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.detallescarro = JSON.parse(localStorage.getItem("detallesCarro"));
    //console.log(this.datosprc);
    //console.log(this.Admin);

    this.chilexpressService.Conectar();
    if (this.Admin != "Precarga") {
      this.CargarLocker();
    }
    else {
      this.CargaAdminV();
    }

  }

  retorcasilleroD(casillerosd, id) {
    var exiscas = casillerosd.filter(idcas => idcas == id);
    if (exiscas.length > 0) {
      //console.log(exiscas);
      return false;
    }
    else {
      return true;
    }
  }

  async CargarLocker() {
    var primerpedido = false;
    this.Locker = await this.chilexpressService.DibujoCasillero();
    this.DatosConfig = await this.chilexpressService.GetConfig();
    //console.log(this.Admin);
    // var respuesta = await this.chilexpressService.TraerCasillero();
    ////console.log(respuesta);
    if (this.Admin == "RetiroCarrier2" || this.Admin == "PlazoCumplido2") {
      if (primerpedido == false && this.Admin == "RetiroCarrier2" || this.Admin == "PlazoCumplido2") {
        //console.log("entrando");

        var pedidos = JSON.parse(localStorage.getItem("PedidoARetirar"));
        this.DatosPedido.idcasilleron = pedidos[0]["idCasillero"];
        primerpedido = true;
        this.changeStep.emit({ step: 'CambioPedidoid', IdPuerta: this.DatosPedido.idcasilleron });
      }
    }
    if (this.Admin == "RetiroCli") {
      var resppedret = await this.PruebaService.RetirarCliente();
      if (resppedret["status"] == true) {
        for (let index = 0; index < resppedret["data"]["idPedido"].length; index++) {
          const element = resppedret["data"]["idPedido"][index];
          if (element["sPedidoSku"] == this.detallescarro["detalleCarro"]["nroOt"]) {
            this.DatosPedido.idcasilleron = element["idCasillero"];
            this.changeStep.emit({ step: 'CambioCasillero', IdPuerta: this.DatosPedido.idcasilleron });
          }
        }
      }
    }
    if (this.Admin == "CargaCarrier") {
      await this.consultartamañodisponible();
      var respcasdc = await this.PruebaService.tipoCasilletodisp(this.datosprc["tipcas"]);

      if (respcasdc["status"] == true) {
        this.DatosPedido.idcasilleron = respcasdc["data"]["casilleros"][0];
        //console.log(this.DatosPedido.idcasilleron);
      }
    }
    if (this.Admin == "Cargacli") {
      var respcasd = await this.PruebaService.tipoCasilletodisp(this.datosprc["tipcas"]);
      var casillerosd = [];
      //console.log(respcasd);
      if (respcasd["status"] == true) {
        casillerosd = respcasd["data"]["casilleros"];
        //console.log(casillerosd);
      }
      this.Locker.forEach(columna => {
        columna.Casilleros.forEach(casillero => {
          casillero.ocupado = this.retorcasilleroD(casillerosd, casillero.id);
        });
      });
    }


    // this.pedidos = respuesta;

    if (this.Admin == "RetiroCarrier2" || this.Admin == "PlazoCumplido2") {
      this.timer = true;
      this.cicloTimer();
    }
    if (this.Admin == "RetiroCli") {
      //this.DatosPedido.idcasilleron = 7;
      this.cicloTimerCargaCarrier(this.DatosPedido.idcasilleron);
      this.AbrirLocker(this.DatosPedido.idcasilleron);
    }
    if (this.Admin == "CargaCarrier") {
      this.changeStep.emit({ step: 'CambioCasillero', IdPuerta: this.DatosPedido.idcasilleron });
      this.cicloTimerCargaCarrier(this.DatosPedido.idcasilleron);
      this.AbrirLocker(this.DatosPedido.idcasilleron);
    }
    this.SweetAlertService.swalClose();
  }

  async consultartamañodisponible() { //Buscar tipo casillero para carrier 
    this.SweetAlertService.swalLoading();
    var casiD = await this.PruebaService.casillerosDisponibles();
    if (casiD["status"] == true) {
      //this.sweetAlertService.swalClose();
      var encontrado = false;
      var casillerosd = casiD["data"];
      //console.log("alto buscado", this.datosprc.altoPza);
      casillerosd.forEach(element => {
        if (element.alto >= this.datosprc.altoPza && element.total > 0 && element.tipo != "G" && encontrado == false) {
          //console.log("alto seleccionado", element.alto);
          localStorage.setItem("TipoCasSelt", element.alto);
          this.datosprc["tipcas"] = element.tipo;
          localStorage.setItem("datosproc", JSON.stringify(this.datosprc));
          encontrado = true;
        }
      });

    }
  }

  async CargaAdminV() {
    this.Locker = await this.chilexpressService.DibujoCasillero();
    if (sessionStorage.getItem("TipoAccion") == "RetiroCarrier") {
      var pedidos = JSON.parse(localStorage.getItem("PedidoARetirar"));
      //console.log(pedidos);

      pedidos.forEach(async element => {
        this.Locker.forEach(columna => {
          columna.Casilleros.forEach(casillero => {
            if (casillero.id == element["idCasillero"]) {
              casillero.ocupado = false;
              //console.log("entro");
            }
          });
        });
      });
    }
  }



  async AbrirLocker(id) {
    var pedido;
    // pedido = await this.pedidos.filter(function (array) {
    //   if (array.idCasillero == id) {
    //     return array
    //   }
    // });
    var pedido;
    this.Locker.forEach(columna => {
      columna.Casilleros.forEach(casillero => {
        if (casillero.id == id) {
          pedido = casillero;
        }
      });
    });
    //console.log(pedido);
    //si es apartura Admin
    if (this.Admin == "Admin") {
      this.AperturaAdmin(pedido, id);
    }
    // SI es carga de productos con cliente
    else if (this.Admin == "Cargacli") {
      this.CargaCli(pedido, id);
    }
    else if (this.Admin == "RetiroCli") {
      this.retiroCliente(id);
    }
    else if (this.Admin == "CargaCarrier") {
      pedido.ocupado = false;
      this.CargaCli(pedido, id);
    }
  }

  async casosPuerta(id) {
    var puertacentral = await this.chilexpressService.CasilleroCentral();
    if (id != puertacentral) {
      //this.SweetAlertService.CargandoDatos();
      var req = {
        "idPuerta": parseInt(id)
      }
      ////console.log(req);

      var respuesta = await this.chilexpressService.AbrirPuerta(req);
      //this.SweetAlertService.swalClose();
      if (respuesta["status"] == false) {
        this.SweetAlertService.PuertaNoAbiertaCHE();
        return false;
      }
      else if (this.Admin == "RetiroCli" || this.Admin == "Cargacli" || this.Admin == "CargaCarrier" || this.Admin == "RetiroCarrier2" || this.Admin == "PlazoCumplido2") {
        return true;
      }
    }
    else {
      this.SweetAlertService.PuertaNoAbiertaCHE();
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async AperturaAdmin(pedido, id) {
    if (this.Admin == "Admin") {
      this.SweetAlertService.CasilleroAbiertoCHE(id, "Revisa y")
    }
    // SI es carga de productos con cliente
    else if (this.Admin == "Cargacli") {
      this.SweetAlertService.CasilleroAbiertoCHE(id, "Ingresa la encomienda y")
    }
    else if (this.Admin == "RetiroCli") {
      this.SweetAlertService.CasilleroAbiertoCHE(id, "Retira la encomienda y")
    }
    else if (this.Admin == "CargaCarrier") {
      this.SweetAlertService.CasilleroAbiertoCHE(id, "Carga la encomienda y")
    }

    this.casosPuerta(id);
    await this.delay(5000);
    this.SweetAlertService.swalClose();

  }

  async CargaCli(pedido, id) {
    if (pedido.ocupado == true) {
      var respapert = await this.SweetAlertService.CasilleroOcupadoCHE();
    }
    else {
      var puertaabierta = await this.casosPuerta(id);
      //if (puertaabierta == true) {
      if (true) {
        //this.SweetAlertService.CasilleroAbiertoCHE(id);
        var cancelped = await this.SweetAlertService.CasilleroAbiertoCargaCHE(id);

        if (cancelped == false) {
          var idcasillero = {
            "idPuerta": parseInt(id)
          }

          // await this.delay(5000);
          var errorproceso = false;

          for (let index = 0; index < 5; index++) {
            var estadocasillero = await this.chilexpressService.Estadocasillero(idcasillero);
            //console.log(estadocasillero);

            //if (estadocasillero["status"] == true) {
            //if (estadocasillero["puertaestado"]["isEmpty"] == false && estadocasillero["puertaestado"]["isOpen"] == false) {
             if (true) {
              if (true) {
                errorproceso = true;
                this.SweetAlertService.swalClose();
                this.SweetAlertService.AdmitiendoencomiendaCHE();

                //await this.delay(5000);
                var respot = await this.RecepcionOt(id);
                var respcrg = await this.CargaLocker(id);
                if (respot == true && respcrg == true) {
                  if (this.Admin == "CargaCarrier") {
                    this.crearHistorialOtCli(id, "cargada por Carrier");
                  }
                  else {
                    this.crearHistorialOtCli(id, "cargada por cliente");
                  }

                  this.SweetAlertService.swalClose();
                  this.changeStep.emit({ step: 'Pedidohecho', IdPuerta: id, "pedido": pedido });
                }
                else {
                  await this.SweetAlertService.ErroFrecuente();
                  this.changeStep.emit({ step: 'PedidoError', IdPuerta: id, "pedido": pedido });
                }

                break;
              }
              else {
                if (estadocasillero["puertaestado"]["isOpen"] == true) {
                  var resp = await this.SweetAlertService.PuertaAunAbiertaCHE();
                }
                else if (estadocasillero["puertaestado"]["isEmpty"] == true) {
                  var resp = await this.SweetAlertService.PuertaAunVaciaCHE();
                }
                await this.casosPuerta(id);
                this.SweetAlertService.CasilleroAbiertoCHE(id, "Verifica y ");
                await this.delay(10000);
              }
            }
            else {
              this.SweetAlertService.ErrorPuerta("error a conectarse con el locker");
            }
          }
          if (errorproceso == false) {
            await this.SweetAlertService.ErrorPuerta("No se ha concretado el proceso intente nuevamente");
            this.changeStep.emit({ step: 'PedidoError', IdPuerta: id, "pedido": pedido });
          }
        }
        else {
          this.SweetAlertService.CasilleroAbiertoCHE(id, "Revisa y ");
          await this.delay(5000);
          this.SweetAlertService.swalClose();
          this.changeStep.emit({ step: 'CambioCasillero', IdPuerta: id, "pedido": pedido });
        }

      }
    }
  }
  async retiroCliente(id) {
    var resppuerta = await this.casosPuerta(id);

    //if (puertaabierta == true) {
    if (true) {
      //this.SweetAlertService.CasilleroAbiertoCHE(id);
      var idcasillero = {
        "idPuerta": parseInt(id)
      }

      await this.delay(5000);
      var errorproceso = false;

      for (let index = 0; index < 5; index++) {
        var estadocasillero = await this.chilexpressService.Estadocasillero(idcasillero);
        //console.log(estadocasillero);

        //if (estadocasillero["status"] == true) {
        //if (estadocasillero["puertaestado"]["isEmpty"] == false && estadocasillero["puertaestado"]["isOpen"] == false) {
         if (true) {
           if (true) {
            errorproceso = true;
            this.SweetAlertService.swalClose();
            //this.SweetAlertService.AdmitiendoencomiendaCHE();
            //await this.delay(5000);
            var respot = await this.DescargaOT();
            var repretiro = await this.PruebaService.RetirarPedidoLOcker(this.detallescarro["detalleCarro"]["nroOt"] + "");
            var crearhistorial = await this.crearHistorialOtCli(id, "Retirado por Cliente");
            if (respot == true) {
              this.SweetAlertService.swalClose();
              this.changeStep.emit({ step: 'Pedidohecho', IdPuerta: id });
            }
            else {
              await this.SweetAlertService.ErroFrecuente();
              this.changeStep.emit({ step: 'PedidoError', IdPuerta: id });
            }

            break;
          }
          else {
            if (estadocasillero["puertaestado"]["isOpen"] == true) {
              var resp = await this.SweetAlertService.PuertaAunAbiertaCHE();
            }
            else if (estadocasillero["puertaestado"]["isEmpty"] == true) {
              var resp = await this.SweetAlertService.PuertaAunVaciaCHE();
            }
            await this.casosPuerta(id);
            this.SweetAlertService.CasilleroAbiertoCHE(id, "Verifica y ");
            await this.delay(10000);
          }
        }
        else {
          this.SweetAlertService.ErrorPuerta("error a conectarse con el locker");
        }
      }
      if (errorproceso == false) {
        await this.SweetAlertService.ErrorPuerta("No se ha concretado el proceso intente nuevamente");
        this.changeStep.emit({ step: 'PedidoError', IdPuerta: id });
      }
    }
  }

  async RetiroCurier(id, index) {
    var salirciclos = false
    var puertaabierta = await this.casosPuerta(id);
    if (puertaabierta == true) {
      this.SweetAlertService.swalClose();
      //await this.delay(2000);
      this.SweetAlertService.CasilleroAbiertoCHE(id, "Retira y ");
      //await this.delay(3000);

      // this.pedidos.forEach(pedido => {
      //   if (pedido.pedidos != null && pedido.idCasillero > this.DatosPedido.idcasillero && salirciclos == false) {
      //     this.DatosPedido.idcasillero = pedido.idCasillero;
      //     this.changeStep.emit({ step: 'CambioPedidoid', IdPuerta: pedido.idCasillero });
      //     salirciclos = true;
      //   }
      // });
      var idcasillero = {
        "idPuerta": parseInt(id)
      }
      for (let index2 = 0; index2 < 5; index2++) {
        var estadocasillero = await this.chilexpressService.Estadocasillero(idcasillero);
        //console.log(estadocasillero);

        //if (estadocasillero["status"] == true) {
        //if (estadocasillero["puertaestado"]["isEmpty"] == true && estadocasillero["puertaestado"]["isOpen"] == false) {
         if (true) {
           if (true) {
            var respdetallecarro = await this.ActualizarDetallesCarro(id, index);
            var pedidosret = JSON.parse(localStorage.getItem("PedidoARetirar"));
            var repretiro = await this.PruebaService.RetirarPedidoLOcker(pedidosret[index]["sPedidoSku"]);
            this.crearHistorialOtCarrier(id, "Retirado por carrier", index);
            //console.log("respuesta retiro", repretiro);
            await this.TransferenciaOT(index);

            this.SweetAlertService.swalClose();
            // this.SweetAlertService.AdmitiendoencomiendaCHE();
            // await this.delay(5000);
            //this.SweetAlertService.swalClose();
            //this.changeStep.emit({ step: 'Pedidohecho', IdPuerta: id, "pedido": pedido });
            break;
          }
          else {
            if (estadocasillero["puertaestado"]["isOpen"] == true) {
              var resp = await this.SweetAlertService.PuertaAunAbiertaCHE();
            }
            else if (estadocasillero["puertaestado"]["isEmpty"] == false) {
              var resp = await this.SweetAlertService.PuertaAunVaciaCHE();
            }
            await this.casosPuerta(id);
            this.SweetAlertService.CasilleroAbiertoCHE(id, "Revisa y ");
            await this.delay(10000);
          }
        }
        else {
          this.SweetAlertService.ErrorPuerta("error a conectarse con el locker");
        }
      }

      await this.delay(3000);
      var pedidos = JSON.parse(localStorage.getItem("PedidoARetirar"));
      if (index + 1 == pedidos.length) {
        this.SweetAlertService.swalClose();
        this.timer = false;
        this.cerrarplanilla();
        this.changeStep.emit({ step: 'Pedidohecho', IdPuerta: this.DatosPedido.idcasillero });
      }
      else {
        this.DatosPedido.idcasillero = pedidos[index + 1]["idCasillero"];
        this.changeStep.emit({ step: 'CambioPedidoid', IdPuerta: pedidos[index + 1]["idCasillero"] });
        this.procesando = false;
      }
      // this.Locker.forEach(columna => {
      //   columna.Casilleros.forEach(casillero => {
      //     if (casillero.ocupado == true && casillero.id > this.DatosPedido.idcasillero && salirciclos == false) {
      //       this.DatosPedido.idcasillero = casillero.id;
      // this.changeStep.emit({ step: 'CambioPedidoid', IdPuerta: casillero.id });
      //       salirciclos = true;
      // //     }
      // //   });
      // // });

      // this.procesando = false;
      // if (salirciclos == false) {
      //   this.changeStep.emit({ step: 'Pedidohecho', IdPuerta: this.DatosPedido.idcasillero });
      // }
      // if (this.DatosPedido.idcasillero > 7) {

      // }
    }
  }

  async cicloTimer() {
    var pedidos = JSON.parse(localStorage.getItem("PedidoARetirar"));
    //console.log("PEdido en cliclo", pedidos);
    var index = 0;
    while (this.timer == true) {
      if (index < pedidos.length) {
        this.Locker.forEach(Columna => {
          Columna.Casilleros.forEach(casillero => {
            if (casillero.id == this.DatosPedido.idcasillero && pedidos[index]["idCasillero"] == casillero.id) {
              casillero.ocupado = !casillero.ocupado;
              if ((this.procesando == false && this.Admin == "RetiroCarrier2") || (this.procesando == false && this.Admin == "PlazoCumplido2")) {
                this.procesando = true;
                this.changeStep.emit({ step: 'CambioPedidoid', IdPuerta: casillero.id });
                this.RetiroCurier(casillero.id, index);
                index++;

              }
            } else {
              casillero.ocupado = true;
            }
          });
        });
      }

      await this.delay(1000);
    }
  }

  async cicloTimerCargaCarrier(id) {
    while (this.timer == true) {
      this.Locker.forEach(Columna => {
        Columna.Casilleros.forEach(casillero => {
          if (casillero.id == id) {
            casillero.ocupado = !casillero.ocupado;
          };
        });
      });
      await this.delay(2000);
    }
  }

  async RecepcionOt(id) {
    var recOt = {
      "nroOT": this.datosprc["not"],
      "codOficina": this.DatosConfig["CodOfilocal"],
      "rutCarrier": this.datosprc["rutcli"],
      "rutUsuario": "14183938",
      "codSistema": "16",// cambiar a 14
      "dataToken": {
        "codSistema": 16,// cambia a 14
        "glsUsuario": "UsrTest",
        "version": "1"
      }
    }

    var resprepOt = await this.chilexpressService.recepcionOt(recOt);
    if (resprepOt["status"] == true && resprepOt["data"]["codRespuesta"] == 0) {
      var respdetallecarro = await this.ActualizarDetalleCarro(id);
      return respdetallecarro;
    }
  }

  async ActualizarDetalleCarro(id) {
    //console.log(this.detallescarro);
    this.detallescarro["detalleCarro"]["codEstadoDetalle"] = 1;
    this.detallescarro["detalleCarro"]["glsEstadoDetalle"] = "Cargada"
    this.detallescarro["detalleCarro"]["idDetalle"] = 1;
    this.detallescarro["detalleCarro"]["nroReferencia"] = 0;
    this.detallescarro["detalleCarro"]["numCasillero"] = id;
    //console.log(this.detallescarro);
    var resp = await this.chilexpressService.ActualizarDetalleCarro(this.detallescarro);
    if (resp["status"] == true && resp["data"][0]["statusCode"] == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  async ActualizarDetallesCarro(id, index) {
    //console.log(this.detallescarro);
    this.detallescarro[index]["detalleCarro"]["codEstadoDetalle"] = 2;
    this.detallescarro[index]["detalleCarro"]["glsEstadoDetalle"] = "retirada"
    this.detallescarro[index]["detalleCarro"]["idDetalle"] = 1;
    this.detallescarro[index]["detalleCarro"]["nroReferencia"] = 0;
    this.detallescarro[index]["detalleCarro"]["numCasillero"] = id;
    //console.log(this.detallescarro[index]);
    var resp = await this.chilexpressService.ActualizarDetalleCarro(this.detallescarro[index]);
    if (resp["status"] == true && resp["data"][0]["statusCode"] == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  crearHistorialOtCli(id, estado) {
    var fecha: Date;
    fecha = new Date();
    var dia = this.gethoras(fecha)
    var otn = {
      "numeroOt": this.detallescarro["detalleCarro"]["nroOt"],
      "nombreCliente": this.datosprc["nomcli"],
      "rutCliente": this.datosprc["rutcli"],
      "estadoOt": estado,
      "casillero": id,
      "direccion": this.detallescarro["detalleCarro"]["direccionOt"],
      "remitente": this.detallescarro["detalleCarro"]["codCoberturaDestino"],
      "localidad": this.detallescarro["detalleCarro"]["codCoberturaOrigen"],
      "contenido": "Paquete",// viene en actualizacion de OT
      "valorDeclarado": this.detallescarro["detalleCarro"]["valorDeclarado"],
      "total": this.detallescarro["detalleCarro"]["valorTotal"],
      "fechaIngreso": dia,
      "fechaAdmision": dia,
      "fechaRetiro": dia,
      "fechaPlazoCumplido": dia,
      "pinRetiro": 0,
      "largo": this.detallescarro["detalleCarro"]["largoPza"],
      "alto": this.detallescarro["detalleCarro"]["altoPza"],
      "ancho": this.detallescarro["detalleCarro"]["anchoPza"],
      "estadoPago": "OK"
    }
    this.PruebaService.InsertHistorial(otn);
  }

  crearHistorialOtCarrier(id, estado, index) {
    var fecha: Date;
    fecha = new Date();
    var dia = this.gethoras(fecha)
    var otn = {
      "numeroOt": this.detallescarro[index]["detalleCarro"]["nroOt"],
      "nombreCliente": this.datosprc["nomcli"],
      "rutCliente": this.datosprc["rutcli"],
      "estadoOt": estado,
      "casillero": id,
      "direccion": this.detallescarro[index]["detalleCarro"]["direccionOt"],
      "remitente": this.detallescarro[index]["detalleCarro"]["codCoberturaDestino"],
      "localidad": this.detallescarro[index]["detalleCarro"]["codCoberturaOrigen"],
      "contenido": "Paquete",// viene en actualizacion de OT
      "valorDeclarado": this.detallescarro[index]["detalleCarro"]["valorDeclarado"],
      "total": this.detallescarro[index]["detalleCarro"]["valorTotal"],
      "fechaIngreso": dia,
      "fechaAdmision": dia,
      "fechaRetiro": dia,
      "fechaPlazoCumplido": dia,
      "pinRetiro": 0,
      "largo": this.detallescarro[index]["detalleCarro"]["largoPza"],
      "alto": this.detallescarro[index]["detalleCarro"]["altoPza"],
      "ancho": this.detallescarro[index]["detalleCarro"]["anchoPza"],
      "estadoPago": "OK"
    }
    this.PruebaService.InsertHistorial(otn);
  }

  async TransferenciaOT(index) {

    var transOt = {
      "nroOT": this.detallescarro[index]["detalleCarro"]["nroOt"],
      "codOTPlantilla": this.nroplanillaOt,
      "codOficinaOrigen": this.DatosConfig["CodOfilocal"],
      "codOficinaDestino": 795,
      "nroPlanilla": this.nroplanilla,
      "codRecorrido": this.datosprc["codrecorrido"],
      "codTriada": this.datosprc["codtriada"],
      "rutUsuario": this.datosprc["rutcli"]+"",
      "ip": "1",
      "reintento": "0",
      "codSistema": 0,
      "dataToken": {
        "codSistema": 0,
        "glsUsuario": "string",
        "version": "string"
      }
    }

    var resptrsnot = await this.chilexpressService.transferenciaOt(transOt);
    if (resptrsnot["status"] = true) {
      this.nroplanilla = resptrsnot["data"]["data"]["nroPlanilla"];
      this.nroplanillaOt = resptrsnot["data"]["data"]["codOTPlanilla"];
    }

  }

  async cerrarplanilla() {

    var cerrarplanilla = {
      "nroPlanilla": this.nroplanilla,
      "codOficina": this.DatosConfig["CodOfilocal"],
      "codOficinaDestino": 795,
      "codTriada": this.datosprc["codtriada"],
      "rutCarrier": this.datosprc["rutcli"],
      "rutUsuario": this.datosprc["rutcli"],
      "codTipoPlanilla": 2
    }
    var respceerr = await this.chilexpressService.Cerrarplanilla(cerrarplanilla);

  }

  async CargaLocker(id) {
    var carga = {
      "sku": this.detallescarro["detalleCarro"]["nroOt"] + "",
      "tipoCarga": "C",
      "rutCliente": this.datosprc["rutcli"] + "",
      "emailCliente": "",
      "idCasillero": id,
      "idLocker": 7,
      "idOficina": 6
    }
    if (this.Admin == "CargaCarrier") {
      carga.tipoCarga = "T";
    }
    var respcarg = await this.PruebaService.CargaPedidoLOcker(carga);
    if (respcarg["status"] == true) {
      return true;
    }
    else {
      return false;
    }
  }

  async DescargaOT() {
    var descarga = {
      "nroop": 1,
      "nroOT": this.detallescarro["detalleCarro"]["nroOt"] + "",
      "codOficina": this.DatosConfig["CodOfilocal"],
      "rutRecibe": parseInt(this.datosprc["rutcli"] + ""),
      "nombreRecibe": this.datosprc["nomcli"],
      "telefonoRecibe": 9999999,
      "usuario": 0,
      "swShow": 0,
      "porCobrarPos": "N",
      "nroTCC_PP": 0,
      "atributosAdicionales": 0
    }

    var respcarg = await this.chilexpressService.Descargaot(descarga);
    if (respcarg["status"] == true) {
      return true;
    }
    else {
      return false;
    }
  }

  gethoras(fecha) {
    var fechan;
    let day = fecha.getDate();
    var day2 = day + "";
    if (day < 10) {
      day2 = "0" + day;
    }
    let month = fecha.getMonth() + 1;
    let year = fecha.getFullYear();
    let hora = (fecha.getHours() < 10 ? "0" + fecha.getHours() : "" + fecha.getHours())
      + ":" + (fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : "" + fecha.getMinutes())
      + ":" + (fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : "" + fecha.getSeconds())
      + "." + fecha.getUTCMilliseconds();
    if (month < 10) {
      fechan = `${year}-0${month}-${day2} T${hora}`;
    } else {
      fechan = `${year}-${month}-${day2} T${hora}`;
    }
    return fechan;
  }

  ngOnDestroy(): void {
    this.SweetAlertService.swalClose();
    this.timer = false;
  }

}
