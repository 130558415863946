import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-imprimir-etiqueta2',
  templateUrl: './imprimir-etiqueta2.component.html',
  styleUrls: ['./imprimir-etiqueta2.component.scss']
})
export class ImprimirEtiqueta2Component implements OnInit {

  constructor(private router: Router, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
  }

  continuar()
  {
    this.router.navigate(['casilleroAbiertoRetiro']);
  }

  async imprimir()
  {
    await this.SweetAlertService.swalSuccess("Etiqueta impresa satisfactoriamente");
  }

  async errorPapel()
  {
    await this.SweetAlertService.swalInfo("No hay papel, por favor contacte al personal técnico");
  }

}
