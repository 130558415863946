<div style="position: relative; top: 50px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea el código QR</div>
        <span style="color: black; position: relative; left: 1000px;">Escanea el código QR generado por Driv.in para verificar tu identidad</span>
      </div>
    </div>
    <img src="./assets/img/leerCodigoQR.PNG" style="width: 330px; position: relative; left: 1050px; top: 100px;">
    <div class="row" style="position: relative; left: 1050px; top: 300px;">
        <button (click)="identificacionManual()" style="position: relative; right: 100px;">Identificación manual</button>
        <button (click)="lecturaCorrecta()" >Lectura correcta</button>
    </div>
</div>
