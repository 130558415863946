<!-- <div class="row">
    <div class="col-12 text-center mt-5">
      <img src="./assets/img/exitoso.gif">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Hola,</div><br>
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Te damos la bienvenida al sistema de entrega y retiro de encomiendas y documentos</div>
      </div>
    </div>
    <div class="row" style="position: relative; left: 1100px; top: 70px;">
        <button (click)="continuar()" style="position: relative; right: 60px;">PRESIONE PARA CONTINUAR</button>
        <button (click)="volver()">VOLVER</button>
    </div>
</div> -->
<div class="wrapper">
  <div class="container bienvenido-class">
      <div class="row text-center">
          <div class="col-12 text-left">
              <h1 class="display-3 font-weight-semibold" id="script">Hola,</h1>
              <h2>Te damos la bienvenida al sistema de entrega<br> y retiro de encomiendas y documentos</h2>
              <button type="button" id="MyElement" class="btn btn-primary btn-lg mt-4" (click)="continuar()">Continuar</button>
          </div>


      </div>
  </div>
</div>