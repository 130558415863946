import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ChilexpressService } from '../../../core/services/chilexpress/chlexpress.service';

@Component({
  selector: 'app-escanear-qr-finalizado2',
  templateUrl: './escanear-qr-finalizado2.component.html',
  styleUrls: ['./escanear-qr-finalizado2.component.scss']
})
export class EscanearQrFinalizado2Component implements OnInit {

  constructor(private router: Router, private sweetAlertService: SweetAlertService, private ChilexpressService:ChilexpressService) { }
  datosprc
  DatosConfig = {};
  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.finalizarCarro();
  }

  finalizar()
  {
    this.router.navigate(['menuOpciones2']);
  }

  async finalizarCarro(){
    this.DatosConfig = await this.ChilexpressService.GetConfig();
    var fincarro = {
      "carro": {
        "IdCarro": this.datosprc["idcarro"],
        "IdPortador": this.datosprc["idportador"],//14408
        "TipoOperacion": 1,
        "CodOficina": this.DatosConfig["CodOfilocal"],
        "Estado": 3,
        "RegistroGrabacion": "",
        "FolioRetiro": 0
      }
    }
    var resp = await this.ChilexpressService.FinalizarCarro(fincarro);
    if (resp["status"] == true && resp["data"]["statusCode"] == 0) {
      return true;
    }
    else {
      return false;
    }
  }

}
