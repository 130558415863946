import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-retirar-encomienda-puerta',
  templateUrl: './retirar-encomienda-puerta.component.html',
  styleUrls: ['./retirar-encomienda-puerta.component.scss']
})
export class RetirarEncomiendaPuertaComponent implements OnInit {

  casillero;
  Admin;
  pedido;

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.Admin = "RetiroCli";
    this.pedido= {
      "idcasillero":1
    }
    
    //this.continuar();
  }

  async continuar()
  {
    await this.delay(7000);
    this.router.navigate(['encomiendaIngresada']);
  }

  volver()
  {
    this.router.navigate(['tamanoCasillero']);
  }

  async GetLocker(e: any) {
    this.casillero = e.IdPuerta;
    if (e.step== "Pedidohecho") {
      this.router.navigate(['encomiendaIngresada']);
    } 
    else if (e.step== "PedidoError") {
      this.router.navigate(['menu']);
    } 
    else if (e.step== "CambioCasillero") {
      this.pedido["idcasillero"] = e.IdPuerta;
    } 

    
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
