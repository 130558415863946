<!-- <div style="color: black; position: relative; left: 900px; top: 50px;">
    <h1>Tu encomienda ha sido ingresada exitosamente</h1>
    <h3>¿cómo quieres recibir tu comprobante?</h3>
</div>
<div style="color: black; position: relative; left: 1050px; top: 100px;">
    <span (click)="imprimirComprobante()" style="cursor: pointer;">Imprimir comprobante</span><br><br>
    <span (click)="enviarEmail()" style="cursor: pointer;">Enviar al Email</span><br><br>
    <span (click)="imprimirEmail()" style="cursor: pointer;">Imprimir y enviar al Email</span>
</div>
<button (click)="continuar()" style="position: relative; left: 1100px; top: 300px;">Salir</button> -->

<div class="mt-5 ml-5">
    <h4 *ngIf="tipoAccion == 'EntregaCli'">Enviar Encomienda > ... Seleccionar Casillero > <strong> Ingreso
            Exitoso</strong></h4>
    <h4 *ngIf="tipoAccion == 'RetiroCli'">Retirar Encomienda > ... Retiro en Casillero > <strong> Retiro
            Exitoso</strong></h4>
    <h4 *ngIf="tipoAccion == 'CargaCarrier'">Cargar Encomienda > ... Seleccionar Casillero > <strong> Carga
            Exitosa</strong></h4>
</div>
<div class="wrapper">
    <div class="container mt-5">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Tu encomienda ha
                    <br> <span *ngIf="tipoAccion == 'EntregaCli'">sido ingresada exitosamente</span>
                    <span *ngIf="tipoAccion == 'RetiroCli'">sido retirada exitosamente</span>
                    <span *ngIf="tipoAccion == 'CargaCarrier'">sido cargada exitosamente</span>
                </h1>
                <h1 class="font-weight-normal mt-5 mb-5"> ¿Cómo quieres recibir tu comprobante?</h1>
            </div>
            <div class="col-8 mx-auto text-left">
                <button type="button" class="btn mt-4 btn-light btn-lg icon-lg icon-comprobante w-100 text-left"
                    (click)="imprimirComprobante()">Imprimir comprobante</button>
                <button type="button" class="btn  mt-4 btn-light btn-lg  icon-lg icon-mail w-100 text-left"
                    (click)="enviarEmail()">Enviar al email</button>
                <button type="button" class="btn mt-4 btn-light btn-lg icon-lg icon-sky w-100 text-left"
                    (click)="imprimirEmail()">Imprimir y enviar al email</button>
            </div>
        </div>
    </div>
</div>