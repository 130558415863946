import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
// import { AuthGuard } from 'src/app/guards/auth.guard';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import {ReportComponent} from 'src/app/components/report/report.component';
import {ReportePorFechaComponent} from 'src/app/components/reporte-por-fecha/reporte-por-fecha.component';
import {CedulaComponent} from 'src/app/components/cedula/cedula.component';
import {AutenticadoComponent} from 'src/app/components/autenticado/autenticado.component';
import {MenuOpcionesComponent} from 'src/app/components/menu-opciones/menu-opciones.component';
import {EscanearQRComponent} from 'src/app/components/escanear-qr/escanear-qr.component';
import {NumOrdenTransporteComponent} from 'src/app/components/num-orden-transporte/num-orden-transporte.component';
import {QrEscaneadoComponent} from 'src/app/components/qr-escaneado/qr-escaneado.component';
import {PinValidacionComponent} from 'src/app/components/pin-validacion/pin-validacion.component';
import {EncomiendaDisponibleComponent} from 'src/app/components/encomienda-disponible/encomienda-disponible.component'
import {RetirarEncomiendaPuertaComponent} from 'src/app/components/retirar-encomienda-puerta/retirar-encomienda-puerta.component';
import { EscogerTamanoCasilleroComponent } from './components/escoger-tamano-casillero/escoger-tamano-casillero.component';
import { SeleccionarCasilleroComponent } from './components/seleccionar-casillero/seleccionar-casillero.component';
import { EncomiendaIngresadaComponent } from './components/encomienda-ingresada/encomienda-ingresada.component';
import { PagarEncomiendaComponent } from './components/pagar-encomienda/pagar-encomienda.component';
import { FlujoCargaCourierComponent } from './components/flujo-carga-courier/flujo-carga-courier.component';
import { Inicio2Component } from './components/segundoFlujo/inicio2/inicio2.component';
import { MenuOpciones2Component } from './components/segundoFlujo/menu-opciones2/menu-opciones2.component';
import { EscanearQr2Component } from './components/segundoFlujo/escanear-qr2/escanear-qr2.component';
import { IdentificacionManualComponent } from './components/segundoFlujo/identificacion-manual/identificacion-manual.component';
import { QrEscaneado2Component } from './components/segundoFlujo/qr-escaneado2/qr-escaneado2.component';
import { OrdenTransporte2Component } from './components/segundoFlujo/orden-transporte2/orden-transporte2.component';
import { CasilleroAbierto2Component } from './components/segundoFlujo/casillero-abierto2/casillero-abierto2.component';
import { EscanearQrFinalizarComponent } from './components/segundoFlujo/escanear-qr-finalizar/escanear-qr-finalizar.component';
import { EncomiendaCargada2Component } from './components/segundoFlujo/encomienda-cargada2/encomienda-cargada2.component';
import { EscanearQr2DescargaComponent } from './components/segundoFlujo/escanear-qr2-descarga/escanear-qr2-descarga.component';
import { IdentificacionManualDescargaComponent } from './components/segundoFlujo/identificacion-manual-descarga/identificacion-manual-descarga.component';
import { EncomiendaARetirarComponent } from './components/segundoFlujo/encomienda-aretirar/encomienda-aretirar.component';
import { CasilleroAbiertoRetiroComponent } from './components/segundoFlujo/casillero-abierto-retiro/casillero-abierto-retiro.component';
import { ImprimirEtiqueta2Component } from './components/segundoFlujo/imprimir-etiqueta2/imprimir-etiqueta2.component';
import { CasilleroAbiertoRetiro2Component } from './components/segundoFlujo/casillero-abierto-retiro2/casillero-abierto-retiro2.component';
import { EscanearQrFinalizado2Component } from './components/segundoFlujo/escanear-qr-finalizado2/escanear-qr-finalizado2.component';
import { EscanearQrPlazoCumplidoComponent } from './components/segundoFlujo/escanear-qr-plazo-cumplido/escanear-qr-plazo-cumplido.component';
import { IdentManualPlazoCumplidoComponent } from './components/segundoFlujo/ident-manual-plazo-cumplido/ident-manual-plazo-cumplido.component';
import { EncomiendasARetirarPlazoCumplidoComponent } from './components/segundoFlujo/encomiendas-aretirar-plazo-cumplido/encomiendas-aretirar-plazo-cumplido.component';
import { CasilleroAbiertoPlazoCumplidoComponent } from './components/segundoFlujo/casillero-abierto-plazo-cumplido/casillero-abierto-plazo-cumplido.component';
import { CasilleroAbiertoPlazoCumplido2Component } from './components/segundoFlujo/casillero-abierto-plazo-cumplido2/casillero-abierto-plazo-cumplido2.component';
import { EscanearQrFinalizadoPlazoCumplidoComponent } from './components/segundoFlujo/escanear-qr-finalizado-plazo-cumplido/escanear-qr-finalizado-plazo-cumplido.component';
import { ImprimirEtiquetaPlazoCumplidoComponent } from './components/segundoFlujo/imprimir-etiqueta-plazo-cumplido/imprimir-etiqueta-plazo-cumplido.component';
import { EscanearQrRevisarCasilleroComponent } from './components/segundoFlujo/escanear-qr-revisar-casillero/escanear-qr-revisar-casillero.component';
import { IdentManualRevisarCasilleroComponent } from './components/segundoFlujo/ident-manual-revisar-casillero/ident-manual-revisar-casillero.component';
import { SeleccionarLockerRevisarCasilleroComponent } from './components/segundoFlujo/seleccionar-locker-revisar-casillero/seleccionar-locker-revisar-casillero.component';
import { EscanearQrFinalizadoRevisarCasilleroComponent } from './components/segundoFlujo/escanear-qr-finalizado-revisar-casillero/escanear-qr-finalizado-revisar-casillero.component';
import { EscanearQrReporteProblemaComponent } from './components/segundoFlujo/escanear-qr-reporte-problema/escanear-qr-reporte-problema.component';
import { ReportarProblemaComponent } from './components/segundoFlujo/reportar-problema/reportar-problema.component';
import { ReporteEnviadoComponent } from './components/segundoFlujo/reporte-enviado/reporte-enviado.component';
import { IdentManualReporteProblemaComponent } from './components/segundoFlujo/ident-manual-reporte-problema/ident-manual-reporte-problema.component';
import { RaitingComponent } from './components/raiting/raiting.component';
import { FishDirective } from '@carbon/icons-angular';
import { fromJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'report', component: ReportComponent},
  { path: 'dashboard', component: DashboardComponent}, 
  ///////////////PRIMER FLUJO///////////////////////// 
  { path: 'cedula', component: CedulaComponent},
  { path: 'autenticado', component: AutenticadoComponent},
  { path: 'menuOpciones', component: MenuOpcionesComponent},
  { path: 'escanearQR', component: EscanearQRComponent},
  { path: 'numOrdenTransporte', component: NumOrdenTransporteComponent},
  { path: 'qrEscaneado', component: QrEscaneadoComponent},
  { path: 'pinValidacion', component: PinValidacionComponent},
  { path: 'encomiendaDisponible', component: EncomiendaDisponibleComponent},
  { path: 'retirarEncomiendaPuerta', component: RetirarEncomiendaPuertaComponent},
  { path: 'tamanoCasillero', component: EscogerTamanoCasilleroComponent},
  { path: 'encomiendaIngresada', component: EncomiendaIngresadaComponent},
  { path: 'seleccionarCasillero', component: SeleccionarCasilleroComponent},
  { path: 'pagarEncomienda', component: PagarEncomiendaComponent},
  { path: 'Raiting', component: RaitingComponent},
  
  //////////////////////SEGUNDO FLUJO/////////////////////
  { path: 'login2', component: FlujoCargaCourierComponent},
  { path: 'inicio2', component: Inicio2Component},
  { path: 'menuOpciones2', component: MenuOpciones2Component},
  { path: 'escanearQR2', component: EscanearQr2Component},
  { path: 'identManual', component: IdentificacionManualComponent},
  { path: 'qrEscaneado2', component: QrEscaneado2Component},
  { path: 'ordenTransporte2', component: OrdenTransporte2Component},
  { path: 'casilleroAbierto2', component: CasilleroAbierto2Component},
  { path: 'escanearQRFinalizado', component: EscanearQrFinalizarComponent},
  { path: 'encomiendaCargada2', component: EncomiendaCargada2Component},
  { path: 'escanearQR2Descarga', component: EscanearQr2DescargaComponent},
  { path: 'identManualDescarga', component: IdentificacionManualDescargaComponent},
  { path: 'encomiendaARetirar', component: EncomiendaARetirarComponent},
  { path: 'casilleroAbiertoRetiro', component: CasilleroAbiertoRetiroComponent},
  { path: 'casilleroAbiertoRetiro2', component: CasilleroAbiertoRetiro2Component},
  { path: 'imprimirEtiqueta2', component: ImprimirEtiqueta2Component},
  { path: 'escanearQRFinalizado2', component: EscanearQrFinalizado2Component},
  /////////////////////PLAZO CUMPLIDO/////////////////////////
  { path: 'escanearQRPlazoCumplido', component: EscanearQrPlazoCumplidoComponent},
  { path: 'identManualPlazoCumplido', component: IdentManualPlazoCumplidoComponent},
  { path: 'encomiendaARetirarPlazoCumplido', component: EncomiendasARetirarPlazoCumplidoComponent},
  { path: 'casilleroAbiertoPlazoCumplido', component: CasilleroAbiertoPlazoCumplidoComponent},
  { path: 'casilleroAbiertoPlazoCumplido2', component: CasilleroAbiertoPlazoCumplido2Component},
  { path: 'escanearQRFinalizadoPlazoCumplido', component: EscanearQrFinalizadoPlazoCumplidoComponent},
  { path: 'imprimirEtiquetaPlazoCumplido', component: ImprimirEtiquetaPlazoCumplidoComponent},
  /////////////////////REVISAR CASILLERO/////////////////////
  { path: 'escanearQrRevisarCasillero', component: EscanearQrRevisarCasilleroComponent},
  { path: 'identManualRevisarCasillero', component: IdentManualRevisarCasilleroComponent},
  { path: 'seleccionarLockerRevisarCasillero', component: SeleccionarLockerRevisarCasilleroComponent},
  { path: 'escanearQrFinalizadoRevisarCasillero', component: EscanearQrFinalizadoRevisarCasilleroComponent},
  /////////////////////REPORTAR PROBLEMA/////////////////////
  { path: 'escanearQrReporteProblema', component: EscanearQrReporteProblemaComponent},
  { path: 'identManualReporteProblema', component: IdentManualReporteProblemaComponent},
  { path: 'reportarProblema', component: ReportarProblemaComponent},
  { path: 'reporteEnviado', component: ReporteEnviadoComponent},


  ////////////////////////////////////////////////////////
  { path: 'cierrePorFecha', component: ReportePorFechaComponent}, 
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
