import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-casillero-abierto2',
  templateUrl: './casillero-abierto2.component.html',
  styleUrls: ['./casillero-abierto2.component.scss']
})
export class CasilleroAbierto2Component implements OnInit {

  Admin;
  pedido;
  casillero;

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.Admin = sessionStorage.getItem("TipoAccion");
    this.pedido= {
      "idcasillero":0
    }
    //this.cargaCorrecta();
  }

  finalizarCarga()
  {
    this.router.navigate(['escanearQRFinalizado']);
  }

  async cargaCorrecta()
  {
    await this.delay(10000);
    this.router.navigate(['escanearQRFinalizado']);
  }

  async errorPuerta()
  {
    await this.sweetAlertService.swalErrorPuerta("Vuelve a ingresar tu encomienda. Si el problema persiste, entrega tu encomienda al encargado de la oficina");
    this.router.navigate(['casilleroAbierto2']);
  }

  async GetLocker(e: any) {
    this.casillero = e.IdPuerta;
    if (e.step== "Pedidohecho") {
      this.router.navigate(['qrEscaneado2']);
    } 
    else if (e.step== "PedidoError") {
      this.router.navigate(['menu']);
    } 
    else if (e.step== "CambioPedidoid") {
      this.pedido.idcasillero = e.IdPuerta;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


}
