import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { PruebaService } from '../../core/services/chilexpress/prueba.service';

@Component({
  selector: 'app-qr-escaneado',
  templateUrl: './qr-escaneado.component.html',
  styleUrls: ['./qr-escaneado.component.scss']
})
export class QrEscaneadoComponent implements OnInit {

  constructor(private PruebaService: PruebaService, private router: Router, private sweetAlertService: SweetAlertService) { }
  datosprc;
  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.continuar();
  }

  async continuar() {
    var etiquetaimpresa = await this.sweetAlertService.EtiquetaImpresa();
    if (etiquetaimpresa == true) {
      this.sweetAlertService.swalClose()
    }
  }

  Siguiente() {
    //this.router.navigate(['tamanoCasillero']); 
    this.consultartamañodisponible();
    // localStorage.setItem("TipoCasSelt", this.datosprc.altoPza);
    // this.router.navigate(['seleccionarCasillero']);

  }

  scanNumOrdenTransporte() {
    this.router.navigate(['numOrdenTransporte']);
  }

  async imprimirEtiqueta(texto) {
    var impresa = await this.sweetAlertService.EtiquetapegadaCHe(texto);
    // if (impresa) {
    //   this.imprimirEtiqueta("reimprimiendo");
    // }
    // else {
    //   this.continuar();
    // }
    //this.router.navigate(['imprimirEtiqueta'])
  }

  volver() {
    this.router.navigate(['menuOpciones']);
  }

  pago() {
    this.router.navigate(['pagarEncomienda']);
  }

  async Errorimpresion() {
    var resp = await this.sweetAlertService.ImpresoraError();
    if (resp) {
      this.router.navigate(['/']);
    }
  }

  async consultartamañodisponible() {
    this.sweetAlertService.swalLoading();
    var casiD = await this.PruebaService.casillerosDisponibles();
    if (casiD["status"]==true) {
      //this.sweetAlertService.swalClose();
      var encontrado = false;
      var casillerosd = casiD["data"];
      //console.log("alto buscado",this.datosprc.altoPza);
      casillerosd.forEach(element => {
        if (element.alto >= this.datosprc.altoPza && element.total > 0 && element.tipo !="G" && encontrado == false) {
          //console.log("alto seleccionado",element.alto);
          localStorage.setItem("TipoCasSelt", element.alto);
          this.datosprc["tipcas"]= element.tipo;
          localStorage.setItem("datosproc", JSON.stringify(this.datosprc));
          encontrado = true
          this.router.navigate(['seleccionarCasillero']);
        } 
      });
      
    }
  }

}
