<!-- <div class="d-flex tecladoshow" *ngIf="shoekb">
    <div class="col-12 centrar-teclado" >
        <!-- Teclado 
        <div class="caja-botones col-12">
          <div class=" boton col-4" *ngFor="let number of numberbutton; index as i; last as last">
              <div *ngIf="!last" class="contenido-boton text-center animated bounceIn" (click)="setnota(i)">
                  {{number}}</div>
              <div *ngIf="last" class="contenido-boton text-center button-borrar bgi-del"
                  (click)="borar()"><div><i class="pi pi-chevron-left"></i></div>
              </div>
          </div>
      </div>
    </div>
  </div>
  
  <div class="row">
      <div class="col-12 text-center mt-5">
        <div class="row mt-5">
          <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Identificación manual</div>
        </div>
        <div style="color: black; position: relative; right: 30px; top: 10px;">¿Dónde encuentro mi número de OT?</div>
        <div style="position: relative; right: 20px; top: 30px;">
          <input type="text" placeholder="Rut" [formControl]="rut"><br>
          <input type="text" placeholder="Clave" [formControl]="clave">
        </div>
      </div>
  </div>
  <div style="position: relative; top: 700px;">
    <button style="width: 330px; position: relative; left: 1100px; top: 20px;" (click)="continuar()">INGRESAR</button><br>
    <button style="width: 330px; position: relative; left: 1100px; top: 60px;" (click)="volver()">VOLVER</button>
  </div> -->
  

  <div class="wrapper">
    <div class="container mt-5">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Identificación manual</h1>
            </div>
            <div class="col-6 mx-auto mt-5">
                <form>
                    <div class="form-group text-left mt-2">
                        <label for="exampleInputEmail1"><b class="bold">RUT</b></label>
                        <input readonly [formControl]="rut" type="email" class="form-control form-control-lg text-center" placeholder="Escanea tu carnet ">
                        <span style="color: red;"> {{Rutnvalida}}</span>
                        <img style="width: 148px; margin-left: 90px;  margin-top: 10px;"src='assets/img/animacion-cedula.gif'>
                    </div>
                    <div class="form-group text-left mt-2 mb-4">
                      <label for="exampleInputEmail1"><b class="bold">N° Folio / Clave</b></label> 
                      <input (click)="TipoIput('F')" [formControl]="clave" type="email" class="form-control form-control-lg text-center" placeholder="Ej: 845693254">
                      <span style="color: red; font-size: 14px;"> {{FolioInvalido}}</span>
                  </div>
                </form>
                <div class="row col-11 pl-2 mx-auto mt-5">
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(1)">
                            1
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(2)">
                            2
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(3)">
                            3
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(4)">
                            4
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(5)">
                            5
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(6)">
                            6
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(7)"> 
                            7
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(8)">
                            8
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(9)">
                            9
                        </div>

                    </div>
                    <div class="col-4 offset-4">
                        <div class="tecla-key btn-light" (click)="setnota(0)">
                            0
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="borar()">
                            <img style="height: 23px;margin-bottom: 5px;" src="assets/img/backspace.svg">
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
    
    <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <span (click)="this.rut.setValue('14183938-9')">emular cedula</span>
                <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="ValidaCarrier()">Continuar</button>
                <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100" (click)="volver()">Volver</button>

            </div>
        </div>

    </div>
</div>
  