<!-- <div class="row" style="position: relative; top: 350px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black; bottom: 250px;">Encomienda Disponible</div>
        <div style="position: relative; left: 400px; bottom: 150px;">
            <h4 style="color: black; position: relative; left: 860px;"><b>Origen</b></h4><br>
            <h5 style="color: black; position: relative; left: 860px; top: 1px;">Pudahuel</h5><br>
            <h4 style="color: black; position: relative; left: 860px;"><b>Destino</b></h4><br>
            <h5 style="color: black; position: relative; left: 860px; top: 1px;">Concepcion</h5>
        </div>
      </div>
    </div>
    <div>
        <h5  style="color: black; position: relative; left: 1240px; bottom: 50px;">Total a pagar</h5>
        <span style="color: black; position: relative; left: 1250px; bottom: 40px;"><b>Envío Pagado</b></span>
    </div>
    <div class="row" style="position: relative; left: 1050px; top: 80px;">
        <button (click)="retirarEncomienda()" style="position: relative; right: 160px;">RETIRAR ENCOMIENDA</button>
        <button (click)="volver()" style="position: relative; left: 1px; width: 150px;">CERRAR SESIÓN</button>
    </div>
    <button (click)="pagarEncomienda()">envio por pagar</button>
        
</div> -->
<div class="mt-5 ml-5">
    <h4>Retiro Encomienda > ...Ingresar PIN > <strong> Datos Encomienda</strong></h4>
  </div>
<div class="wrapper">
  <div class="container mt-5">
      <div class="row text-center">
          <div class="col-12 text-center">
              <h1 class="display-3 font-weight-semibold mt-5">Encomienda<br>disponible</h1>

          </div>
          <div class="col-6 mx-auto mt-5 box-animado-encomienda">
              <img style="height: 120px;" class="inside-pop" src="assets/img/box.svg">

          </div>

          <div class="col-11 text-center mx-auto">
              <h4>Origen</h4>
              <h1 class="mt-n2">{{datosprc["origen"]}}</h1>
              <h4 class="mt-4">Destino</h4>
              <h1 class="mt-n2">{{datosprc["destino"]}}</h1>


          </div>
          <div class="col-auto mx-auto mt-5 pt-5">
              <div class="btn-light p-4 border-lg">
                  <h3>Total a pagar</h3>
                  <h1>Envio Pagado</h1>
              </div>
          </div>




      </div>
  </div>
  <div class="botonera-navegacion">
      <div class="row">
          <div class="col-8 mx-auto">

              <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="retirarEncomienda()">Retirar encomienda</button>


          </div>
      </div>

  </div>
</div>
