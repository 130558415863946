import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class ChilexpressService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }

    async GetConfig(): Promise<any> {
     
        const API_URL = await this.configService.getConfig();
       
        try {
            var resp = API_URL;
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    
    async CrearDetalleCarro(DetalleCarro): Promise<any> {
        console.log("Datos enviados Crear detlle Carro:", DetalleCarro);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/CrearDetalleCarro`
        try {
            var resp = await this.httpClient.post(url, DetalleCarro, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }


    async FinalizarCarro(DetalleCarro): Promise<any> {
        console.log("Datos enviados Fin Carro:", DetalleCarro);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/FinalizarCarro`
        try {
            var resp = await this.httpClient.put(url, DetalleCarro, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async ActualizarDetalleCarro(DetalleCarro): Promise<any> {
        console.log("Datos enviados Actualizar Detalle carro:", DetalleCarro);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ActualizarDetalleCarro`
        try {
            var resp = await this.httpClient.put(url, DetalleCarro, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }


    async actualizadorPortador(actualizarPortador): Promise<any> {
        console.log("Datos enviados Actualizar POrtador:", actualizarPortador);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/Crear/ActualizarPortador`
        try {
            var resp = await this.httpClient.post(url, actualizarPortador, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getPortador(portador): Promise<any> {
        console.log("Datos enviados Obtener Portador:", portador);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/GetPortador?portador=` + portador;
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async crearCabeceraCarro(crearCabeceraCarro): Promise<any> {
        console.log("Datos enviados Crear Cabecera Carro:", crearCabeceraCarro);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/CrearCarro`
        try {
            var resp = await this.httpClient.post(url, crearCabeceraCarro, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async Cerrarplanilla(plantilla): Promise<any> {
        console.log("Datos enviados Cerrar Planilla:", plantilla);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/Cerrarplanilla`
        try {
            var resp = await this.httpClient.post(url, plantilla, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async actualizarCabecera(actualizarCabecera): Promise<any> {
        console.log("Datos enviados Act Cabecera:", actualizarCabecera);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ActualizarCabecera`
        try {
            var resp = await this.httpClient.put(url, actualizarCabecera, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    ////////////////////////////////CASILLERO////////////////////////////////////////
    async DibujoCasillero(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const locker = API_URL['locker'];

        try {
            return locker;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async CasilleroCentral(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        return API_URL['CasilleroP'];
    }

    async TraerCasillero(): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        ////console.log(API_URL);

        const url = `${API_URL['api_dibujo_locker']}/Casillero`;
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////

    async validaTcc(filtros): Promise<any> {
        console.log("Datos enviados Validar TCC:", filtros);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ValitaTCC?numot=` + filtros;
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async listaCoberturas(filtros): Promise<any> {
        console.log("Datos enviados Lista coberturas:", filtros);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ListaCoberturas?type=` + filtros["type"] + `&regionCode=` + filtros["regionCode"];
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getCotizador(filtros): Promise<any> {
        console.log("Datos enviados Obtener Cotizador:", filtros);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/Cotizador?
        ciudadOrigen=`+ filtros["ciudadOrigen"]
            + `&ciudadDestino=` + filtros["ciudadDestino"]
            + `&codProducto=` + filtros["codProducto"]
            + `&peso=` + filtros["peso"]
            + `&largo=` + filtros["largo"]
            + `&ancho=` + filtros["ancho"]
            + `&alto=` + filtros["alto"]
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async serviciosAdicionales(filtros): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ServiciosAdicionales?
        codProducto=`+ filtros["codProducto"]
            + `&ciudadOrigen=` + filtros["ciudadOrigen"]
            + `&ciudadDestino=` + filtros["ciudadDestino"]
            + `&peso=` + filtros["peso"]
            + `&alto=` + filtros["alto"]
            + `&ancho=` + filtros["ancho"]
            + `&largo=` + filtros["largo"]
            + `&codFormaPago=` + filtros["codFormaPago"]
            + `&valorDeclarado=` + filtros["valorDeclarado"]
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async obtenerPagos(filtros): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ObtenerPagos?idcarro=` + filtros["idcarro"];
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async obtenerTransaccion(filtros): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ObtenerTransaccion?idcarro=` + filtros["idcarro"];
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async actualizarUsuario(actualizarUsuario): Promise<any> {
        console.log("Datos enviados Actualizar usuario:", actualizarUsuario);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ActualizarUsuario`
        try {
            var resp = await this.httpClient.put(url, actualizarUsuario, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async validarUsuarios(validarUsuarios): Promise<any> {
        console.log("Datos enviados Validar Usuarios:", validarUsuarios);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ValidarUsuario`
        try {
            var resp = await this.httpClient.post(url, validarUsuarios, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async obtenerBoleta(filtros): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}ChExpress/ObtenerBoleta?
        documentType=`+ filtros["documentType"];
        +`&documentNumber=` + filtros["documentNumber"]
            + `&indRequireBinario=` + filtros["indRequireBinario"]
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async generarBoleta(generarBoleta): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/Generaboleta`
        try {
            var resp = await this.httpClient.post(url, generarBoleta, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async token(filtros): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/Token?
        GlsUsuario=`+ filtros["GlsUsuario"];
        +`&CodSistema=` + filtros["CodSistema"]
            + `&Version=` + filtros["Version"]
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async recepcionOt(recepcionOt): Promise<any> {
        console.log("Datos enviados recepcion OT:", recepcionOt);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/RecepcionOT`
        try {
            var resp = await this.httpClient.post(url, recepcionOt, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async Descargaot(DescargaOt): Promise<any> {
        console.log("Datos enviados Descarga OT:", DescargaOt);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/DescargaOT`
        try {
            var resp = await this.httpClient.post(url, DescargaOt, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async cargaRecorrido(cargaRecorrido): Promise<any> {
        console.log("Datos enviados Carga Recorrido:", cargaRecorrido);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/CargaRecorrido`
        try {
            var resp = await this.httpClient.post(url, cargaRecorrido, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async cargaVehiculo(cargaVehiculo): Promise<any> {
        console.log("Datos enviados Carga Vehiculo:", cargaVehiculo);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/CargaVehiculo`
        try {
            var resp = await this.httpClient.post(url, cargaVehiculo, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async consultarOficina(consultarOficina): Promise<any> {
        console.log("Datos enviados Get Oficina:", consultarOficina);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ConsultarOficina`
        try {
            var resp = await this.httpClient.post(url, consultarOficina, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async transferenciaOt(transferenciaOt): Promise<any> {
        console.log("Datos enviados Transferencia Ot:", transferenciaOt);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/TransferenciaOT`
        try {
            var resp = await this.httpClient.post(url, transferenciaOt, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async retencionOt(retencionOt): Promise<any> {
        console.log("Datos enviados recepcion Ot:", retencionOt);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/RetencionOT`
        try {
            var resp = await this.httpClient.put(url, retencionOt, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async genTriada(consultarOficina): Promise<any> {
        console.log("Datos enviados Generar Triada:", consultarOficina);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/GenTriada`
        try {
            var resp = await this.httpClient.post(url, consultarOficina, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async validacionCarrier(validacionCarrier): Promise<any> {
        console.log("Datos enviados Valida Carrier:", validacionCarrier);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ValidacionCarrier`
        try {
            var resp = await this.httpClient.post(url, validacionCarrier, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async consultaDetalleOt(consultaDetalleOt): Promise<any> {
        console.log("Datos enviados Consulta Ot:", consultaDetalleOt);
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/ChExpress/ConsultaDetalleOT`
        try {
            var resp = await this.httpClient.post(url, consultaDetalleOt, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }




    ///.-------------------------- Servicio Locker puertas --------------------------

    async Conectar(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/ActivarPuerta`;

        try {
             return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
            //var resp = await  this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ",resp);
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async AbrirPuerta(request): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/AbrirPuerta`;

        try {
            // var resp = await this.httpClient.post(url, request, { headers: this.headers }).toPromise();
            // console.log("respuesta de servicio: ", resp);
            // return  resp;
           return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async ImprimirPrueba(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['Api_Impresion']}`;
        var pdf = {
            "document": API_URL['Cupon']
        }

        try {
            var resp = await this.httpClient.post(url, pdf, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
            //return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async LecturQR(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['Api_QR']}/ObtenerUltimoRegistro`;

        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
            //return { status: false, code: 200, message: 'Error al ejecutar la petición.' };
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async ConectarQR(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['Api_QR']}/Conectar`;

        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
            //return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async AbrirTodasPuerta(): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/AbrirTodasPuerta`;

        try {
            return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
            //var resp = await  this.httpClient.get(url, { headers: this.headers }).toPromise();status: true, code: 200, message: 'Error al ejecutar la petición.' };
            //console.log("respuesta de servicio: ",resp);
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async Estadocasillero(req): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/EstadoLockerCasillero`;

        try {
            var resp = await this.httpClient.post(url, req, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
            // return{
            //     "status": true,
            //     "codigo": 0,
            //     "mensaje": "string",
            //     "puertaestado": {
            //       "pinNumber": 0,
            //       "pinValue": 0,
            //       "isOpen": true,
            //       "isEmpty": false,
            //       "lockerNumber": 0,
            //       "boardAddress": 0
            //     }
            //   }
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async Apagar(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/Apagar`;

        try {
            return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
            //var resp = await  this.httpClient.get(url, { headers: this.headers }).toPromise();status: true, code: 200, message: 'Error al ejecutar la petición.' };
            //console.log("respuesta de servicio: ",resp);
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async Reiniciar(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/Reiniciar`;

        try {
            return { status: true, code: 200, message: 'Error al ejecutar la petición.' };
            //var resp = await  this.httpClient.get(url, { headers: this.headers }).toPromise();
            // console.log("respuesta de servicio: ",resp);//
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async EstatusTodasPuerta(): Promise<any> {
        console.log("Datos enviados :");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['PuertaAPI']}/EstatusTodasPuerta`;

        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp;
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    ///.-------------------------- Servicio Locker puertas --------------------------

















}