import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-identificacion-manual-descarga',
  templateUrl: './identificacion-manual-descarga.component.html',
  styleUrls: ['./identificacion-manual-descarga.component.scss']
})
export class IdentificacionManualDescargaComponent implements OnInit {

  Enconfig: boolean;
  numberbutton;

  constructor(private router: Router, private emitTecladoService: EmitTecladoService, private sweetAlertService: SweetAlertService) { }

  shoekb;
  tipok;
  subscription: Subscription = new Subscription();
  rut = new FormControl('');
  clave = new FormControl('');

  ngOnInit(): void {
    this.Enconfig = true;
    this.shoekb = true;
    this.ConfigurarTeclado();
    var esto = this;
  }

  volver()
  {
    this.router.navigate(['escanearQR2']);
  }

  async continuar()
  {
    if(this.rut.value == 123)
    { 
      this.router.navigate(['casilleroAbiertoRetiro']);
      //console.log("NUMERO", this.rut.value);
    }
    else if(this.rut.value === '')
    {
      await this.sweetAlertService.swalError("Debe ingresar un número de orden")
    }
    else if(this.rut.value !== 123)
    {
      await this.sweetAlertService.swalError("Ingrese nuevamente el código");
    }
    
  }

  desplegarteclado(): void {
    this.shoekb = true;
  }

  // AsignrValorRut(rut) {
  //   var rutvalido = true;
  //   if (rutvalido) {
  //     this.rutvalido = true;
  //     this.numOrden.setValue(this.ValidatorsService.formaterRut(rut));
  //     this.SweetAlertService.swalSuccess("Rut leido Correctamente");
  //   }
  //   else {
  //     this.SweetAlertService.swalErrorgeneral("Error QR", "Ha ocurrido un error al realizar la lectura del QR");
  //   }
  // }

  borar() {
      if (this.rut.value != "") {
        this.rut.setValue(this.rut.value.substring(0, this.rut.value.length - 1))
      }
  }

  setnota(número) {
    if (this.numberbutton[número] == "X") {
      this.shoekb = true;
    }
    else{
      if (this.rut.value.includes(",") && this.clave.value.includes(",")) {
        if (this.numberbutton[número] != ",") {
          this.rut.setValue(this.rut.value + this.numberbutton[número]);
          this.clave.setValue(this.clave.value + this.numberbutton[número]);
        }
      } else if (this.rut.value.length <= 11) {
        this.rut.setValue(this.rut.value + this.numberbutton[número]);
      }
    }

    
  }

  ConfigurarTeclado() {
    this.numberbutton = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", ""];
  }



}
