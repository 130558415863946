import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Movimiento } from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { UsuarioService } from 'src/app/core/services/http/usuario.service';
import { DenominacionService } from 'src/app/core/services/http/denominacion.service';
import {Oficina} from 'src/app/core/models/oficina';
import { ErrorService } from '../../core/services/http/error.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PrimeNGConfig } from 'primeng/api';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { HttpResponse } from '@angular/common/http';
import {PruebaService} from '../../core/services/chilexpress/prueba.service';
import {ChilexpressService}from '../../core/services/chilexpress/chlexpress.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  portador   = new FormControl('');
  type       = new FormControl('');
  regionCode = new FormControl('');
  ciudadOrigen = new FormControl('');
  ciudadDestino = new FormControl('');
  codProducto = new FormControl('');
  peso = new FormControl('');
  largo = new FormControl('');
  ancho = new FormControl('');
  alto = new FormControl('');
  codFormaPago = new FormControl('');
  valorDeclarado = new FormControl('');
  idcarro = new FormControl('');
  documentType = new FormControl('');
  documentNumber = new FormControl('');
  indRequireBinario = new FormControl('');
  GlsUsuario = new FormControl('');
  CodSistema = new FormControl('');
  Version = new FormControl('');
  numot = new FormControl('');
 
  DatosConfig = {};

  constructor(private primengConfig: PrimeNGConfig, private PruebaService: PruebaService, private chilexpressService: ChilexpressService) 
  { 

  }

  async ngOnInit() 
  {
    this.DatosConfig = await this.chilexpressService.GetConfig();
  }

  async ActualizarPortador()
  {
    var actualizarPortador = 
      {
        "portador": {
          "id_portador": 10018,
          "id_tipo_persona": 2,
          "tipo_persona": "EMPRESA",
          "cod_tipo_identificador": 1,
          "tipo_identificador": "RUT",
          "codigo_identificador": "77170488",
          "gls_nombre": "Sch Group spa",
          "key_carga": 77170488
        },
        "contacto": [
          {
            "id_contacto": 27653,
            "id_uso_contacto": 2,
            "uso_contacto": "REMITENTE",
            "id_tipo_contacto": 1,
            "tipo_contacto": "CORREO",
            "gls_contacto": "gto.yordan@gmail.com",
            "status_registro": "USADO ALGUNA VEZ"
          }
        ],
        "direcciones": [
          {
            "id_direccion": 0,
            "tipo_uso_contacto_direccion": 2,
            "uso_contacto_direccion": "REMITENTE",
            "cod_tipo_direccion": 1,
            "tipo_direccion": "DOMICILIO",
            "gls_direccion": "LAS ORQUIDEAS",
            "num_direccion": 4555,
            "gls_complemento": "EDIFICIO",
            "latitud": 0.0,
            "longitud": 0.0,
            "cod_cobertura_Alfa": "ECEN",
            "status_registro": "USADO ALGUNA VEZ"
          }
        ]
      }
    await this.chilexpressService.actualizadorPortador(actualizarPortador);
  }

  async GetPortador()
  {
    var filtros = {
      portador: this.portador.value
    }
    await this.chilexpressService.getPortador(filtros);
  }

  async CrearCabeceraCarro()
  {
    var filtros =
    {
      "carro": {
        "idCarro": 10018,
        "idPortador": 1,
        "tipoOperacion": 765,
        "codOficina": this.DatosConfig["CodOfilocal"],
        "fechaCreacion": "2021-05-03T17:18:22.850Z",
        "estado": 0,
        "registroGrabacion": "string",
        "folioRetiro": 0
      }
    }
    await this.chilexpressService.crearCabeceraCarro(filtros);
  }

  //EN DOCUMENTO EXCEL APARECE COMO PUT
  // async CrearDetalleCarro()
  // {
      // var filtros = {
      // "IdDetalle":53,
      // "IdCarro":90,
      // "NroOt":701001109999,
      // "NumCasillero":11,
      // "CodOficinaOrigen":795,
      // "CodOficinaDestino":200,
      // "CodProdOt":3,
      // "CodServicioOt":2,
      // "CodTipoOt":1,
      // "NroTcc":1020011,
      // "CodOrigenOt":11,
      // "PesoOt":1,
      // "LargoPza":10,
      // "AnchoPza":10,
      // "AltoPza":10,
      // "FechaCreacionOt":null,
      // "CodEstadoOt":1,
      // "IndPorCobrar":1,
      // "RutUsCreacion":15910400,
      // "IpCreacion":"10.0.0.10",
      // "TipoEntrega":2,
      // "NroReferencia":"122212",
      // "CodUpv":-3,
      // "ValorTotal":19000,
      // "CoberturaExtendida":1000,
      // "ValorDeclarado":1000000,
      // "RutEntrega":15910400,
      // "NombreEntrega": "Roberto Castro",
      // "TelefonoEntrega":"5690100011",
      // "EmailEntrega":"correo@correo.cl",
      // "CelularEntrega":"5699882212",
      // "FechaHoraEntrega":null,
      // "CodCoberturaOrigen":"LCON",
      // "CodCoberturaDestino":"CONC",
      // "GlsCoberturaDestino":"CONCEPCION",
      // "DireccionOt":"Las orquideas",
      // "NumeracionOt":"4918",
      // "ComplementoOt":"casa del aldo",
      // "LatDir":-33.463046,
      // "LonDir":-70.7051383,
      // "CaN":null,
      // "IdDireccion":null,
      // "CpN":null,
      // "IdBlock":null,
      // "RutRemitente":17021641,
      // "DvRemitente":"5",
      // "NombreRemitente":"Julio Bascuñan Sóto",
      // "EmailRemitente":"destino@destino.cl",
      // "CelularRemitente":"56987116522",
      // "TelefonoRemitente":"56927654322",
      // "CodCoberturaRemitente":"ECEN",
      // "GlsCoberturaRemitente":"ESTACION CENTRAL",
      // "CalleRemitente":"Apola",
      // "NumeracionRemitente":"7885",
      // "ComplementoRemitente":"Despartamento 191",
      // "CodEstadoDetalle": 1,
      // "GlsEstadoDetalle":"Creada",
      // "FechaRegistroTZ":null,
      // "FechaRegistroOrig":null,
      // "IndSincronizacion":null,
      // "NroIntento":null,
      // "GlsErrorSincronizacion":null,
      // "XmlSalidaImg":null,
      // "XmlSalidaEpl":null
  //     }
  //   }
  //   await this.chilexpressService.getPortador(filtros);
  // }

  async ActualizarCabecera()
  {
    
    var filtros = {
      "carro": {
        "IdCarro": "104",
        "IdPortador": 125901,
        "CodOficina": this.DatosConfig["CodOfilocal"],
        "TipoOperacion": 1,
        "Estado": 1,
        "RegistroGrabacion": "",
        "FolioRetiro": 0
      }
    }
    await this.chilexpressService.actualizarCabecera(filtros);
  }

  async ValitTCC()
  {
    var filtros = 
    {
        numot: this.numot.value
    }
    await this.chilexpressService.validaTcc(filtros);
  }

  async ListaCoberturas()
  {
    var filtros = {
      type: this.type.value,
      regionCode: this.regionCode.value
    }
    await this.chilexpressService.listaCoberturas(filtros);
  }

  async Cotizador()
  {
    var filtros = 
    {
      ciudadOrigen: this.ciudadOrigen.value,
      ciudadDestino: this.ciudadDestino.value,
      codProducto: this.codProducto.value,
      peso: this.peso.value,
      largo: this.largo.value,
      ancho: this.ancho.value,
      alto: this.alto.value
    }
    await this.chilexpressService.getCotizador(filtros);
  }

  async ServiciosAdicionales()
  {
    var filtros =
    {
      codProducto: this.codProducto.value,
      ciudadOrigen: this.ciudadOrigen.value,
      ciudadDestino: this.ciudadDestino.value,
      peso: this.peso.value,
      alto: this.alto.value,
      ancho: this.ancho.value,
      largo: this.largo.value,
      codFormaPago: this.codFormaPago.value,
      valorDeclarado: this.valorDeclarado.value
    }
    await this.chilexpressService.serviciosAdicionales(filtros);
  }

  async ObtenerPagos()
  {
    var filtros =
    {
      idcarro: this.idcarro.value
    }
    await this.chilexpressService.obtenerPagos(filtros);
  }

  async ObtenerTransaccion()
  {
    var filtros =
    {
      idcarro: this.idcarro.value
    }
    await this.chilexpressService.obtenerTransaccion(filtros);
  }

  async ActualizarUsuario()
  {
    var filtros = {
      "Usuarios":{
        "IdUsuario":1,
        "TipoUsuario":1,
        "NumIdentificacion":7,
        "DvIdentificacion":"7",
        "Nombres":"daniel",
        "ApellidoPaterno":"quiroz ",
        "ApellidoMaterno":"toledo",
        "GlsCorreo":"daniel.quiroz@ionix.cl",
        "GlsTelefono":"936356765",
        "GlsClave":"123",
        "EstadoVigencia":1
     }
    }
    await this.chilexpressService.actualizarUsuario(filtros);
  }

  async ValidarUsuarios()
  {
    var filtros = {
      "NumIdentificacion": 15910400,
      "GlsClave": "Abcd1234"
    }
    await this.chilexpressService.validarUsuarios(filtros);
  }

  async ObtenerBoleta()
  {
    var filtros =
    {
      documentType: this.documentType.value,
      documentNumber: this.documentNumber.value,
      ndRequireBinario: this.documentNumber.value
    }
    await this.chilexpressService.obtenerBoleta(filtros);
  }

  async GenerarBoleta()
  {
    var filtros = 
    {
      "controlInfoModel": {
        "codOrigen": "MAA",
        "codMedioFormaPago": "TJ", 
        "codTipoMedioPago": "DB",
        "identificadorPago": "1234567",
        "númeroOperacion": 10503930,
        "indRequiereBinario": false
      },
      "headerModel": {
        "fechaEmision": "2021-01-06T16:20:21.969Z",
        "rutCliente": 15910400, 
        "dvCliente": "1",
        "razonSocial": "rodrigo cea",
        "montoNeto": 1000,
        "montoIva": 900,
        "montoTotal": 1900
      },
      "detailModel": [
        {
          "codigoServicio": "10400",
          "cantidad": 1,
          "glosaServicio": "SOBRE LOCAL",
          "monto": 1000,
          "indAfecto": 1
        }     
      ]
    }
    await this.chilexpressService.generarBoleta(filtros);
  }

  async Token()
  {
    var filtros =
    {
      GlsUsuario: this.GlsUsuario.value,
      CodSistema: this.CodSistema.value,
      Version: this.Version.value
    }
    await this.chilexpressService.token(filtros);
  }

  // EN EXCEL ESTÁ COMO GET Y DICE PRECHECKEO
  // async RecepcionOT()
  // {
  //   var filtros = {
  //     "NumIdentificacion": 15910400,
  //     "GlsClave": "Abcd1234"
  //   }
  //   await this.chilexpressService.token(filtros);
  // }

  // EN EXCEL ESTÁ COMO GET
  // async CargaRecorrido()
  // {
  //   var filtros = {
  //     "NumIdentificacion": 15910400,
  //     "GlsClave": "Abcd1234"
  //   }
  //   await this.chilexpressService.cargaRecorrido(filtros);
  // }

  // EN EXCEL ESTÁ COMO GET
  // async CargaVehiculo()
  // {
  //   var filtros = {
  //     "NumIdentificacion": 15910400,
  //     "GlsClave": "Abcd1234"
  //   }
  //  await this.chilexpressService.cargaVehiculo(filtros);
  // }

  // EN EXCEL ESTÁ COMO GET
  // async ConsultarOficina()
  // {
  //   var filtros = {
  //     "NumIdentificacion": 15910400,
  //     "GlsClave": "Abcd1234"
  //   }
  //   await this.chilexpressService.validarUsuarios(filtros);
  // }

  // REVISAR DATOS DE ENTRADA
  // async TransferenciaOT()
  // {
  //   var filtros = {
  //     "nroOT": 0,
  //     "codOTPlantilla": 0,
  //     "codOficinaOrigen": 0,
  //     "codOficinaDestino": 0,
  //     "nroPlanilla": 0,
  //     "codRecorrido": 0,
  //     "codTriada": 0,
  //     "rutUsuario": "string",
  //     "codSistema": 0,
  //     "dataToken": {
  //     "codSistema": 0,
  //     "glsUsuario": "string",
  //     "version": "string"
  //    }
  //   }
  //   await this.chilexpressService.transferenciaOt(filtros);
  // }

  // REVISAR DATOS DE ENTRADA
  // async RetencionOT()
  // {
  //   var filtros = {  
  //     "nroOT": "string",
  //     "codTipoRetencion": 0,
  //     "codOficina": 0,
  //     "rutUsuario": "string",
  //     "glsObservacion": "string",
  //     "indDevolucion": 0,
  //     "dataToken": 
  //     {
  //       "codSistema": 0,
  //       "glsUsuario": "string",
  //       "version": "string"
  //     }
  //   }
  //   await this.chilexpressService.retencionOt(filtros);
  // }

  // EN EXCEL APARECE COMO PUT
  // async GenTriada()
  // {
  //   var filtros = 
  //   {
  //       "codRecorrido": 0,
  //       "codTransporte": 0,
  //       "rutCarrier": "string",
  //       "rutUsuario": "string",
  //       "codSistema": 0,
  //       "dataToken": 
  //       {
  //         "codSistema": 0,
  //         "glsUsuario": "string",
  //         "version": "string"
  //       }
  //   }
  //   await this.chilexpressService.genTriada(filtros);
  // }

  // EN EXCEL APARECE COMO GET
  // async ValidacionCarrier()
  // {
  //   var filtros = {
  //     "rutCarrirer": "string",
  //     "codOficina": 0,
  //     "indValidacionTrans": 0,
  //     "codSistema": 0,
  //     "dataToken": {
  //         "codSistema": 0,
  //         "glsUsuario": "string",
  //         "version": "string"
  //       }
  //   }
  //   await this.chilexpressService.validacionCarrier(filtros);
  // }

  // EN EXCEL APARECE COMO GET
  // async ConsultaDetalleOT()
  // {
  //   var filtros = {
  //     "rutCarrirer": "string",
  //     "codOficina": 0,
  //     "indValidacionTrans": 0,
  //     "codSistema": 0,
  //     "dataToken": {
  //         "codSistema": 0,
  //         "glsUsuario": "string",
  //         "version": "string"
  //       }
  //   }
  //   await this.chilexpressService.consultaDetalleOt(filtros);
  // }

}
