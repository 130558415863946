
    <div class="wrapper">
      <div class="container mt-5">
          <div class="row text-center">
              <div class="col-12 text-center">
                  <h1 class="display-3 font-weight-semibold mt-5">Escanea el código QR</h1>
                  <h3 class="font-weight-normal">Escanea el código QR generado por Driv.in<br> para verificar tu identidad.</h3>

                  <img class="mt-5 pt-5 inside-img" src="assets/img/qr.gif">


              </div>


          </div>
      </div>
      <div class="botonera-navegacion">
          <div class="row">
              <div class="col-8 mx-auto">


                  <button type="button" (click)="identificacionManual()" class="btn btn-outline-primary btn-lg mt-4 w-100">Identificación manual</button>


              </div>
          </div>

      </div>
  </div>
<!-- <div style="position: relative; top: 50px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea el código QR</div>
        <span style="color: black; position: relative; left: 1000px;">Escanea el código QR generado por Driv.in para verificar tu identidad</span>
      </div>
    </div>
    <img src="./assets/img/leerCodigoQR.PNG" style="width: 330px; position: relative; left: 1050px; top: 100px;">
    <div class="row" style="position: relative; left: 1050px; top: 300px;">
        <button (click)="identificacionManual()" style="position: relative; right: 100px;">Identificación manual</button>
        <button (click)="lecturaCorrecta()" >Lectura correcta</button>
    </div>
</div> -->
