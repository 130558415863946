<div class="mt-5 ml-5">
    <h6>Enviar encomienda > <strong>Escanear OT</strong></h6>
</div>
<div class="wrapper">
    <div class="container ">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Escanea e ingresa</h1>
                <h3 class="font-weight-normal">Escanea el código QR que se generó en la App o la etiqueta Chilexpress enviada a tu email en el<br> lector e ingresa la encomienda.</h3>
                <a>
                    <h4 (click)="scanNumOrdenTransporte()" class="font-weight-normal" style="text-decoration: underline;">Ingresa con número de Orden de Transporte
                    </h4>
                </a>
                <img class="mt-5 pt-5 inside-img" src="assets/img/scan.gif">
                <!-- <i class="fa fa-arrow-right arrow-top" aria-hidden="true"></i><img class="mt-5 pt-5 inside-img" width="250px" height="450px" src="assets/img/locker-retirar.gif"> -->
            </div>
        </div>
    </div>
    <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100" (click)="volver()">Volver</button>
            </div>
        </div>
    </div>
</div>
<div class="text-center justify-content-around opciones-menu">
    <!-- <p>Menu navegación (solo para revision de flujo)</p> -->
    <input type="text" [(ngModel)]="datoqr" >
    <div (click)="scanQr()" class="mt-2">PRESIONE PARA CONTINUAR</div>
    <div (click)="ErrorQrScan()">ERROR DE QR ESCANEADO</div>
    <div (click)="LockerFull()">ERROR DE LOCKER FULL</div>
</div>

<!-- <div class="row" style="position: relative; top: 350px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea e ingresa</div>
        <span style="color: black; position: relative; left: 860px;">Escanea el código QR que se generó en la app o la etiqueta Chilexpress enviada a tu email en el lector e ingresa la encomienda</span>
      </div>
    </div>
    <a (click)="scanNumOrdenTransporte()" style="position: relative; left: 1130px; cursor: pointer; top: 30px;">
        <span style="color: blue;">Ingresar con número de orden de transporte</span> 
    </a>
    <div class="row" style="position: relative; left: 1050px; top: 80px;">
    <button (click)="scanQr()" style="position: relative; right: 160px;">LECTURA CORRECTA</button>
    </div>
    <button (click)="volver()" style="position: relative; left: 1220px; width: 150px; top: 350px;">VOLVER</button>
</div> -->