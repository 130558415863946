import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ChilexpressService } from 'src/app/core/services/chilexpress/chlexpress.service';

@Component({
  selector: 'app-encomienda-ingresada',
  templateUrl: './encomienda-ingresada.component.html',
  styleUrls: ['./encomienda-ingresada.component.scss']
})
export class EncomiendaIngresadaComponent implements OnInit {

  NuevoMail;
  tipoAccion;
  datosprc;
  DatosConfig = {};

  constructor(private ChilexpressService:ChilexpressService, private router: Router, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.tipoAccion = sessionStorage.getItem("TipoAccion");
    this.finalizarCarro();

  }

  continuar() {
    this.router.navigate(['login']);
  }

  async imprimirComprobante() {
    var resp = await this.SweetAlertService.ImpresionComprobanteCHE();
    if (resp == true) {
      this.router.navigate(['menuOpciones']);
    }
    else {
      //this.router.navigate(['login']);
      this.router.navigate(['Raiting']);
    }
    //await this.delay(6000);
    //this.ChilexpressService.ImprimirPrueba();
    //await this.realizarOtraAccion();
    //this.router.navigate(['retirarImprimir']);
  }

  async enviarEmail() {
    var respuestamail = await this.SweetAlertService.EnviarCorreoFacturaCHE();
    if (respuestamail == true) {
      var nuevomail = await this.SweetAlertService.CambiarcorreoCHE();
      this.NuevoMail = nuevomail;
      var repc = await this.SweetAlertService.CorreoEnviadoCHE();
      if (repc == true) {
        this.router.navigate(['menuOpciones']);
      }
      else {
        this.router.navigate(['Raiting']);
        //this.router.navigate(['login']);
      }
    }
    else{
      var repc = await this.SweetAlertService.CorreoEnviadoCHE();
      if (repc == true) {
        this.router.navigate(['menuOpciones']);
      }
      else {
        this.router.navigate(['Raiting']);
        //this.router.navigate(['login']);
      }
    }
  }

 async  imprimirEmail() {
    var respuestamail = await this.SweetAlertService.EnviarCorreoFacturaCHE();
    if (respuestamail == true) {
      var nuevomail = await this.SweetAlertService.CambiarcorreoCHE();
      this.NuevoMail = nuevomail;
      this.ChilexpressService.ImprimirPrueba();
      var repc2 = await this.SweetAlertService.CorreoeimpreionEnviadoCHE();
      if (repc2 == true) {
        this.router.navigate(['menuOpciones']);
      }
      else {
        this.router.navigate(['Raiting']);
        // this.router.navigate(['login']);
      }
      //this.ChilexpressService.ImprimirPrueba();
      //await this.delay(5000);
      ///this.realizarOtraAccion();
    }
    else{
      this.ChilexpressService.ImprimirPrueba();
      var repc2 = await this.SweetAlertService.CorreoeimpreionEnviadoCHE();
      if (repc2 == true) {
        this.router.navigate(['menuOpciones']);
      }
      else {
        this.router.navigate(['Raiting']);
        //this.router.navigate(['login']);
      }
    }
    //this.router.navigate(['retirarImprimirEmail']);
  }

  async realizarOtraAccion() {
    var respuesta = await this.SweetAlertService.RealizarOtraAcciionCHE();
    //console.log("respuesta " + respuesta);
    if (respuesta == true) {
      this.router.navigate(['menuOpciones']);
    }
    else {
      this.router.navigate(['login']);
    }
  }

  async finalizarCarro(){
    this.DatosConfig = await this.ChilexpressService.GetConfig();
    var fincarro = {
      "carro": {
        "IdCarro": this.datosprc["idcarro"],
        "IdPortador": this.datosprc["idportador"],
        "TipoOperacion": 1,
        "CodOficina": this.DatosConfig["CodOfilocal"],// cd del locker
        "Estado": 3,// cambian segun la accion 
        "RegistroGrabacion": "",
        "FolioRetiro": 0
      }
    }
    var resp = await this.ChilexpressService.FinalizarCarro(fincarro);
    if (resp["status"] == true && resp["data"]["statusCode"] == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
