import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ChilexpressService } from 'src/app/core/services/chilexpress/chlexpress.service';
import { PruebaService } from './../../core/services/chilexpress/prueba.service';

@Component({
  selector: 'app-num-orden-transporte',
  templateUrl: './num-orden-transporte.component.html',
  styleUrls: ['./num-orden-transporte.component.scss']
})
export class NumOrdenTransporteComponent implements OnInit {
  Enconfig: boolean;
  numberbutton;

  constructor(private PruebaService:PruebaService,private ChilexpressService: ChilexpressService, private router: Router, private emitTecladoService: EmitTecladoService, private sweetAlertService: SweetAlertService) { }

  shoekb;
  tipok;
  tipoAccion = sessionStorage.getItem("TipoAccion");
  subscription: Subscription = new Subscription();
  numOrden = new FormControl('');
  MigaPan;
  datosprc;
  datoqr;

  OtEquivocada;

  DatosConfig;

  ngOnInit(): void {
    this.Enconfig = true;
    this.shoekb = true;
    this.OtEquivocada = 0;
    var esto = this;
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    //console.log(this.datosprc);
    

    this.tipoAccion = sessionStorage.getItem("TipoAccion");
    if (this.tipoAccion == "EntregaCli") {
      this.MigaPan = "Enviar Encomienda"
    }
    if (this.tipoAccion == "RetiroCli") {
      this.MigaPan = "Retiro Encomienda"
    }
    if (this.tipoAccion == "CargaCarrier") {
      this.MigaPan = "Cargar Encomienda"
    }

    this.obtenerdatos();

  }

  async obtenerdatos(){
    this.DatosConfig = await this.ChilexpressService.GetConfig();
  }

  volver() {
    if (this.tipoAccion == "EntregaCli") {
      this.router.navigate(['menuOpciones']);
    }
    else if (this.tipoAccion == "CargaCarrier") {
      this.router.navigate(['menuOpciones2']);
    }
    else {
      this.router.navigate(['menuOpciones']);
    }
  }

  async continuar() {

    if (this.numOrden.value !=  "") {
      this.datoqr = this.numOrden.value;
      var respot = await this.consultarOT();
      if (this.tipoAccion == "EntregaCli") {
        if (respot == true) {
          this.sweetAlertService.swalClose();
          this.router.navigate(['qrEscaneado']);
        }
      }
      else if (this.tipoAccion == "CargaCarrier") {
        if (respot == true) {
          this.sweetAlertService.swalClose();
          this.router.navigate(['casilleroAbierto2']);
        }
      }
      else {
        if (respot == true) {
          this.sweetAlertService.swalClose();
          this.router.navigate(['pinValidacion']);
        }
      }

      //console.log("NUMERO", this.numOrden.value);
    }
    else {
      if (this.OtEquivocada < 3) {
        if (this.numOrden.value === '') {
          await this.sweetAlertService.OTnoAdmitidaCHE()
        }
        else if (this.numOrden.value !== 123) {
          await this.sweetAlertService.OTnoAdmitidaCHE();
        }
        this.OtEquivocada++;
      }
      else {
        var resp = await this.sweetAlertService.ErroFrecuente();
        if (resp) {
          this.router.navigate(['/']);
        }
      }


    }

  }

  desplegarteclado(): void {
    this.shoekb = true;
  }

  async DondeOrden() {
    var resp = await this.sweetAlertService.DondeEncuentroOT();
  }

  // AsignrValorRut(rut) {
  //   var rutvalido = true;
  //   if (rutvalido) {
  //     this.rutvalido = true;
  //     this.numOrden.setValue(this.ValidatorsService.formaterRut(rut));
  //     this.SweetAlertService.swalSuccess("Rut leido Correctamente");
  //   }
  //   else {
  //     this.SweetAlertService.swalErrorgeneral("Error QR", "Ha ocurrido un error al realizar la lectura del QR");
  //   }
  // }

  borar() {
    if (this.numOrden.value != "") {
      this.numOrden.setValue(this.numOrden.value.substring(0, this.numOrden.value.length - 1))
    }
  }

  setnota(número) {
    this.numOrden.setValue(this.numOrden.value + número);
  }

  ConfigurarTeclado() {
    this.numberbutton = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", ""];
  }

  async consultarOT() {
    var dataot = {
      "nroOt": this.datoqr,
      "dataToken": {
        "codSistema": 16,
        "glsUsuario": "UsrTest",
        "version": "1"
      }
    }
    this.sweetAlertService.swalLoading();
    var respot = await this.ChilexpressService.consultaDetalleOt(dataot);
    //console.log();
    var respott = false;
    if (respot["status"] == true) {
      respott = this.validarOT(respot["data"])
    }
    
    //console.log("Ot valida",respott, respot["data"]);
    var encontrado = false;
    if (this.tipoAccion == "RetiroCli") {
      var resppedret = await this.PruebaService.RetirarCliente();
      if (resppedret["status"] == true) {
        for (let index = 0; index < resppedret["data"]["idPedido"].length; index++) {
          const element = resppedret["data"]["idPedido"][index];
          if (element["sPedidoSku"] == this.datoqr) {
            encontrado = true;
          }
        }
      }
      if(encontrado==false) {
        await this.sweetAlertService.OTnoAdmitidaCHE();
        this.router.navigate(['menuOpciones']);
        return false;
      }
    }

    if (respot["status"] == true && respott == true) {
      
      this.datosprc["not"] = this.datoqr;
      this.datosprc["emailEntrega"] = respot["data"]["ot"]["emailEntrega"];
      this.datosprc["emailRemitente"] = respot["data"]["ot"]["emailRemitente"];
      this.datosprc["altoPza"] = respot["data"]["ot"]["altoPza"];
      this.datosprc["anchoPza"] = respot["data"]["ot"]["altoPza"];
      this.datosprc["largoPza"] = respot["data"]["ot"]["largoPza"];
      this.datosprc["glsDimensiones"] = respot["data"]["ot"]["glsDimensiones"];
      this.datosprc["destino"] = respot["data"]["direccion"]["comuna"];
      this.datosprc["origen"] = respot["data"]["direccion"]["direccion"];
      this.datosprc["ntcc"] = respot["data"]["ot"]["nroTCC"];
      //console.log("datos proceso", this.datosprc);
      localStorage.setItem("datosproc", JSON.stringify(this.datosprc));
      var respotcc = await this.ChilexpressService.validaTcc(this.datosprc["ntcc"]);
      if (respotcc["status"] == true) {
        if (respotcc["datTCC"]["status"]["statusCode"] == 0) {
          var respdetallecarro = await this.crearDetalleCarro(respot["data"]);
          //console.log(respdetallecarro);

          if (respdetallecarro["status"] == true && respdetallecarro["detalle"][0]["statusCode"] == 0) {
            return true;
            //this.router.navigate(['qrEscaneado']);
          }
          else {
            await this.sweetAlertService.ErroFrecuente();
            this.router.navigate(['menuOpciones']);
            return false;
          }
        }
        else {
          await this.sweetAlertService.ErroFrecuente();
          this.router.navigate(['menuOpciones']);
          return false;
        }
      }
      else {
        await this.sweetAlertService.ErroFrecuente();
        this.router.navigate(['menuOpciones']);
        return false;

      }
    }
    else {
      await this.sweetAlertService.OTnoAdmitidaCHE();
      this.router.navigate(['menuOpciones']);
      return false;
    }
  }
  async crearDetalleCarro(OT) {
    var detallecarro = {
      "detalleCarro": {
        "idDetalle": 0,
        "idCarro": this.datosprc["idcarro"],
        "nroOt": this.datosprc["not"],
        "numCasillero": 0,//actualizar numero casillero
        "codOficinaOrigen": this.DatosConfig["CodOfilocal"],//referenciar de config
        "codOficinaDestino": OT["oficina"]["codOficinaDestino"],
        "codProdOt": OT["ot"]["codProdOT"],
        "codServicioOt": OT["ot"]["codServicio"],
        "codTipoOt": OT["ot"]["codTipoOt"],
        "nroTcc": this.datosprc["ntcc"],
        "codOrigenOt": OT["ot"]["codOrigenOT"],
        "pesoOt": OT["ot"]["pesoOT"],
        "largoPza": OT["ot"]["largoPza"],
        "anchoPza": OT["ot"]["anchoPza"],
        "altoPza": OT["ot"]["altoPza"],
        "fechaCreacionOt": OT["ot"]["fechaCreacion"],// actualizar con servicio
        "codEstadoOt": OT["codEstadoOt"],
        "indPorCobrar": OT["indPorPagar"],
        "rutUsCreacion": this.datosprc["rutcli"],
        "ipCreacion": OT["ot"]["iPCreacion"],// ip traer de act cservicio
        "tipoEntrega": 0,
        "nroReferencia": 0,//pin de seguridad 
        "codUpv": OT["ot"]["codUPV"],
        "valorTotal": 0,
        "coberturaExtendida": 0,
        "valorDeclarado": OT["valorDeclarado"],
        "codCoberturaOrigen": "LCON",// donde esta el locker
        "codCoberturaDestino": OT["direccion"]["codCobertura"],
        "glsCoberturaDestino": OT["direccion"]["comuna"],// traer de OT
        "direccionOt": OT["direccion"]["glsZonaReparto"],
        "numeracionOt": OT["direccion"]["nroDireccion"],
        "complementoOt": OT["direccion"]["complemento"],
        "latDir": OT["direccion"]["latitud"],
        "lonDir":OT["direccion"]["longitud"],
        "codEstadoDetalle": OT["codEstadoOt"],
        "glsEstadoDetalle": "Creada" // me la tiene que pasar dani
      }
    }
    //console.log(detallecarro);
    localStorage.setItem("detallesCarro", JSON.stringify(detallecarro));

    var respcraredtallecarro = await this.ChilexpressService.CrearDetalleCarro(detallecarro);
    return respcraredtallecarro;
  }

  validarOT(data) {
    var otreal = this.datoqr.substring(0,this.datoqr.length-1);
    var otdv = this.datoqr.substring(this.datoqr.length-1,this.datoqr.length);
    var resto = parseInt(otreal) % 7;
    //console.log((data["indBloqueo"]));
    if (resto == parseInt(otdv) && this.tipoAccion == "CargaCarrier") {
      return true;
    }

    if (resto == parseInt(otdv) && this.tipoAccion == "RetiroCli") {
      return true;
    }

    if (resto == parseInt(otdv)) {
      if (data["ot"]["codProducto"] == 1 || data["ot"]["codProducto"] == 3 ) {
        if (data["codEstadoOt"] == 0 && (data["ot"]["codOrigenOT"] == 5 || (data["ot"]["codOrigenOT"] == 8 &&  data["ot"]["codCanalOrigen"] == 10))) {
          if (data["valorDeclarado"] < 200000 && data["ot"]["codUPV"] < 1) {
            if (data["indBloqueo"] < 1) {
              if (data["ot"]["codServicio"] !=  8 && data["ot"]["codServicio"] !=  1 && data["ot"]["codServicio"] != 10) {
                if (data["servicioSobredimensionado"] < 1) {
                  if (data["ot"]["largoPza"] < 100 && data["ot"]["anchoPza"] < 100 ||data["ot"]["altoPza"] < 120 ) {
                    //console.log("OT true");
                    
                    return true;
                  } else { console.log("muy grande el pedido");return false }
                } else { console.log("sobredimencionado");return false }
              } else { console.log("cod serv errorneo"); return false }
            } else { console.log("ot bloqueada"); return false }
          } else { console.log("valor ot no corresponde"); return false }
        } else { console.log("cod estado ot error");return false }
      }
      else { console.log("Cod producto error"); return false }
    }
    else { console.log("no se divide entre 7"); return false }
  }


}
