import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ValidatorsService } from 'src/app/core/services/miscellaneous/validators.service';
import { ChilexpressService } from '../../../core/services/chilexpress/chlexpress.service';
import { PruebaService } from '../../../core/services/chilexpress/prueba.service';

@Component({
  selector: 'app-identificacion-manual',
  templateUrl: './identificacion-manual.component.html',
  styleUrls: ['./identificacion-manual.component.scss']
})
export class IdentificacionManualComponent implements OnInit {
  Enconfig: boolean;
  numberbutton;

  constructor(private PruebaService: PruebaService, private ChilexpressService: ChilexpressService, private ValidatorsService: ValidatorsService, private router: Router, private emitTecladoService: EmitTecladoService, private sweetAlertService: SweetAlertService) { }

  shoekb;
  tipok;
  subscription: Subscription = new Subscription();
  rut = new FormControl('');
  clave = new FormControl('');

  FolioInvalido = "";
  Rutnvalida = "";
  datosprc;
  detallescarro
  DatosConfig



  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.detallescarro = [];
    //console.log(this.datosprc);

    this.Enconfig = true;
    this.ConfigurarTeclado();
    var esto = this;
    this.tipok = "F";
  }

  volver() {
    this.router.navigate(['menuOpciones2']);
  }

  async continuar() {
    if (this.rut.value == "14183938-9" && this.clave.value == 123) {
      if (sessionStorage.getItem("TipoAccion") == "CargaCarrier") {
        this.router.navigate(['qrEscaneado2']);
      }
      else if (sessionStorage.getItem("TipoAccion") == "RetiroCarrier") {
        this.router.navigate(['encomiendaARetirar']);
      }
      else if (sessionStorage.getItem("TipoAccion") == "PlazoCumplido") {
        this.router.navigate(['encomiendaARetirar']);
      }
      else if (sessionStorage.getItem("TipoAccion") == "RevisarCasillero") {
        this.router.navigate(['seleccionarLockerRevisarCasillero']);
      }
      else if (sessionStorage.getItem("TipoAccion") == "ReportarProblema") {
        this.router.navigate(['reportarProblema']);
      }

      //console.log("NUMERO", sessionStorage.getItem("TipoAccion"));
    }
    if (this.rut.value !== "14183938-9") {
      //await this.sweetAlertService.DatosnoAdmitidaCHE();
      // this.Rutnvalida = this.rut.value == ""?"Rut no puede estar vacio":"";
      // this.FolioInvalido = this.clave.value == ""?"Folio/Clave no puede estar vacio":"";

      this.Rutnvalida = this.rut.value != "14183938-9" ? "Ingresa un RUT válido" : "";
      this.Rutnvalida = this.rut.value == "" ? "Ingresa un RUT válido" : this.Rutnvalida;
      this.FolioInvalido = this.clave.value != "123" ? "Ingresa un Folio/Clave válido " : "";
      this.FolioInvalido = this.clave.value == "" ? "Ingresa un Folio/Clave válido " : this.FolioInvalido;
    }
    if (this.clave.value !== '123') {
      this.Rutnvalida = this.rut.value != "14183938-9" ? "Ingresa un RUT válido" : "";
      this.Rutnvalida = this.rut.value == "" ? "Ingresa un RUT válido" : this.Rutnvalida;
      this.FolioInvalido = this.clave.value != "123" ? "Ingresa un Folio/Clave válido " : "";
      this.FolioInvalido = this.clave.value == "" ? "Ingresa un Folio/Clave válido " : this.FolioInvalido;
    }


  }

  desplegarteclado(): void {
    this.shoekb = true;
  }

  TipoIput(tipo) {
    this.tipok = tipo;
  }

  borar() {
    if (this.tipok == "R") {
      if (this.rut.value != "") {
        this.rut.setValue(this.rut.value.substring(0, this.rut.value.length - 1));
        this.rut.setValue(this.ValidatorsService.formateaRut(this.rut.value));
      }
    }
    else {
      this.clave.setValue(this.clave.value.substring(0, this.clave.value.length - 1));
    }

  }

  setnota(número) {
    if (this.tipok == "R") {
      if (this.rut.value.length <= 11) {
        this.rut.setValue(this.rut.value + número);
        this.rut.setValue(this.ValidatorsService.formateaRut(this.rut.value));
      }
    }
    else {
      this.clave.setValue(this.clave.value + número);
    }
  }

  async ValidaCarrier() {
    this.sweetAlertService.swalLoading();
    var carrier = {
      "rutCarrirer": "14183938",
      "codOficina": this.DatosConfig["CodOfilocal"],
      "indValidacionTrans": 0,
      "codSistema": 13,
      "dataToken": {
        "codSistema": 16,
        "glsUsuario": "UsrTest",
        "version": "1"
      }
    }
    var respca = await this.ChilexpressService.validacionCarrier(carrier);
    if (respca["status"] == true && respca["data"]["codRespuesta"] == 0) {

      var clin = {
        "acc": "N",
        "dniCli": respca["data"]["data"]["rutCarrier"],
        "nom": respca["data"]["data"]['nomCarrier'],
        "fono": "956427161",
        "mail": "jgpaz19@gmail.com"
      }
      var respncli = await this.PruebaService.administracionClientes(clin);
      var recorrido = await this.CargaRecorrido();
      if (recorrido == true) {

        var gentriada = {
          "codRecorrido": this.datosprc["codrecorrido"],
          "codTransporte": respca["data"]["data"]["codTransporte"],
          "rutCarrier": respca["data"]["data"]["rutCarrier"],
          "rutUsuario": "12236614",
          "codSistema": 16,
          "dataToken": {
            "codSistema": 16,
            "glsUsuario": "UsrTest",
            "version": "1"
          }
        }
        var resptria = await this.ChilexpressService.genTriada(gentriada);
        if (resptria["status"] == true && resptria["data"]["codRespuesta"] == 0) {

          this.datosprc["nombreCarrier"] = respca["data"]["data"]["nomCarrier"];
          this.datosprc["rut"] = respca["data"]["data"]["rutCarrier"];
          this.datosprc["codtransporte"] = respca["data"]["data"]["codTransporte"];
          this.datosprc["patente"] = respca["data"]["data"]["patente"];
          this.datosprc["codtriada"] = resptria["data"]["data"]["codTriada"];
          this.datosprc["rutcli"] = respca["data"]["data"]["rutCarrier"];
          localStorage.setItem("datosproc", JSON.stringify(this.datosprc));
          if (sessionStorage.getItem("TipoAccion") == "RetiroCarrier") {
            var respcasillerosocu = await this.consultarOtEncasillero();
            if (respcasillerosocu == true) {
              this.continuar();
            }
            else{
             await  this.sweetAlertService.LockerVacio();
             this.router.navigate(['menuOpciones2']);
            }
          }
          else {
            this.continuar();
          }
        }
        else {
          await this.sweetAlertService.ErroFrecuente();
          this.router.navigate(['menuOpciones2']);
        }
      }
      else {
        await this.sweetAlertService.ErroFrecuente();
        this.router.navigate(['menuOpciones2']);
      }
    }
    else {
      await this.sweetAlertService.ErroFrecuente();
      this.router.navigate(['menuOpciones2']);
    }
  }


  async consultarOT(ot, nud, casillero) {
    var dataot = {
      "nroOt": ot + "",
      "dataToken": {
        "codSistema": 16,
        "glsUsuario": "UsrTest",
        "version": "1"
      }
    }

    var respot = await this.ChilexpressService.consultaDetalleOt(dataot);
    //console.log(respot);

    if (respot["status"] == true) {
      this.datosprc["not"] = ot;
      this.datosprc["emailEntrega"] = respot["data"]["ot"]["emailEntrega"];
      this.datosprc["emailRemitente"] = respot["data"]["ot"]["emailRemitente"];
      this.datosprc["altoPza"] = respot["data"]["ot"]["altoPza"];
      this.datosprc["anchoPza"] = respot["data"]["ot"]["altoPza"];
      this.datosprc["largoPza"] = respot["data"]["ot"]["largoPza"];
      this.datosprc["glsDimensiones"] = respot["data"]["ot"]["glsDimensiones"];
      this.datosprc["destino"] = respot["data"]["direccion"]["comuna"];
      this.datosprc["ntcc"] = respot["data"]["ot"]["nroTCC"];
      //console.log("datos proceso", this.datosprc);
      localStorage.setItem("datosproc", JSON.stringify(this.datosprc));
      var respotcc = await this.ChilexpressService.validaTcc(this.datosprc["ntcc"]);
      this.sweetAlertService.swalClose();
      if (respotcc["status"] == true) {
        if (respotcc["datTCC"]["status"]["statusCode"] == 0) {
          var respdetallecarro = await this.crearDetalleCarro(respot["data"], casillero);
          //console.log(respdetallecarro);

          if (respdetallecarro["status"] == true && respdetallecarro["detalle"][0]["statusCode"] == 0) {
            //console.log("Creacion correcta de carro");
            var respactdetalle = await this.ActualizarDetalleCarro(1, nud);
            if (respactdetalle == false) {
              await this.sweetAlertService.ErroFrecuente();
              this.router.navigate(['menuOpciones2']);
            }

            //this.router.navigate(['qrEscaneado']);
          }
          else {
            await this.sweetAlertService.ErroFrecuente();
            this.router.navigate(['menuOpciones2']);
          }
        }
        else {
          await this.sweetAlertService.ErroFrecuente();
          this.router.navigate(['menuOpciones2']);
        }
      }
      else {
        await this.sweetAlertService.ErroFrecuente();
        this.router.navigate(['menuOpciones2']);

      }
    }
    else {
      this.sweetAlertService.swalClose();
      await this.sweetAlertService.ErroFrecuente();
      this.router.navigate(['menuOpciones2']);
    }
  }
  async crearDetalleCarro(OT, casillero) {
    var detallecarro = {
      "detalleCarro": {
        "idDetalle": 0,
        "idCarro": this.datosprc["idcarro"],
        "nroOt": this.datosprc["not"],
        "numCasillero": 0,//actualizar numero casillero
        "codOficinaOrigen": this.DatosConfig["CodOfilocal"],//referenciar de config
        "codOficinaDestino": OT["oficina"]["codOficinaDestino"],
        "codProdOt": OT["ot"]["codProdOT"],
        "codServicioOt": OT["ot"]["codServicio"],
        "codTipoOt": OT["ot"]["codTipoOt"],
        "nroTcc": this.datosprc["ntcc"],
        "codOrigenOt": OT["ot"]["codOrigenOT"],
        "pesoOt": OT["ot"]["pesoOT"],
        "largoPza": OT["ot"]["largoPza"],
        "anchoPza": OT["ot"]["anchoPza"],
        "altoPza": OT["ot"]["altoPza"],
        "fechaCreacionOt": OT["ot"]["fechaCreacion"],// actualizar con servicio
        "codEstadoOt": OT["codEstadoOt"],
        "indPorCobrar": OT["indPorPagar"],
        "rutUsCreacion": this.datosprc["rutcli"],
        "ipCreacion": OT["ot"]["iPCreacion"],// ip traer de act cservicio
        "tipoEntrega": 0,
        "nroReferencia": 0,//pin de seguridad 
        "codUpv": OT["ot"]["codUPV"],
        "valorTotal": 0,
        "coberturaExtendida": 0,
        "valorDeclarado": OT["valorDeclarado"],
        "codCoberturaOrigen": "LCON",// donde esta el locker
        "codCoberturaDestino": OT["direccion"]["codCobertura"],
        "glsCoberturaDestino": OT["direccion"]["comuna"],// traer de OT
        "direccionOt": OT["direccion"]["glsZonaReparto"],
        "numeracionOt": OT["direccion"]["nroDireccion"],
        "complementoOt": OT["direccion"]["complemento"],
        "latDir": OT["direccion"]["latitud"],
        "lonDir": OT["direccion"]["longitud"],
        "codEstadoDetalle": OT["codEstadoOt"],
        "glsEstadoDetalle": "Creada" // me la tiene que pasar dani
      }
    }
    //console.log("Detalle carro", detallecarro);
    this.detallescarro.push(detallecarro);
    localStorage.setItem("detallesCarro", JSON.stringify(this.detallescarro));


    var respcraredtallecarro = await this.ChilexpressService.CrearDetalleCarro(detallecarro);
    return respcraredtallecarro;
  }

  async ActualizarDetalleCarro(id, nud) {
    //console.log(this.detallescarro[nud]);
    this.detallescarro[nud]["detalleCarro"]["codEstadoDetalle"] = 1;
    this.detallescarro[nud]["detalleCarro"]["glsEstadoDetalle"] = "Transferida"
    this.detallescarro[nud]["detalleCarro"]["idDetalle"] = 2;
    this.detallescarro[nud]["detalleCarro"]["nroReferencia"] = id;
    //console.log(this.detallescarro[nud]);
    var resp = await this.ChilexpressService.ActualizarDetalleCarro(this.detallescarro[nud]);
    if (resp["status"] == true && resp["data"][0]["statusCode"] == 0) {
      localStorage.setItem("detallesCarro", JSON.stringify(this.detallescarro));
      //console.log("detallecarro", this.detallescarro);

      return true;
    }
    else {
      return false;
    }

  }

  async consultarOtEncasillero() {
    var otcasillero = await this.PruebaService.RetirarEmpleado();
    if (otcasillero["status"] == true) {
      var pedidos = otcasillero["data"]["idPedido"];
      for (let index = 0; index < pedidos.length; index++) {
        const element = pedidos[index];
        await this.consultarOT(element["sPedidoSku"], index, element["idCasillero"]);
      }
      localStorage.setItem("PedidoARetirar", JSON.stringify(pedidos));
      //console.log(this.detallescarro);
      if (pedidos.length == 0) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }

  }

  async CargaRecorrido() {
    var recorrido = {
      "nomRecorrido": "Prueba",
      "codOficina": 795,
      "codSistema": 0,
      "dataToken": {
        "codSistema": 0,
        "glsUsuario": "string",
        "version": "string"
      }
    }
    var recresp = await this.ChilexpressService.cargaRecorrido(recorrido);
    if (recresp["status"]) {
      this.datosprc["codrecorrido"] = recresp["data"]["data"][0]["codRecorrido"];
      return true;
    }
    else {
      return false;
    }
  }

  async ConfigurarTeclado() {
    this.DatosConfig = await this.ChilexpressService.GetConfig();
    this.numberbutton = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", ""];
  }


}