<div style="color: black; position: relative; left: 950px;">
    <div>
        <h1>Encomiendas a retirar</h1><br>
        <h3>Los casilleros se abrirán de forma secuencial al presionar continuar</h3>
        <h3>Total de envíos a retirar</h3>
        <span>18</span>
    </div>
    <app-locker [Admin]="Admin" (changeStep)="GetLocker($event)" style="position: relative; right: 900px;">

    </app-locker>
    <div style="color: black; position: relative; left: 200px; top: 300px;">
        <button (click)="continuar()">Continuar</button>
        <button (click)="volver()">Volver</button>
    </div>
</div>


