import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import { ConfigService } from '../config/config.service';


@Injectable({
  providedIn: 'root'
})
export class MocService {

  GETRUT		= 100;
  GETFINGER	= 101;
  VALIDATE	= 102;
  iTOcnx		= 0;
  iTO			  = 10;	// seg
  iTOint		= 0;
  isConnected: boolean;
  count = 0;

  wsReq = {
    Action	: this.GETRUT,
    Timeout	: this.iTO
  };

  constructor(private configService: ConfigService) {}

  async geturl(){
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['url_websocket']}`
    return url;
  }

  public  init(url) {
    /* Comunicación con ws */
    this.wsReq = {
      Action	: this.GETRUT,
      Timeout	: this.iTO
    };
    
    return new Observable<any>(observer => {
      try {
        const ws = new WebSocket(url);

        ws.onopen = (event) => {
          ws.send(JSON.stringify(this.wsReq));
        };

        ws.onmessage = (message) => {
          /* Se envía la lógica del moc */
          this.onMessage(message, observer, ws);
        };

        ws.onerror = (err) => {
          ws.close();
          observer.error();
        };

        /* Cierra conexión con ws */
        ws.onclose = (err) => {
          observer.complete();
        };

        return () => {

        };
      } catch (ex) {
        observer.error(ex);
      }
    });
  }

  onMessage(message: any, observer: any, ws: WebSocket) {
    observer.next(message);
    let oRsp = JSON.parse(message.data), acc = '';
    this.iTOint = 0;

    /* Código 0 = OK */
    if (oRsp.Error.Code !== 0) {
      observer.error(message);
      ws.close();
    }

    /* Si el primer intento de huella no es válida intentará 2 veces más */
    if (oRsp.Result === 'N') {
      const acccion = this.fingerError(message, observer, ws);
      this.fnAccion(acccion, ws);
    }

    switch (oRsp.Action) {
      case this.GETRUT:
        acc = 'GetFinger';
        break;

      case this.GETFINGER:
        acc = 'SetFinger';
        break;

      case this.VALIDATE:
        break;

      default:
        break;
    }
    if (acc !== '') {
      this.fnAccion(acc, ws);
    }
  }

  fnAccion(acc: any, ws: WebSocket) {
    switch (acc) {
      case 'Salir':
        return false;

      case 'GetRut':
        this.wsReq = {
          Action	: this.GETRUT,
          Timeout	: this.iTO
        };
        break;

      case 'GetFinger':
        this.wsReq = {
          Action	: this.GETFINGER,
          Timeout	: this.iTO
        };
        break;

      case 'SetFinger':
        this.wsReq = {
          Action	: this.VALIDATE,
          Timeout	: this.iTO
        };
        break;
    }

    /* Se envia request al webSocket*/
    ws.send(JSON.stringify(this.wsReq));
    this.iTOint = this.iTO + 10;
  }

  fingerError(message: any, observer: any, ws: WebSocket) {
    this.count++;

    if (this.count === 3) {
      observer.error(message);
      ws.close();
    } else {
      return 'GetFinger';
    }
  }

}
