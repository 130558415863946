import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChilexpressService } from '../../core/services/chilexpress/chlexpress.service';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { PruebaService } from '../../core/services/chilexpress/prueba.service';

@Component({
  selector: 'app-autenticado',
  templateUrl: './autenticado.component.html',
  styleUrls: ['./autenticado.component.scss']
})
export class AutenticadoComponent implements OnInit {
  animation = false;
  portador;
  constructor(private PruebaService: PruebaService, private router: Router, private ChilexpressService: ChilexpressService, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.ConsultarPortador();
  }

  continuar() {
    this.animation = true;
    // this.router.navigate(['menuOpciones']);
    setTimeout(() => {
      this.router.navigate(['menuOpciones']);
    }, 1000);
  }

  volver() {
    this.router.navigate(['']);
  }

  async ConsultarPortador() {
    var portador = "15910400";
    //this.SweetAlertService.swalLoading()
    var resp = await this.ChilexpressService.getPortador(portador);
    //this.SweetAlertService.swalClose();
    if (resp["status"] == true) {
      this.portador = resp["datPortador"];
      //console.log(this.portador['portador']['gls_nombre']);
      var datosguardados = {
        "nomcli": this.portador['portador']['gls_nombre'],
        "rutcli": portador,
        "idportador": this.portador['portador']["id_portador"]
      }
      //console.log(datosguardados);
      var clin = {
        "acc": "N",
        "dniCli": portador,
        "nom": this.portador['portador']['gls_nombre'],
        "fono": "956427161",
        "mail": "jgpaz19@gmail.com"
      }
      var respncli = await this.PruebaService.administracionClientes(clin);
      //console.log(respncli);
      
      if (respncli["status"]==false) {
        var clin = {
          "acc": "U",
          "dniCli": portador,
          "nom": this.portador['portador']['gls_nombre'],
          "fono": "956427161",
          "mail": "jgpaz19@gmail.com"
        }
        var respncli2 = await this.PruebaService.administracionClientes(clin);
        //console.log(respncli2);
        
      }
      
      localStorage.setItem("datosproc", JSON.stringify(datosguardados));

    }
    else {
      var portadorn = [
        {
          "portador": {
            "id_portador": 14408,
            "id_tipo_persona": 1,
            "tipo_persona": "PERSONA",
            "cod_tipo_identificador": 1,
            "tipo_identificador": "RUT",
            "codigo_identificador": "15910400",
            "gls_nombre": "Rodrigo Andres Cea Sobarzo",
            "key_carga": 15910400
          },
          "contacto": [
            {
              "id_contacto": 34177,
              "id_uso_contacto": 2,
              "uso_contacto": "REMITENTE",
              "id_tipo_contacto": 1,
              "tipo_contacto": "CORREO",
              "gls_contacto": "rcea@chilexpress.cl",
              "status_registro": "USADO ALGUNA VEZ"
            }
          ],
          "direcciones": [
            {
              "id_direccion": 4714,
              "tipo_uso_contacto_direccion": 2,
              "uso_contacto_direccion": "REMITENTE",
              "cod_tipo_direccion": 1,
              "tipo_direccion": "DOMICILIO",
              "gls_direccion": "Lalsl",
              "num_direccion": 144,
              "gls_complemento": "",
              "latitud": 0.0,
              "longitud": 0.0,
              "cod_cobertura_Alfa": "ANTO",
              "status_registro": "USADO ALGUNA VEZ"
            }
          ]
        }

      ]
      var respCrear = await this.ChilexpressService.actualizadorPortador(portadorn);
      //console.log(respCrear);
      var datosguardadosn = {
        "nomcli": "Sin Nombre",
        "rutcli": portador,
        "idportador": this.portador['portador']["id_portador"]
      }
      var clin = {
        "acc": "N",
        "dniCli": portador,
        "nom": this.portador['portador']['gls_nombre'],
        "fono": "956427161",
        "mail": "jgpaz19@gmail.com"
      }
      this.PruebaService.administracionClientes(clin);
      localStorage.setItem("datosproc", JSON.stringify(datosguardadosn));
    }
  }

}
