<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div style="position: relative; left: 1100px; font-size: 30px; font-weight: bold;">
        <label for="" style="color: black;">Administrar</label>
    </div>
    <label for="" style="color: black; position: relative; left: 1010px;">-------------------------------------------------------------------------</label>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Zonas</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Regiones</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Comunas</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Oficinas</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Clientes</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Usuarios</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Usuarios Oficina</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Lockers</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Casilleros</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion GetDatos</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Administracion Update Estados</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Servicio 12</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Servicio 13</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Servicio 14</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Servicio 15</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>

</div>