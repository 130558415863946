import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auh/auth.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public isCollapsed = false;

  constructor(private AuthService: AuthService, private SweetAlertService: SweetAlertService) { }

  ngOnInit() {
  }

  async salir(e: any) {
    var resp = await this.SweetAlertService.CerrarSesion();
    if (resp == true) {
      e.preventDefault();
      this.AuthService.logout();
    }
  }

  tipoheader() {
    if (document.location.href.includes("login")) {
      return true;
    }
    else {
      return false;
    }
  }

  estaAutenticado(): boolean {
   // //console.log(window.location.pathname );
    
   if (!window.location.pathname.includes("/autenticado")) {
      return this.AuthService.estaAutenticado();
    }
    else return false;
    
  }

}
