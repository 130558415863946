import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Movimiento } from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { UsuarioService } from 'src/app/core/services/http/usuario.service';
import { DenominacionService } from 'src/app/core/services/http/denominacion.service';
import {Oficina} from 'src/app/core/models/oficina';
import { ErrorService } from '../../core/services/http/error.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PrimeNGConfig } from 'primeng/api';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { HttpResponse } from '@angular/common/http';
import {PruebaService} from '../../core/services/chilexpress/prueba.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  constructor
  (private primengConfig: PrimeNGConfig, private PruebaService: PruebaService) 
  { 

  }

  async ngOnInit()
  {
   
  }

  async administracionZonas()
  {
    var adminZonas = 
      {
        "acc"   : "string",
        "idZ"   : 0,
        "zona"  : "string"
      }
    await this.PruebaService.administracionZonas(adminZonas);
  }

  async administracionRegiones()
  {
    var adminRegiones = 
      {
        "acc"   : "string",
        "idR"   : 0,
        "idZ"   : 0,
        "region": "string",
        "idOrd" : 0
      }
    await this.PruebaService.administracionRegiones(adminRegiones);
  }

  async administracionComunas()
  {
    var adminComunas =
    {
        "acc"   : "string",
        "idC"   : 0,
        "idR"   : 0,
        "comuna": "string"
    }
    await this.PruebaService.administracionComunas(adminComunas);
  }

  async administracionOficinas()
  {
    var adminOficinas =
    {
      "acc"     : "string",
      "idO"     : 0,
      "idC"     : 0,
      "codOfi"  : "string",
      "ofi"     : "string",
      "estado"  : true
    }
    await this.PruebaService.administracionOficinas(adminOficinas);
  }

  async administracionClientes()
  {
    var adminClientes =
    {
      "acc"     : "string",
      "dniCli"  : "string",
      "nom"     : "string",
      "fono"    : "string",
      "mail"    : "string"
    }
    await this.PruebaService.administracionClientes(adminClientes);
  }

  async administracionUsuarios()
  {
    var adminUsuarios =
    {
      "acc"     : "string",
      "dniUsu"  : "string",
      "nomUsu"  : "string",
      "pass"    : "string",
      "hab"     : true
    }
    await this.PruebaService.administracionUsuarios(adminUsuarios);
  }

  async administracionUsuOficinas()
  {
    var adminUsuOficinas =
    {
      "acc"     : "string",
      "idO"     : 0,
      "dniUsu"  : "string"
    }
    await this.PruebaService.administracionUsuOficinas(adminUsuOficinas);
  }

  async administracionLockers()
  {
    var adminLockers =
    {
      "acc"     : "string",
      "idL"     : 0,
      "idO"     : 0,
      "nPuertas": 0,
      "hab"     : true
    }
    await this.PruebaService.administracionLockers(adminLockers);
  }

  async administracionCasilleros()
  {
    var adminCasilleros =
    {
      "acc"     : "string",
      "idL"     : 0,
      "idO"     : 0,
      "idCa"    : 0,
      "tipo"    : "string",
      "alto"    : 0,
      "ancho"   : 0,
      "largo"   : 0,
      "estado"  : "string"
    }
    await this.PruebaService.administracionCasilleros(adminCasilleros);
  }

  async administracionGetDatos()
  {
    var adminGetDatos =
    {
      "tipo"    : "string"
    }
    await this.PruebaService.administracionGetDatos(adminGetDatos);
  }

  async administracionUpdEstados()
  {
    var adminUpdE =
    {
      "tipo"    : "string"
    }
    await this.PruebaService.administracionUpdEstados(adminUpdE);
  }
  
  async ejecturarServicio12()
  {
    await this.PruebaService.servicio12();
  }
  async ejecturarServicio13()
  {
    await this.PruebaService.servicio13();
  }
  async ejecturarServicio14()
  {
    await this.PruebaService.servicio14();
  }
  async ejecturarServicio15()
  {
    await this.PruebaService.servicio15();
  }

}
