<!-- <div class="d-flex tecladoshow" *ngIf="shoekb">
  <div class="col-12 centrar-teclado" >
      <!-- Teclado 
      <div class="caja-botones col-12">
        <div class=" boton col-4" *ngFor="let number of numberbutton; index as i; last as last">
            <div *ngIf="!last" class="contenido-boton text-center animated bounceIn" (click)="setnota(i)">
                {{number}}</div>
            <div *ngIf="last" class="contenido-boton text-center button-borrar bgi-del"
                (click)="borar()"><div><i class="pi pi-chevron-left"></i></div>
            </div>
        </div>
    </div>
  </div>
</div>

<div class="row">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Ingresa tu número de orden de transporte</div>
      </div>
      <div style="color: black; position: relative; right: 30px; top: 10px;">¿Dónde encuentro mi número de OT?</div>
      <div style="position: relative; right: 20px; top: 30px;">
        <input type="text" placeholder="ingrese registro" [formControl]="numOrden">
      </div>
    </div>
</div>
<div class="row" style="position: relative; top: 700px;">
  <button style="width: 330px; position: relative; left: 900px; top: 60px;" (click)="continuar()">PRESIONE PARA CONTINUAR</button>
  <button style="width: 330px; position: relative; left: 950px; top: 60px;" (click)="volver()">VOLVER</button>
</div> -->


<div class="mt-5 ml-5">
    <h6>{{MigaPan}} > <strong>Ingresar OT</strong></h6>
</div>
<div class="wrapper">
    <div class="container mt-5">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-2">Ingresa tu número<br> de Orden de Transporte</h1>
                <a>
                    <h4 class="font-weight-normal mt-4" (click)="DondeOrden()" style="text-decoration: underline;">¿Dónde encuentro mi número de OT?</h4>
                </a>
            </div>
            <div class="col-7 mx-auto mt-5">
                <form>

                    <div class="form-group">
                        <!-- <label for="exampleInputEmail1"><b>Label del input</b></label> -->
                        <input type="email" [formControl]="numOrden" class="form-control form-control-lg text-center" placeholder="Número OT">
                        <!-- <small class="form-text">We'll never share your email with anyone else.</small> -->
                    </div>

                </form>
                <div class="row col-9 pl-2 mx-auto mt-5">
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(1)">
                            1
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(2)">
                            2
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(3)">
                            3
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(4)">
                            4
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(5)">
                            5
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(6)">
                            6
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(7)">
                            7
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(8)">
                            8
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="setnota(9)">
                            9
                        </div>

                    </div>
                    <div class="col-4 offset-4">
                        <div class="tecla-key btn-light" (click)="setnota(0)">
                            0
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="tecla-key btn-light" (click)="borar()">
                            <img style="height: 23px;margin-bottom: 5px;" src="assets/img/backspace.svg">
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="continuar()">Continuar</button>
                <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100" (click)="volver()">Volver</button>

            </div>
        </div>

    </div>
</div>