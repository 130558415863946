import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ConfigService } from '../../../core/services/config/config.service';
import { Usuario } from '../../models/usuario';
import * as jQuery from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  usuario: Usuario;
  constructor(
    private ConfigService: ConfigService
  ) { }

  swalClose(): void {
    Swal.close();
  }
  //Modals Chileexpress
  async CasilleroAbiertoCHE(puerta,accion): Promise<Usuario> {
    var usuario
    const html =
    '<div class="modal inside-img" style="background-color:white">' +
    '<div class="modal-dialog" role="document" style="position:absolute; ">' +
    '<div class="modal-content text-center" style="width: 100vw;border:none">' +
    '<div class="modal-header">' +
    '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' +
    '</div>' +
      '<div class="modal-body text-center">' +
      '<h2>El casillero seleccionado <br> se encuentra abierto </h2>' +
      '<div class="btn-light p-2 border-lg w-50 mx-auto mt-2">' +
      '<div class="btn-light p-4 border-lg">' +
      '<h3>Puerta </h3>' +
      '<h1 class="display-4"> N° ' + puerta + ' </h1>' +
      '</div>' +
      '</div>' +
      '<h3 class="mt-2"> '+accion+' <br> cierra el casillero.</h3>' +
      '<img src = "assets/img/Locker-agregar.gif" style = "margin-left:-80px;margin-top:-80px">' +
      '</div>' +
      '<div class="col-12 text-center mt-4">'+
      '<h3>N° Orden de trabajo</h3>'+
      '<div class="row mt-3">'+
      '<div class="col-6 mt-1 rounded shadow mx-auto" style="height: 150px;">'+
      '<img src="assets/img/ordenTrabajo.PNG" alt="" style="width: 100%; background-size: cover;">'+
      '</div>'+
      '</div>'+
      '</div>'+
      '</div>' +
      '</div>' +
      '</div>';
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async CasilleroAbiertoCargaCHE(puerta): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal inside-img" style="background-color:white">' +
      '<div class="modal-dialog" role="document" style="position:absolute; ">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header">' +
      // '<div class="modal">' +
      // '<div class="modal-dialog" role="document">' +
      // '<div class="modal-content text-center" >' +
      // '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h2>El casillero seleccionado <br> se encuentra abierto </h2>' +
      '<div class="btn-light p-2 border-lg w-50 mx-auto mt-2">' +
      '<div class="btn-light p-4 border-lg">' +
      '<h3>Puerta </h3>' +
      '<h1 class="display-4"> N° ' + puerta + ' </h1>' +
      '</div>' +
      '</div>' +
      '<h5 class="mt-2"> Ingresa la encomienda y <br> cierra el casillero.</h5>' +
      '<img src = "assets/img/Locker-agregar.gif" style = "margin-left:-80px;margin-top:-80px">' +
      '<div  style="text-align: -webkit-center;"><h4 class="mt-1" style="width:90%;"> Si tu pieza no cumple con las dimensiones del casillero, puedes cambiar el tamaño.</h4></div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3" >' +
      '<button id="btnB" type = "button" class="btn btn-primary btn-lg" style="padding-left:170px!important;padding-right:170px!important" > Continuar </button>' +
      '<button id="btnA" type="button" class="btn btn-outline-primary btn-lg  mt-4" style="padding-left:170px!important;padding-right:170px!important" data - dismiss="modal" > Cambiar tamaño </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#btnA", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#btnB", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async DondeEncuentroOT(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal inside-img" style="background-color:white">' +
      '<div class="modal-dialog" role="document" style="position:absolute; ">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<button type="button" id="CerrarX"  class="close closem" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '<div class="modal-header  d-flex justify-content-center mt-5 pt-5"> <img src="assets/img/ico-info.svg" style = "height: 80px;" class="mb-4" >' +
      // '<button type="button" id="CerrarX" style="position: fixed; margin-left: 380px;"  class="close" data-dismiss="modal" aria-label="Close">'+
      // '<span aria-hidden="true">&times;</span>'+
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h1 class="mt-2">¿Dónde encuentro mi  <br> número de OT? </h1>' +
      // '<div class="btn-light p-2 border-lg w-50 mx-auto mt-2">' +
      // '<div class="btn-light p-4 border-lg">' +
      // '<h3>Puerta </h3>' +
      // '<h1 class="display-4"> N° ' + puerta + ' </h1>' +
      // '</div>' +
      // '</div>' +
      '<img class="mt-5 mb-3" src = "assets/img/DondeOt.png">' +
      '<div  style="text-align: -webkit-center;"><h4 class="mt-1" style="width:70%;"> La Orden de Transporte (OT) <strong>es un código único para cada envío, </strong>' +
      ' que está compuesto por <strong>10 a 12 dígitos.</strong> Este número <strong> puedes encontrarlo en la parte superior del comprobante </strong> que te dan cuando envías algo con nosotros y, ' +
      ' por eso, es importante que no lo pierdas.</h4></div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3" style="position:fixed;bottom:60px;margin-left: 20%;">' +

      '<button id="btnA" type="button" class="btn btn-outline-primary btn-lg" style="padding-left:170px!important;padding-right:170px!important" data - dismiss="modal" > Volver </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#btnA", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#btnB", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(255,255,255,0.1)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }


  async DondeEncuentroPIN(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
    '<div class="modal inside-img" style="background-color:white">' +
    '<div class="modal-dialog" role="document" style="position:absolute; ">' +
    '<div class="modal-content text-center" style="width: 100vw;border:none">' +
    '<button type="button" id="CerrarX"  class="close closem" data-dismiss="modal" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' +
    '<div class="modal-header  d-flex justify-content-center mt-5 pt-5"> <img src="assets/img/ico-info.svg" style = "height: 80px;" class="mb-4" >' +
    // '<button type="button" id="CerrarX" style="position: fixed; margin-left: 380px;"  class="close" data-dismiss="modal" aria-label="Close">'+
    // '<span aria-hidden="true">&times;</span>'+
    '</div>' +
    '<div class="modal-body text-center">' +
    '<h2>¿Donde Encuentro mi  <br>PIN de seguridad? </h2>' +
      // '<div class="btn-light p-2 border-lg w-50 mx-auto mt-2">' +
      // '<div class="btn-light p-4 border-lg">' +
      // '<h3>Puerta </h3>' +
      // '<h1 class="display-4"> N° ' + puerta + ' </h1>' +
      // '</div>' +
      // '</div>' +
      '<img src = "assets/img/DondeOt.png">' +
      '<div  style="text-align: -webkit-center;"><h4 class="mt-1" style="width:90%;"> La Orden de Transporte (OT) <strong>es un código único para cada envío, </strong>' +
      ' que está compuesto por <strong>10 a 12 dígitos.</strong> Este número <strong> puedes encontrarlo en la parte superiro del comprobante </strong> que te dan cuando envías algo con nosotros y, ' +
      ' por eso, es importante que no lo pierdas.</h4></div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3">' +
      '<button id="btnA" type="button" class="btn btn-primary btn-lg btn-alert" data - dismiss="modal" > Volver </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#btnA", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#btnB", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async AdmitiendoencomiendaCHE(): Promise<Usuario> {
    var usuario
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h2>Admitiendo encomienda</h2>' +
      '<h4>Compuerta cerrada correctamente.</h4>' +
      '<div class="mt-3" style="width: initial;margin-left: -40px;">' +
      '<img src="assets/img/box-entrega.gif" style="height: 306px;margin-left:40px" class="mb-5">' +
      '</div>' +
      '<h3 class="font-weight-normal">Espera unos segundos...</h3>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async ImpresionComprobanteCHE(): Promise<Usuario> {
    var usuario
    var esto = this;
    const html =
      '<div class="modal inside-img" style="background-color:white">' +
      '<div class="modal-dialog" role="document" style="position:absolute; ">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mb-5">' +
      // '<div class="modal">' +
      // '<div class="modal-dialog" role="document">' +
      // '<div class="modal-content text-center" >' +
      // '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/ico-ok (2).svg" style="height: 80px;" class="mb-5">' +
      '<h2>Tu comprobante<br> fue impreso</h2>' +
      '<h4 class="font-weight-normal mt-5 mb-5">Gracias por utilizar nuestros servicios.</h4>' +
      '<div><img src="assets/img/ticket-chilexpress.gif" style="height: 430px; " class="mb-5"></div>' +
      // '</br><h4 class="font-weight-normal mt-5 mb-5"><span  id="CerrarX" style="text-decoration: underline;">Ir al inicio</span> o <span   id="menup" style="text-decoration: underline;">Cerrar sesión<span></h4>' +
      '<div class="modal-footer text-center mb-3 mt-3 justify-content-center" >' +
      '<button id="menup" type = "button" class="btn btn-primary btn-lg " style="padding-left:170px!important;padding-right:170px!important" > Continuar </button>' +
     // '<button id="CerrarX" type="button" class="btn btn-outline-primary btn-lg mt-4" style="padding-left:170px!important;padding-right:170px!important" data - dismiss="modal" > Ir al inicio </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
      $(document).on('click', "#CerrarX", function () {
        usuario = true;
        esto.swalClose();
        return usuario;
      });
      $(document).on('click', "#menup", function () {
        usuario = false;
        esto.swalClose();
        return usuario;
      });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  // ENVIO CORREO IMPRESION BOLETA
  async RealizarOtraAcciionCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog">' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<h3>¿Quieres realizar otra acción? </h3>' +
      '<div class="mt-5" style = "height: 120px;margin-left: -40px;" >' +
      '<img src="assets/img/box-ch.svg" style = "height: 120px;" class="inside-pop" class="mb-5" >' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3" >' +
      '<button id="btnA" type="button" class="btn btn-primary btn-lg btn-alert btn-alert" data - dismiss="modal" > Si, deseo realizar </button>' +
      '<button id="btnB" type = "button" class="btn btn-outline-primary btn-lg btn-alert mt-3" > No, Finalizar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#btnA", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#btnB", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  };


  async EnviarCorreoFacturaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h2>Tu comprobante<br> será enviado a</h2>' +
      '<h2>mi************@gmail.com</h2>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button type="button"  id="sendmail" class="btn btn-primary btn-lg btn-alert" data-dismiss="modal">Enviar a email</button>' +
      '<a id="sendothermail">' +
      '<h4 class="font-weight-normal mt-4" style="text-decoration: underline;">Enviar a otro email</h4>' +
      '</a>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#sendothermail", function () {
      usuario = true;
      //console.log(usuario);

      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#sendmail", function () {
      usuario = false;
      //console.log(usuario);
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async ReEnviarPinValidacionCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h2>El código de acceso será enviado al mail registrado</h2>' +
      '<h3>mi************@gmail.com</h3>' +
      '<h3>mi2************@gmail.com</h3>' +
      '<div class="mt-4" style="height: 120px">' +
      '<img src="assets/img/mail-2.svg" style="height: 120px;" class="inside-pop"' +
      ' class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3">' +
      '<button id="enviar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal">Enviar</button>' +
      '<button id="cancelar" type="button" class="btn btn-outline-primary btn-lg btn-alert mt-3">Cancelar</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cancelar", function () {
      usuario = false;
      //console.log(usuario);

      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#enviar", function () {
      usuario = true;
      //console.log(usuario);
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async ReenviandoPinChe(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h2>Enviando código de acceso</h2>' +
      '<div class="mt-4 p-4" style="height: 320px">' +
      '<img src="assets/img/mail-gif.gif" class="mb-5">' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async CambiarcorreoCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class=" modal-dialog modal-dialog2" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h2> Ingresa el email al que<br> quieres enviar el<br> comprobante' +
      '</h2>' +
      '<input type="email"  id="mailnew" class="form-control-lg w-100 mt-4 text-center" placeholder="Nuevo correo">' +
      '<div class="row col-12 pl-0 mt-5 mx-auto d-flex justify-content-center">' +


      '<div class="col-12 d-flex justify-content-center">' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'1'" + '"> 1</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'2'" + '"> 2</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'3'" + '"> 3</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'4'" + '"> 4</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'5'" + '"> 5</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'6'" + '"> 6</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'7'" + '"> 7</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'8'" + '"> 8</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'9'" + '"> 9</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'0'" + '"> 0</div></div>' +
      '</div>' +

      '<div class="col-12 d-flex justify-content-center">' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'q'" + '"> q</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'w'" + '"> w</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'e'" + '"> e</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'r'" + '"> r</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'t'" + '"> t</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'y'" + '"> y</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'u'" + '"> u</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'i'" + '"> i</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'o'" + '"> o</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'p'" + '"> p</div></div>' +
      '</div>' +
      '<div class="col-12 d-flex justify-content-center">' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'a'" + '"> a</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'s'" + '"> s</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'d'" + '"> d</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'f'" + '"> f</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'g'" + '"> g</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'h'" + '"> h</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'j'" + '"> j</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'k'" + '"> k</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'l'" + '"> l</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'@'" + '"> @</div></div>' +
      '</div>' +

      '<div class="col-12 d-flex justify-content-center">' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'z'" + '"> z</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'x'" + '"> x</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'c'" + '"> c</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'v'" + '"> v</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'b'" + '"> b</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'n'" + '"> n</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'m'" + '"> m</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'.'" + '"> .</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'-'" + '"> -</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value +=  ' + "'_'" + '"> _</div></div>' +
      '<div class="col-1"><div class="tecla-key2 btn-light" onclick="document.getElementById' + "('mailnew')" + '.value = document.getElementById' + "('mailnew')" + '.value.length > 0? document.getElementById' + "('mailnew')" + '.value.substring(0, document.getElementById' + "('mailnew')" + '.value.length - 1):document.getElementById' + "('mailnew')" + '.value "> ' +
      '<img style="height: 23px;margin-bottom: 5px;" src="assets/img/backspace.svg"></div>' +
      '</div>' +
      '</div>' +

      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button type="button" id="enviarcorreo" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal">Enviar</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#enviarcorreo", function () {
      usuario = document.getElementById('mailnew');
      usuario = usuario.value;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }


  async CorreoEnviadoCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal inside-img" style="background-color:white">' +
      '<div class="modal-dialog" role="document" style="position:absolute; ">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mb-5">' +
      // '<div class="modal">' +
      // '<div class="modal-dialog" role="document">' +
      // '<div class="modal-content text-center" >' +
      // '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/ico-ok (2).svg" style="height: 80px;" class="mb-5">' +
      '<h2>Tu comprobante<br> fue enviado al email</h2>' +
      '<h4 class="font-weight-normal mt-5 mb-5">Gracias por utilizar nuestros servicios.</h4>' +
      '<div><img src="assets/img/mail-gif.gif" style="height: 360px;position: relative;right: 134px;" class="mb-5"></div>' +
      // '</br><h4 class="font-weight-normal mt-5 mb-5"><span  id="CerrarX" style="text-decoration: underline;">Ir al inicio</span> o <span   id="menup" style="text-decoration: underline;">Cerrar sesión<span></h4>' +
      '<div class="modal-footer text-center mb-3 mt-3 justify-content-center" >' +
      '<button id="menup" type = "button" class="btn btn-primary btn-lg " style="padding-left:170px!important;padding-right:170px!important" > Continuar </button>' +
      // '<button id="CerrarX" type="button" class="btn btn-outline-primary btn-lg mt-4" style="padding-left:170px!important;padding-right:170px!important" data - dismiss="modal" > Ir al inicio </button>' +
       '</div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
      $(document).on('click', "#CerrarX", function () {
        usuario = true;
        esto.swalClose();
        return usuario;
      });
      $(document).on('click', "#menup", function () {
        usuario = false;
        esto.swalClose();
        return usuario;
      });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }


  async CorreoeimpreionEnviadoCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal inside-img" style="background-color:white">' +
      '<div class="modal-dialog" role="document" style="position:absolute; ">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mb-5">' +
      // '<div class="modal">' +
      // '<div class="modal-dialog" role="document">' +
      // '<div class="modal-content text-center" >' +
      // '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/ico-ok (2).svg" style="height: 80px;" class="mb-5">' +
      '<h2>Tu comprobante fue<br>impreso y enviado a email</h2>' +
      '<h4 class="font-weight-normal mt-5 mb-5">Gracias por utilizar nuestros servicios.</h4>' +
      '<div><img src="assets/img/mail-gif.gif" style="height: 360px;position: relative;right: 134px;" class="mb-5"></div>' +
      // '</br><h4 class="font-weight-normal mt-5 mb-5"><span  id="CerrarX" style="text-decoration: underline;">Ir al inicio</span> o <span   id="menup" style="text-decoration: underline;">Cerrar sesión<span></h4>' +
      '<div class="modal-footer text-center mb-3 mt-3 justify-content-center" >' +
      '<button id="menup" type = "button" class="btn btn-primary btn-lg " style="padding-left:170px!important;padding-right:170px!important" > Continuar </button>' +
      // '<button id="CerrarX" type="button" class="btn btn-outline-primary btn-lg mt-4" style="padding-left:170px!important;padding-right:170px!important" data - dismiss="modal" > Ir al inicio </button>' +
       '</div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
      $(document).on('click', "#CerrarX", function () {
        usuario = true;
        esto.swalClose();
        return usuario;
      });
      $(document).on('click', "#menup", function () {
        usuario = false;
        esto.swalClose();
        return usuario;
      });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async EnvioProbleReporte(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/check-exito.svg" style="height: 80px;" class="mb-5">' +
      '<h2>Tu reporte fue<br>enviado correctamente</h2>' +
      '<h4 class="font-weight-normal mt-5 mb-5">Gracias por utilizar nuestros servicios.' +
      '</h4>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }
  // ENVIO CORREO IMPRESION BOLETA
  async CerrarSesion(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/ico-info.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Cerrar sesión</h2>' +
      '<h4> ¿Quieres salir de tu sesión? </h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Cerrar sesión </button>' +
      '<button id="cancelar" type="button" class="btn btn-outline-primary btn-lg btn-alert mt-3">Cancelar</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#cancelar", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }


  //ERRORES



  async OTnoAdmitidaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>No encontramos tu </br>  orden de transporte.  </h2>' +
      '<h4 class="font-weight-normal mt-2" > Por favor, acércate al módulo de atención en la sucursal.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Volver </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async LockerVacio(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Locker sin orden </br> de transporte dentro.  </h2>' +
      '<h4 class="font-weight-normal mt-2" > Por favor, acércate al módulo de atención en la sucursal.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Volver </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }


  async ImpresoraError(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Hubo un problema  </br>al momento de imprimir</h2>' +
      '<h4 class="font-weight-normal mt-2" > Si el error persiste, acércate al módulo de atención en la sucursal. ' +
      '</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Cerrar sesión </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }
  async ErroFrecuente(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Ha ocurrido  un error </h2>' +
      '<h4 class="font-weight-normal mt-2" > Se ha presentado un error al realizar esta ' +
      'accion, por favor comuniquese con un encargado.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Volver al Inicio </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async ErrorCarnet(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Error de escaneo </h2>' +
      '<h4 class="font-weight-normal mt-2" >Inténtalo nuevamente, Si el error persiste, acércate al módulo de atención en la sucursal.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-outline-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Cerrar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async ErrorHuella(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Error de lectura </h2>' +
      '<h4 class="font-weight-normal mt-2" >Inténtalo nuevamente, Si el error persiste, acércate al módulo de atención en la sucursal.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-outline-primary btn-lg btn-alert"' +
      'data-dismiss="modal" >Cerrar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async ErrorLockerFull(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>No tenemos casilleros  </br>  disponibles. </h2>' +
      '<h4 class="font-weight-normal mt-2" > Por favor, acércate al módulo ' +
      'de atención en la sucursal para </br>continuar con tu envío. </h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Cerrar sesión </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async DatosnoAdmitidaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Datos invalidos </h2>' +
      '<h4 class="font-weight-normal mt-2" > Los datos ingresados no son válidos. Por favor, intentalo nuevamente.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Reintentar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async PinnoAdmitidaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/alert.svg" style="height: 80px;" class="mb-4">' +
      '<h2>Pin incorrecto</h2>' +
      '<h4 class="font-weight-normal mt-2">Ingresa el pin nuevamente. Si </br> el error persiste,' +
      ' dirígete al </br> módulo de atención.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Reintentar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }


  async CasilleroVacioCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Número de OT </br> no admitido </h2>' +
      '<h4 class="font-weight-normal mt-2" > El número de tu OT no es valida o no se' +
      ' encuentra dentro del periodo.Por favor, dirígete a una oficina comercial o al' +
      ' mesón si es que ya estás en una.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Reintentar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async PuertaAunAbiertaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal ">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/alert.svg" style="height: 80px;" class="mb-4">' +
      '<h2>La puerta sigue abierta</h2>' +
      '<h4 class="font-weight-normal mt-2">Por favor cierra la compuerta para continuar con' +
      ' el ingreso de la encomienda.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal">Volver</button>' +
      '<button id="reportar" type="button" class="btn btn-outline-primary btn-lg btn-alert mt-3">Reportar' +
      ' problema</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#reportar", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async PuertaAunVaciaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal ">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content text-center">' +
      '<div class="modal-header">' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<img src="assets/img/alert.svg" style="height: 80px;" class="mb-4">' +
      '<h2>Casillero sin pedido</h2>' +
      '<h4 class="font-weight-normal mt-2">Por favor ingresa el pedido para continuar ' +
      '.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal">Volver</button>' +
      '<button id="reportar" type="button" class="btn btn-outline-primary btn-lg btn-alert mt-3">Reportar' +
      ' problema</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#reportar", function () {
      usuario = false;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }


  async PuertaNoAbiertaCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Ha ocurrido un</br>problema al abrir el casillero.</h2>' +
      '<h4 class="font-weight-normal mt-2" > Acércate al módulo de atención.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Reintentar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }

  async CasilleroOcupadoCHE(): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal">' +
      '<div class="modal-dialog" role = "document" >' +
      '<div class="modal-content text-center" >' +
      '<div class="modal-header" >' +
      '<button type="button" id="CerrarX"  class="close" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center" >' +
      '<img src="assets/img/alert.svg" style = "height: 80px;" class="mb-4" >' +
      '<h2>Casillero ocupado </h2>' +
      '<h4 class="font-weight-normal mt-2" > El casillero que has seleccionado se encuentra ocupado, por favor elige uno disponible.</h4>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3">' +
      '<button id="cerrar" type="button" class="btn btn-primary btn-lg btn-alert"' +
      'data-dismiss="modal" > Reintentar </button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    $(document).on('click', "#cerrar", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return usuario;
  }
  //ERRORES

  // impresion etiequeta

  async EtiquetaImpresa(): Promise<Usuario> {
    var usuario;
    var esto = this;
    var texto = "imprimiendo"
    const html =
      '<div class="modal inside-img pt-5" style="background-color:white">' +
      '<button type="button" id="CerrarX"  class="close closem" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      //primer div
      '<div class="modal-dialog mt-5 pt-5" role="document" style="position:absolute; " id="Principal">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mt-5 d-flex justify-content-center">' +
      '</div>' +
      '<div class="modal-body text-center mb-5">' +
      '<div class="wizard mt-4 mb-5">' +
      '<div class="pasoAct">' +
      '<div class="circlestp colorAct"></div>' +
      '<div class="linestp  colorAct"></div>' +
      '<div class="linestp  colorAct"></div>' +
      '</div>' +
      '<div class="pasoDesc">' +
      '<div class="circlestp circlestpAct"></div>' +
      '</div>' +
      '</div>' +
      '<h2>¿Tienes tu etiqueta<br> Chilexpress impresa y pegada?</h2>' +
      '<div class="mt-2" style="height: 120px">' +
      '<img src="assets/img/etiqueta.svg" style="height: 120px;" class="inside-pop mt-4 mb-5"' +
      'class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-5">' +
      '<button id="impresa" type="button" class="btn btn-primary btn-lg" data-dismiss="modal">Sí, está' +
      ' impresa y pegada</button>' +
      '<button id="imprimir" type="button" class="btn btn-outline-primary btn-lg mt-4">Imprimir ' +
      'etiqueta</button>' +
      '<div class="w-100 mt-3"><hr style="border-top: 1px solid rgba(0, 0, 0, 0.4)!important"></div>' +
      '<div class="text-center w-100 pt-4 pb-3 " id="CerrarX" style="text-decoration-line: underline"><h4>No volver a preguntar</h4></div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      //primer div
      //segunda div
      '<div class="modal-dialog mt-5 pt-5" role="document" style="position:absolute; display:none" id="Secundaria">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mt-5 d-flex justify-content-center">' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center mb-5">' +
      '<div class="wizard mt-4 mb-5">' +
      '<div class="pasoAct">' +
      '<div class="circlestp colorAct"></div>' +
      '<div class="linestp colorAct"></div>' +
      '<div class="linestp colorDct"></div>' +
      '</div>' +
      '<div class="pasoDesc">' +
      '<div class="circlestp colorDct"></div>' +
      '</div>' +
      '</div>' +
      '<h2>Se está ' + "imprimiendo" + '<br>tu etiqueta</h2>' +
      '<h4 class="mt-5">Pégala en la parte superior de tu encomienda.</h4>' +
      '<div class="mt-2" style="height: 120px">' +
      '<img src="assets/img/etiquetar.gif" style="height: 120px;" class="inside-pop"' +
      'class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3">' +
      '<button id="pegada" type="button" class="btn btn-primary btn-lg mb-4 " data-dismiss="modal">Ya pegué mi etiqueta</button>' +
      '<button id="reimprimir" type="button" class="btn btn-outline-primary btn-lg ">Reimprimir' +
      ' etiqueta</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      //segunda div
      //tercera div
      '<div class="modal-dialog mt-5 pt-5" role="document" style="position:absolute; display:none" id="tercera">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mt-5 d-flex justify-content-center">' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center mb-5">' +
      '<div class="wizard mt-4 mb-5">' +
      '<div class="pasoAct">' +
      '<div class="circlestp colorAct"></div>' +
      '<div class="linestp colorAct"></div>' +
      '<div class="linestp colorDct"></div>' +
      '</div>' +
      '<div class="pasoDesc">' +
      '<div class="circlestp colorDct"></div>' +
      '</div>' +
      '</div>' +
      '<h2>Se está ' + "reimprimiendo" + '<br>tu etiqueta</h2>' +
      '<h4 class="mt-5">Pégala en la parte superior de tu encomienda.</h4>' +
      '<div class="mt-2" style="height: 120px">' +
      '<img src="assets/img/etiquetar.gif" style="height: 120px;" class="inside-pop"' +
      'class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3">' +
      '<button id="pegada" type="button" class="btn btn-primary btn-lg mb-4 " data-dismiss="modal">Ya pegué mi etiqueta</button>' +
      '<button id="reimprimir" type="button" class="btn btn-outline-primary btn-lg ">Reimprimir' +
      ' etiqueta</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      //tercera div
      '</div>';
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#imprimir", function () {
      $("#Principal").hide();
      $("#Secundaria").show();
      $("#tercera").hide();
      //texto = "imprimiendo";
    });
    $(document).on('click', "#impresa", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#pegada", function () {
      $("#Principal").show();
      $("#Secundaria").hide();
      $("#tercera").hide();
    });
    $(document).on('click', "#reimprimir", function () {
      //$("#Principal").show();
      //$("#Secundaria").hide();
      $("#Principal").hide();
      $("#Secundaria").hide();
      $("#tercera").show();
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  async EtiquetapegadaCHe(texto): Promise<Usuario> {
    var usuario;
    var esto = this;
    const html =
      '<div class="modal inside-img pt-5" style="background-color:white">' +
      '<button type="button" id="CerrarX"  class="close closem" data-dismiss="modal" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' +
      //primer div
      '<div class="modal-dialog mt-5 pt-5" role="document" style="position:absolute; display:none" id="Principal">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mt-5 d-flex justify-content-center">' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center mb-5">' +
      '<div class="wizard mt-4 mb-5">' +
      '<div class="pasoAct">' +
      '<div class="circlestp colorAct"></div>' +
      '<div class="linestp colorAct"></div>' +
      '<div class="linestp colorAct"></div>' +
      '</div>' +
      '<div class="pasoDesc">' +
      '<div class="circlestp circlestpAct"></div>' +
      '</div>' +
      '</div>' +
      '<h2>¿Tienes tu etiqueta<br> Chilexpress impresa y pegada?</h2>' +
      '<div class="mt-2" style="height: 120px">' +
      '<img src="assets/img/etiqueta.svg" style="height: 120px;" class="inside-pop mt-4 mb-5"' +
      'class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-5">' +
      '<button id="impresa" type="button" class="btn btn-primary btn-lg " data-dismiss="modal">Sí, está' +
      ' impresa y pegada</button>' +
      '<button id="imprimir" type="button" class="btn btn-outline-primary btn-lg mt-4 ">Imprimir ' +
      'etiqueta</button>' +
      '<div class="w-100 mt-3"><hr style="border-top: 1px solid rgba(0, 0, 0, 0.4)!important"></div>' +
      '<div class="text-center w-100 pt-4 pb-3 " id="CerrarX" style="text-decoration-line: underline"><h4>No volver a preguntar</h4></div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      //primer div
      //segunda div
      '<div class="modal-dialog mt-5 pt-5" role="document" style="position:absolute; display:none" id="Secundaria">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mt-5 d-flex justify-content-center">' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center mb-5">' +
      '<div class="wizard mt-4 mb-5">' +
      '<div class="pasoAct">' +
      '<div class="circlestp colorAct"></div>' +
      '<div class="linestp colorAct"></div>' +
      '<div class="linestp colorDct"></div>' +
      '</div>' +
      '<div class="pasoDesc">' +
      '<div class="circlestp colorDct"></div>' +
      '</div>' +
      '</div>' +
      '<h2>Se está ' + "imprimiendo" + '<br>tu etiqueta</h2>' +
      '<h4 class="mt-5">Pégala en la parte superior de tu encomienda.</h4>' +
      '<div class="mt-2" style="height: 120px">' +
      '<img src="assets/img/etiquetar.gif" style="height: 120px;" class="inside-pop"' +
      'class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3">' +
      '<button id="pegada" type="button" class="btn btn-primary btn-lg mb-4 " data-dismiss="modal">Ya pegué mi etiqueta</button>' +
      '<button id="reimprimir" type="button" class="btn btn-outline-primary btn-lg ">Reimprimir' +
      ' etiqueta</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      //segunda div
      //tercera div
      '<div class="modal-dialog mt-5 pt-5" role="document" style="position:absolute;" id="tercera">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header mt-5 d-flex justify-content-center">' +
      '</button>' +
      '</div>' +
      '<div class="modal-body text-center mb-5">' +
      '<div class="wizard mt-4 mb-5">' +
      '<div class="pasoAct">' +
      '<div class="circlestp colorAct"></div>' +
      '<div class="linestp colorAct"></div>' +
      '<div class="linestp colorDct"></div>' +
      '</div>' +
      '<div class="pasoDesc">' +
      '<div class="circlestp colorDct"></div>' +
      '</div>' +
      '</div>' +
      '<h2>Se está ' + "reimprimiendo" + '<br>tu etiqueta</h2>' +
      '<h4 class="mt-5">Pégala en la parte superior de tu encomienda.</h4>' +
      '<div class="mt-2" style="height: 120px">' +
      '<img src="assets/img/etiquetar.gif" style="height: 120px;" class="inside-pop"' +
      'class="mb-5">' +
      '</div>' +
      '</div>' +
      '<div class="modal-footer text-center mb-3 mt-3">' +
      '<button id="pegada" type="button" class="btn btn-primary btn-lg mb-4 " data-dismiss="modal">Ya pegué mi etiqueta</button>' +
      '<button id="reimprimir" type="button" class="btn btn-outline-primary btn-lg ">Reimprimir' +
      ' etiqueta</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      //tercera div
      '</div>';
    $(document).on('click', "#CerrarX", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#imprimir", function () {
      $("#Principal").hide();
      $("#Secundaria").show();
      $("#tercera").hide();
      //texto = "imprimiendo";
    });
    $(document).on('click', "#impresa", function () {
      usuario = true;
      esto.swalClose();
      return usuario;
    });
    $(document).on('click', "#pegada", function () {
      $("#Principal").show();
      $("#Secundaria").hide();
      $("#tercera").hide();
    });
    $(document).on('click', "#reimprimir", function () {
      //$("#Principal").show();
      //$("#Secundaria").hide();
      $("#Principal").hide();
      $("#Secundaria").hide();
      $("#tercera").show();
    });
    await Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }




  // impresion etiequeta

  //Modals ChileExpress



  async swalSuccess(text: string) {
    await Swal.fire({
      title: text,
      confirmButtonColor: '#00AA9F',
    });
  }

  async swalInfo(message: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      imageUrl: 'assets/img/alert.png',
      text: message,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
        content: 'text-orange-isa'
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalError(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: 'aaaa',
      text,
      timer: 20000,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then(async (result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        //TEMPORIZADOR
      } else if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalErrorPuerta(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: '¡Error de apertura!',
      text,
      timer: 20000,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then(async (result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        //TEMPORIZADOR
      } else if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalConfirmApertura(data): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: '¿Realizar Apertura?',
      text: "Éste casillero contiene un pedido del " + data + " ¿Desea Abrirlo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.value) {
        response = true;
      }
      else {
        response = false;
      }
    });
    return response;
  }

  async ErrorPuerta(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: 'Error al abrir el casillero!',
      text: "Por favor comuniquese con algun empleado.",
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async PuertaPc(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: '¡Error al abrir el casillero!',
      text: "Éste casillero no puede ser abierto.",
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalConfirmCarga(data): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: '¿Realizar Carga?',
      text: '¡Se realizara la carga del producto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Cargar'
    }).then((result) => {
      if (result.value) {
        response = true;
      }
      else {
        response = false;
      }
    });
    return response;
  }

  async swalConfirmCasilleroOcupado(): Promise<boolean> {
    let response = false;
    await Swal.fire({
      title: '¡Casillero Ocupado!',
      text: "Este casillero se encuentra ocupado. Por favor elija uno libre",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Reintentar'
    }).then((result) => {
      if (result.value) {
        response = true;
      }
      else {
        response = false;
      }
    });
    return response;
  }

  swalLoading(): void {
    const html =
      '<div class="modal inside-img" style="background-color:white">' +
      '<div class="modal-dialog" role="document" style="position:absolute; top: 400px; ">' +
      '<div class="modal-content text-center" style="width: 100vw;border:none">' +
      '<div class="modal-header">' +
      '</div>' +
      '<div class="modal-body text-center">' +
      '<h1>Cargando <br> Por favor espere </h1> <br><br><br>' +
      '<img src = "assets/img/carga.gif" style = "margin-left:-80px;margin-top:-80px">' +
      '<div  style="text-align: -webkit-center;"><h2 class="mt-1" style="width:90%;"> Se estan Cargando los datos por favor espera mientras se culmina la accion.</h2></div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';
    Swal.fire({
      html: html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(0,0,0,0.95)',
      allowOutsideClick: false,
      customClass: {

      }
    });
  }

  async agregarUsuario(roles = []): Promise<Usuario> {
    var usuario
    const html =
      '<div class="row">' +
      '<div class="form-group col-12">' +
      '<label class="col-sm-12 col-form-label">' +
      "RUT" +
      '</label>' +
      '<div class="col-sm-12">' +
      '<input type="text" id="rut" class="form-control">' +
      '</div>' +
      '</div>' +

      '<div class="form-group col-12">' +
      '<label class="col-sm-12 col-form-label">' +
      "Rol" +
      '</label>' +
      '<div class="col-sm-12">' +
      '<select class="form-control" id="rol">' +
      '<option>Seleccion un rol</option>' +
      '</select>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '<div class="row">' +
      '<div class="form-group col-12">' +
      '<label class="col-sm-12 col-form-label">' +
      "Nombre" +
      '</label>' +
      '<div class="col-sm-12">' +
      '<input type="text" id="nombre" class="form-control">' +
      '</div>' +
      '</div>' +
      '<div class="form-group col-12">' +
      '<label class="col-sm-12 col-form-label">' +
      "Apellido" +
      '</label>' +
      '<div class="col-sm-12">' +
      '<input type="text" id="apellido" class="form-control">' +
      '</div>' +
      '</div>' +
      '</div>';
    await Swal.fire({
      html: html,
      showCancelButton: true,
      allowOutsideClick: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Agregar',
      confirmButtonColor: 'green',
      cancelButtonColor: 'red'
    }).then((result) => {
      usuario = {
        "rut": (<HTMLInputElement>document.getElementById('rut')).value,
        "idRol": parseInt((<HTMLInputElement>document.getElementById('rol')).value),
        "nombre": (<HTMLInputElement>document.getElementById('nombre')).value,
        "apellido": (<HTMLInputElement>document.getElementById('apellido')).value,
        "clave": (<HTMLInputElement>document.getElementById('nombre')).value,
        "estado": 0
      }
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
