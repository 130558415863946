<!-- <div class="d-flex tecladoshow" *ngIf="shoekb">
    <div class="col-24 centrar-teclado">
        <!-- Teclado 
        <div class="caja-botones col-24">
            <div class=" boton col-1" *ngFor="let number of numberbutton; index as i; last as last">
                <div *ngIf="!last" class="contenido-boton text-center animated bounceIn" (click)="setnota(i)">
                    {{number}}</div>
                <div *ngIf="last" class="contenido-boton text-center button-borrar bgi-del" (click)="borar()">
                    <div><i class="pi pi-chevron-left"></i></div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- 
<div style="color: black; position: relative; left: 950px;">
    <h1>Reporte su problema</h1>
    <div>
        <span style="position: relative; left: 50px;">Selecciona un problema</span><br>
        <select name="" id="">
            <option value="">Compuerta no cierra correctamente</option>
            <option value="">Compuerta no abre correctamente</option>
            <option value="">Encomienda no está en el casillero</option>
        </select>
    </div>
    <div style="position: relative; left: 50px;">
        <span>Detalle su problema</span><br>
        <input type="text" placeholder="Rut" [formControl]="rut"><br>
    </div>
    <div>
        <button (click)="enviar()">Enviar</button>
        <button (click)="volver()">Volver</button>
    </div>
</div> -->

<div class="wrapper">
    <div class="container mt-5">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Reporte su<br> problema
                </h1>
            </div>
            <div class="col-11 mt-4 mx-auto text-left">
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Selecciona un problema</label>
                    <select class="form-control form-control-lg" id="exampleFormControlSelect1" rows="10" cols="50">
                        <option>1 Daño de casillero</option>
                        <option>2 Impresora sin papel</option>
                        <option>3 Puerta no abren</option>
                        <option>4 Pedido no entregado</option>
                        <option>5 Locker sin Conexion</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Detalle su problema</label>
                    <textarea [formControl]="rut" class="form-control form-control-lg" id="exampleFormControlSelect1"></textarea>
                </div>
            </div>
            <div class="row col-12 pl-0 mt-5 mx-auto d-flex justify-content-center">
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('1')"> 1</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('2')"> 2</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('3')"> 3</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('4')"> 4</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('5')"> 5</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('6')"> 6</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('7')"> 7</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('8')"> 8</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('9')"> 9</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('0')"> 0</div>
                    </div>
                </div>
            
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('q')"> q</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('w')"> w</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('e')"> e</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('r')"> r</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('t')"> t</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('y')"> y</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('u')"> u</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('i')"> i</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('o')"> o</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('p')"> p</div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('a')"> a</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('s')"> s</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('d')"> d</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('f')"> f</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('g')"> g</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('h')"> h</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('j')"> j</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('k')"> k</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('l')"> l</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('@')"> @</div>
                    </div>
                </div>
            
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('z')"> z</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('x')"> x</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('c')"> c</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('v')"> v</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('b')"> b</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('n')"> n</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('m')"> m</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('.')"> .</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('-')"> -</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="setnota('_')"> _</div>
                    </div>
                    <div class="col-1">
                        <div class="tecla-key2 btn-light" (click)="borar()">
                            <img style="height: 23px;margin-bottom: 5px;" src="assets/img/backspace.svg"></div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-8">
                        <div class="tecla-key2 btn-light w-100" (click)="setnota(' ')"> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="botonera-navegacion">
    <div class="row">
        <div class="col-8 mx-auto">
            <button type="button" class="btn btn-primary btn-lg mt-4 w-100"
            (click)="enviar()">Enviar</button>
            <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100"
            (click)="volver()">Volver</button>
        </div>
    </div>
</div>