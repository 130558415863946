import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-raiting',
  templateUrl: './raiting.component.html',
  styleUrls: ['./raiting.component.scss']
})
export class RaitingComponent implements OnInit {

  constructor(private Router:Router) { }

  puntuacion;

  voto
  ngOnInit(): void {

    this.puntuacion =1;
    this.voto = false;
  }

  cerrarsesion(){
    this.Router.navigate(['login']);
  }

  iranicio(){
    this.Router.navigate(['menuOpciones']);
  }

}
