import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-casillero-abierto-plazo-cumplido2',
  templateUrl: './casillero-abierto-plazo-cumplido2.component.html',
  styleUrls: ['./casillero-abierto-plazo-cumplido2.component.scss']
})
export class CasilleroAbiertoPlazoCumplido2Component implements OnInit {

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
  }

  imprimirEtiqueta2()
  {
    this.router.navigate(['imprimirEtiquetaPlazoCumplido']);
  }

  cargaCorrecta()
  {
    this.router.navigate(['escanearQRFinalizadoPlazoCumplido']);
  }

  async errorPuerta()
  {
    await this.sweetAlertService.swalErrorPuerta("Vuelve a ingresar tu encomienda. Si el problema persiste, entrega tu encomienda al encargado de la oficina");
    this.router.navigate(['casilleroAbierto2']);
  }

}
