<div class="wrapper">
    <div class="container index-class">
        <div class="row text-center">
            <div class="col-12">
                <h1 class="display-3 ">¡Hola!</h1>
                <p class="subtitle">Te damos la bienvenida al sistema<br> de lockers de Chilexpress.</p>
            </div>
            <div class="col-12 mb-5">
                <img src="assets/img/img-index.svg" style="width: 420px;">
            </div>
            <div class="col-12">
                <div class="attack1">
                    <div class="pulse1"></div>
                    <div class="pulse2"></div>
                    <div class="pulse3"></div>
                    <div class="pulse4"></div>
                </div>

            </div>
            <div class="col-12">
                <button type="button" class="btn btn-primary btn-lg mt-5" (click)="change()">Toca la pantalla para continuar</button>
            </div>
        </div>
    </div>
</div>

<!-- <a class="text-decoration-none" (click)="change()">
    <div class="row">
      <div class="col-12 text-center mt-5">
        <img src="./assets/img/touch.gif">
        <div class="row mt-5">
          <div class="col-12 display-1 animated bounceIn infinite slower">Toque la pantalla para continuar</div>
        </div>
      </div>
    </div>
</a>
-->
<div class="btn-izq" (click)="izqbtn()">

</div>

<div class="btnder btn-izq" (click)="derbtn()">

    <!-- </div>  -->