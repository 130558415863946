<div class="mt-2 ml-5">
    <h6>Enviar encomienda > ... Imprimir Etiqueta > <strong> Seleccionar Casillero</strong></h6>
</div>
<div class="wrapper">
    <div class="container">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-2">Selecciona tu Casillero</h1>
                <h3 class="font-weight-normal">Selecciona un casillero entre los disponibles para ingresar tu
                    encomienda.
                </h3>
                <h4 class="mt-3">Dimensiones {{TipoCasSelt}}x30x30</h4>
                <app-locker [Admin]="Admin" (changeStep)="GetLocker($event)">
                </app-locker>

                <!-- <button type="button" class="btn btn-primary btn-lg mt-4" (click)="continuar()">Continuar</button> -->
            </div>


        </div>
    </div>
</div>