<div style="color: black; position: relative; left: 950px;">
    <h2>Pagar Encomienda</h2>
    <img src="./assets/img/caja encomienda.PNG" style="width: 200px; position: relative; top: 60px;">
    <div style="position: relative; right: 810px; top: 50px;">
        <h4 style="color: black; position: relative; left: 860px;"><b>Origen</b></h4><br>
        <h5 style="color: black; position: relative; left: 860px; top: 1px;">Pudahuel</h5><br>
        <h4 style="color: black; position: relative; left: 860px;"><b>Destino</b></h4><br>
        <h5 style="color: black; position: relative; left: 860px; top: 1px;">Concepcion</h5>
    </div>
    <div style="position: relative; right: 1200px; top: 150px;">
        <h5  style="color: black; position: relative; left: 1240px; bottom: 50px;">Total a pagar</h5>
        <span style="color: black; position: relative; left: 1270px; bottom: 40px;"><b>$5.000</b></span>
    </div>
    <div style="position: relative; top: 300px;">
        <button (click)="pago()">Pagar</button>
        <button (click)="volver()">Volver</button>
    </div>
</div>
