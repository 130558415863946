<div style="color: black; position: relative; top: 50px;">
    <div style="position: relative; left: 1100px;">
        <span style="position: relative; left: 130px;">Pieza 2/18</span><br>
        <h2 style="position: relative; left: 50px;">Casillero Abierto</h2>
        <span>En la ilustración se indica ubicación de casillero</span>
    </div>
    <div style="position: relative; left: 1100px; top: 20px;">
        <h5 style="position: relative; left: 120px;">Puerta</h5>
        <span style="position: relative; left: 130px;">N°22</span><br>
        <span style="position: relative; top: 30px;">Retira la encomienda y cierra la puerta</span>
    </div>
    <img src="./assets/img/casillero.PNG" style="width: 200px; position: relative; left: 1150px; top: 80px;">
    <img src="./assets/img/ordenTrabajo.PNG" style="width: 250px; position: relative; left: 920px; top: 430px;">
    <div style="position: relative; left: 1050px; top: 300px;">
        <span style="color: gray; cursor: pointer;" (click)="imprimirEtiqueta2()"><u>Reimprimir etiqueta</u></span>
        <span style="position: relative; right: 50px; bottom: 200px;">Orden de trabajo encomienda:</span>
        <button (click)="cargaCorrecta()" style="position: relative; right: 400px; top: 60px;">Encomienda retirada correctamente</button>
    </div>
</div>
