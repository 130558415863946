import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule  } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CedulaComponent } from './components/cedula/cedula.component';
import { UserIdleModule } from 'angular-user-idle';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import {DataTablesModule} from 'angular-datatables';
import {TableModule} from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import {ChartModule} from 'primeng/chart';
import { ReportComponent } from './components/report/report.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
////////////////////////////
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { VonConfirmationModule } from '@von-development-studio/primeng-confirmation-dialog';
import { ChartComponent } from './components/dashboard/chart/chart.component';
import { ReportePorFechaComponent } from './components/reporte-por-fecha/reporte-por-fecha.component';
import {SidebarModule} from 'primeng/sidebar';
import { AutenticadoComponent } from './components/autenticado/autenticado.component';
import { MenuOpcionesComponent } from './components/menu-opciones/menu-opciones.component';
import { EscanearQRComponent } from './components/escanear-qr/escanear-qr.component';
import { NumOrdenTransporteComponent } from './components/num-orden-transporte/num-orden-transporte.component';
import { QrEscaneadoComponent } from './components/qr-escaneado/qr-escaneado.component';
import { PinValidacionComponent } from './components/pin-validacion/pin-validacion.component';
import { EncomiendaDisponibleComponent } from './components/encomienda-disponible/encomienda-disponible.component';
import { RetirarEncomiendaPuertaComponent } from './components/retirar-encomienda-puerta/retirar-encomienda-puerta.component';
import { EscogerTamanoCasilleroComponent } from './components/escoger-tamano-casillero/escoger-tamano-casillero.component';
import { SeleccionarCasilleroComponent } from './components/seleccionar-casillero/seleccionar-casillero.component';
import { EncomiendaIngresadaComponent } from './components/encomienda-ingresada/encomienda-ingresada.component';
import { PagarEncomiendaComponent } from './components/pagar-encomienda/pagar-encomienda.component';
import { ErrorImpresionComponent } from './components/error-impresion/error-impresion.component';
import {SharedModule} from './components/shared/shared.module';
import { FlujoCargaCourierComponent } from './components/flujo-carga-courier/flujo-carga-courier.component';
import { Inicio2Component } from './components/segundoFlujo/inicio2/inicio2.component';
import { MenuOpciones2Component } from './components/segundoFlujo/menu-opciones2/menu-opciones2.component';
import { EscanearQr2Component } from './components/segundoFlujo/escanear-qr2/escanear-qr2.component';
import { QrEscaneado2Component } from './components/segundoFlujo/qr-escaneado2/qr-escaneado2.component';
import { IdentificacionManualComponent } from './components/segundoFlujo/identificacion-manual/identificacion-manual.component';
import { OrdenTransporte2Component } from './components/segundoFlujo/orden-transporte2/orden-transporte2.component';
import { CasilleroAbierto2Component } from './components/segundoFlujo/casillero-abierto2/casillero-abierto2.component';
import { EscanearQrFinalizarComponent } from './components/segundoFlujo/escanear-qr-finalizar/escanear-qr-finalizar.component';
import { EncomiendaCargada2Component } from './components/segundoFlujo/encomienda-cargada2/encomienda-cargada2.component';
import { EscanearQr2DescargaComponent } from './components/segundoFlujo/escanear-qr2-descarga/escanear-qr2-descarga.component';
import { IdentificacionManualDescargaComponent } from './components/segundoFlujo/identificacion-manual-descarga/identificacion-manual-descarga.component';
import { EncomiendaARetirarComponent } from './components/segundoFlujo/encomienda-aretirar/encomienda-aretirar.component';
import { CasilleroAbiertoRetiroComponent } from './components/segundoFlujo/casillero-abierto-retiro/casillero-abierto-retiro.component';
import { ImprimirEtiqueta2Component } from './components/segundoFlujo/imprimir-etiqueta2/imprimir-etiqueta2.component';
import { CasilleroAbiertoRetiro2Component } from './components/segundoFlujo/casillero-abierto-retiro2/casillero-abierto-retiro2.component';
import { EscanearQrFinalizado2Component } from './components/segundoFlujo/escanear-qr-finalizado2/escanear-qr-finalizado2.component';
import { EscanearQrPlazoCumplidoComponent } from './components/segundoFlujo/escanear-qr-plazo-cumplido/escanear-qr-plazo-cumplido.component';
import { IdentManualPlazoCumplidoComponent } from './components/segundoFlujo/ident-manual-plazo-cumplido/ident-manual-plazo-cumplido.component';
import { EncomiendasARetirarPlazoCumplidoComponent } from './components/segundoFlujo/encomiendas-aretirar-plazo-cumplido/encomiendas-aretirar-plazo-cumplido.component';
import { CasilleroAbiertoPlazoCumplidoComponent } from './components/segundoFlujo/casillero-abierto-plazo-cumplido/casillero-abierto-plazo-cumplido.component';
import { CasilleroAbiertoPlazoCumplido2Component } from './components/segundoFlujo/casillero-abierto-plazo-cumplido2/casillero-abierto-plazo-cumplido2.component';
import { EscanearQrFinalizadoPlazoCumplidoComponent } from './components/segundoFlujo/escanear-qr-finalizado-plazo-cumplido/escanear-qr-finalizado-plazo-cumplido.component';
import { ImprimirEtiquetaPlazoCumplidoComponent } from './components/segundoFlujo/imprimir-etiqueta-plazo-cumplido/imprimir-etiqueta-plazo-cumplido.component';
import { EscanearQrRevisarCasilleroComponent } from './components/segundoFlujo/escanear-qr-revisar-casillero/escanear-qr-revisar-casillero.component';
import { IdentManualRevisarCasilleroComponent } from './components/segundoFlujo/ident-manual-revisar-casillero/ident-manual-revisar-casillero.component';
import { SeleccionarLockerRevisarCasilleroComponent } from './components/segundoFlujo/seleccionar-locker-revisar-casillero/seleccionar-locker-revisar-casillero.component';
import { EscanearQrFinalizadoRevisarCasilleroComponent } from './components/segundoFlujo/escanear-qr-finalizado-revisar-casillero/escanear-qr-finalizado-revisar-casillero.component';
import { EscanearQrReporteProblemaComponent } from './components/segundoFlujo/escanear-qr-reporte-problema/escanear-qr-reporte-problema.component';
import { ReportarProblemaComponent } from './components/segundoFlujo/reportar-problema/reportar-problema.component';
import { ReporteEnviadoComponent } from './components/segundoFlujo/reporte-enviado/reporte-enviado.component';
import { IdentManualReporteProblemaComponent } from './components/segundoFlujo/ident-manual-reporte-problema/ident-manual-reporte-problema.component';
//Raiting
import { RaitingComponent } from './components/raiting/raiting.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReportComponent,
    DashboardComponent,
    ChartComponent,
    CedulaComponent,
    ReportePorFechaComponent,
    AutenticadoComponent,
    MenuOpcionesComponent,
    EscanearQRComponent,
    NumOrdenTransporteComponent,
    QrEscaneadoComponent,
    PinValidacionComponent,
    EncomiendaDisponibleComponent,
    RetirarEncomiendaPuertaComponent,
    EscogerTamanoCasilleroComponent,
    SeleccionarCasilleroComponent,
    EncomiendaIngresadaComponent,
    PagarEncomiendaComponent,
    ErrorImpresionComponent,
    FlujoCargaCourierComponent,
    Inicio2Component,
    MenuOpciones2Component,
    EscanearQr2Component,
    QrEscaneado2Component,
    IdentificacionManualComponent,
    OrdenTransporte2Component,
    CasilleroAbierto2Component,
    EscanearQrFinalizarComponent,
    EncomiendaCargada2Component,
    EscanearQr2DescargaComponent,
    IdentificacionManualDescargaComponent,
    EncomiendaARetirarComponent,
    CasilleroAbiertoRetiroComponent,
    ImprimirEtiqueta2Component,
    CasilleroAbiertoRetiro2Component,
    EscanearQrFinalizado2Component,
    EscanearQrPlazoCumplidoComponent,
    IdentManualPlazoCumplidoComponent,
    EncomiendasARetirarPlazoCumplidoComponent,
    CasilleroAbiertoPlazoCumplidoComponent,
    CasilleroAbiertoPlazoCumplido2Component,
    EscanearQrFinalizadoPlazoCumplidoComponent,
    ImprimirEtiquetaPlazoCumplidoComponent,
    EscanearQrRevisarCasilleroComponent,
    IdentManualRevisarCasilleroComponent,
    SeleccionarLockerRevisarCasilleroComponent,
    EscanearQrFinalizadoRevisarCasilleroComponent,
    EscanearQrReporteProblemaComponent,
    ReportarProblemaComponent,
    ReporteEnviadoComponent,
    IdentManualReporteProblemaComponent,
    RaitingComponent
    
  ],
  imports: [
    BrowserModule,
    ToastModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      progressBar: true
    }),
    UserIdleModule.forRoot({ idle: 240, timeout: 60 }),
    ReactiveFormsModule,
    ModalModule.forRoot(),
    FormsModule,
    ChartsModule,
    DataTablesModule,
    TableModule,
    BrowserAnimationsModule,
    ButtonModule,
    VonConfirmationModule,
    RippleModule,
    ChartModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextareaModule,
    ConfirmDialogModule,
    SidebarModule
  ],
  entryComponents: [],
  providers: [MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
