import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-pagar-encomienda',
  templateUrl: './pagar-encomienda.component.html',
  styleUrls: ['./pagar-encomienda.component.scss']
})
export class PagarEncomiendaComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  pago()
  {
    this.router.navigate(['pagoEncomiendaTarjet']);
  }

  volver()
  {
    this.router.navigate(['qrEscaneado']);
  }

}
