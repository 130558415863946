import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-escanear-qr-finalizado-revisar-casillero',
  templateUrl: './escanear-qr-finalizado-revisar-casillero.component.html',
  styleUrls: ['./escanear-qr-finalizado-revisar-casillero.component.scss']
})
export class EscanearQrFinalizadoRevisarCasilleroComponent implements OnInit {

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
  }

  finalizar()
  {
    this.router.navigate(['menuOpciones2']);
  }


}
