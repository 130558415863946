<!-- <div style="color: black; position: relative; top: 50px;">
    <div style="position: relative; left: 1100px;">
        <h2>Casillero Abierto</h2>
        <h5>Carga la encomienda y cierra la puerta</h5>
    </div>
    <div style="position: relative; left: 1100px; top: 20px;">
        <h5 style="position: relative; left: 120px;">Puerta</h5>
        <span style="position: relative; left: 130px;">N°15</span><br>
        <span style="position: relative; top: 30px;">En la ilustración se indica ubicación de casillero</span>
    </div>
    <img src="./assets/img/casillero.PNG" style="width: 200px; position: relative; left: 1150px; top: 80px;">
    <img src="./assets/img/ordenTrabajo.PNG" style="width: 250px; position: relative; left: 920px; top: 430px;">
    <div style="position: relative; left: 1050px; top: 300px;">
        <span style="position: relative; left: 100px; bottom: 200px;">Orden de trabajo encomienda:</span>
        <button (click)="finalizarCarga()" style="position: relative; right: 100px;">Finalizar carga de envío</button><br>
        <button (click)="cargaCorrecta()" style="position: relative; right: 200px; top: 60px;">Encomienda cargada correctamente</button>
        <button (click)="errorPuerta()" style="position: relative; right: 450px; top: 100px;">Error en apertura de casillero</button>
    </div>
</div> -->

<div class="wrapper">
    <div class="container">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Casillero abierto</h1>
                <h3>Carga la encomienda y cierra la puerta.</h3>
            </div>
            <div class="col-auto mx-auto">
                <div class="btn-light p-3 pl-5 pr-5 border-lg pb-1">
                    <h3>Puerta</h3>
                    <h1>N° {{pedido.idcasillero}}</h1>
                </div>

            </div>
            <div class="col-12 text-center">
                <h3>En la ilustración se indica ubicación de casillero.</h3>
            </div>
            <div class="col-12">
                <app-locker [Admin]="Admin" [DatosPedido]="pedido" (changeStep)="GetLocker($event)">
                </app-locker>
            </div>


            <div class="col-12 text-center">
                <h3>Número de OT: 7896352169854</h3>
                <div class="row">
                    <div class="col-6 mt-1 rounded shadow mx-auto" style="height: 150px;">
                        <img src="assets/img/ordenTrabajo.PNG" alt="" style="width: 100%; background-size: cover;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100"
                    (click)="finalizarCarga()">Finalizar carga de envío</button>
            </div>
        </div>

    </div> -->
</div>