<header class="mt-4">
    <div class="row text-center" *ngIf="tipoheader()">
        <div class="col-12">
            <img class="logo-index" src="assets/img/logo-chilexpress.svg">
        </div>
    </div>
    <div class="row text-left" *ngIf="!tipoheader()">
        <div class="col-8">
            <div class="row">
                <div class="col-3">
                    <img class="min-chilexpress" src="assets/img/logo-iso_chilexpress_amarillo.svg">
                </div>
                <div class="pl-0 pt-5 col-auto name-user" style="display: flex;" *ngIf="estaAutenticado()">
                    <h4 class="font-weight-semibold">Hola</h4>
                    <h4 class="pl-2">Martín</h4>
                </div>
            </div>
        </div>
        <div class="col-4 pl-5 mt-5" *ngIf="estaAutenticado()">
            <button type="button" class="btn btn-light icon icon-cerrar-sesion" (click)="salir($event)">Cerrar sesión</button>
        </div>
    </div>
</header>