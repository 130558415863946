<!-- <div class="row">
    <button (click)="cerrarSesion()" style="position: relative; left: 2100px; top: 30px;">Cerrar Sesión</button>
    <div class="col-12 text-center mt-5">
      <h1 style="color: black;">¿QUÉ QUIERES HACER?</h1>
      <div class="row mt-5">
        <div class="row" style="position: relative; left: 1040px; top: 60px">
            <a  (click)="entregarEncomienda()">
                <div style="color: black;">Entregar Encomienda</div>
                <img src="./assets/img/entregar.png" style="width: 170px; cursor: pointer;">
            </a>
            <a  (click)="retirarEncomienda()" style="position: relative; left: 150px;">
                <div style="color: black;">Retirar Encomienda</div>
                <img src="./assets/img/retirar.png" style="width: 100px; cursor: pointer;">
            </a>
        </div>
        <div style="position: relative; left: 600px; top: 350px;">
            <a  (click)="retirarPlazoCumplido()" style="position: relative; left: 150px;">
                <div style="color: black;">Retirar Encomiendas con plazo cumplido</div>
                <img src="./assets/img/plazoCumplido.PNG" style="width: 100px; cursor: pointer;">
            </a>
            <a  (click)="revisarCasillero()" style="position: relative; left: 150px; top: 40px;">
                <div style="color: black;">Revisar casillero</div>
                <img src="./assets/img/revisar.PNG" style="width: 100px; cursor: pointer;">
            </a>
            <a  (click)="reportarProblema()" style="position: relative; left: 150px; top: 100px;">
                <div style="color: black;">Reportar problema</div>
                <img src="./assets/img/reportar.PNG" style="width: 100px; cursor: pointer;">
            </a>
        </div>
      </div>
    </div>
    <button (click)="volver()" style="position: relative; left: 1220px; width: 150px; top: 600px;">VOLVER</button>
</div> -->
<div class="wrapper">
    <div class="container mt-5">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold  mb-5">¿Qué quieres hacer?</h1>
                <div class="row mt-5 pt-5">
                    <div class="col-6 pl-5">
                        <a (click)="entregarEncomienda()">
                            <div class="card  box-btn">
                                <div class="row">
                                    <div class="col-7 mt-5 mx-auto">
                                        <img style="width: inherit;" src="assets/img/entregar.svg">

                                    </div>
                                    <div class="col-8 mx-auto mt-4 mb-5">
                                        <h3>Entregar encomienda</h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-6 pr-5">
                        <a (click)="retirarEncomienda()">
                            <div class="card  box-btn">
                                <div class="row">
                                    <div class="col-7 mt-5 mx-auto">
                                        <img style="width: inherit;" src="assets/img/retirar.svg">

                                    </div>
                                    <div class="col-8 mx-auto mt-4 mb-5">
                                        <h3>Retirar encomienda</h3>
                                    </div>
                                </div>

                            </div>
                        </a>
                    </div>
                    <div class="col-11 mx-auto mt-4">
                        <a (click)="retirarPlazoCumplido()">
                            <div class="card  box-btn">
                                <div class="row">
                                    <div class="col-4">
                                        <img style="width: 100px" src="assets/img/retirar-plazo.svg">

                                    </div>
                                    <div class="col-8 text-left pt-3 mt-4">
                                        <h3>Retirar encomiendas<br> con plazo cumplido
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-11 mx-auto mt-4">
                        <a (click)="retirarPlazoCumplido()">
                            <div class="card  box-btn">
                                <div class="row">
                                    <div class="col-4">
                                        <img style="width: 100px" src="assets/img/retirar-plazo.svg">

                                    </div>
                                    <div class="col-8 text-left pt-3 mt-4">
                                        <h3>Descargas de piezas
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-11 mx-auto mt-4">
                        <a (click)="revisarCasillero()">
                            <div class="card  box-btn">
                                <div class="row">
                                    <div class="col-4">
                                        <img style="width: 100px" src="assets/img/revisar-casillero.svg">

                                    </div>
                                    <div class="col-8 text-left pt-4 mt-4">
                                        <h3>Revisar casillero

                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-11 mx-auto mt-4">
                        <a (click)="reportarProblema()">
                            <div class="card  box-btn">
                                <div class="row">
                                    <div class="col-4">
                                        <img style="width: 100px" src="assets/img/reclamo.svg">

                                    </div>
                                    <div class="col-8 text-left pt-4 mt-4">
                                        <h3>Reportar problema

                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>
