<div class="wrapper">
    <div class="container">
        <div class="row text-center mt-4">
            <div class="col-12 text-center mt-5 mb-3" *ngIf="voto==false">
                <h1 class="font-weight-semibold mt-5 rating-tittle">¡Gracias por preferirnos!</h1>

                <h4 class="rating-subt">Ahora queremos conocer tu experiencia con</h4>
                <h4 class="rating-subt">nuestro servicio, con tu respuesta nos</h4>
                <h4 class="rating-subt">ayudarás a mejorar</h4>
            </div>
            <div class="box-rating mx-auto mt-5 pt-4 " *ngIf="voto==false">
                <div class="mt-5">
                    <h3>¿Qué tan satisfecho te encuentras</h3>
                    <h3>con tu experiencia con el servicio de Lockers?</h3>
                </div>
                <div class="mt-5">
                    <!-- <p-rating (click)="voto=true" stars="5" [cancel]="false" [(ngModel)]="puntuacion"></p-rating> -->
                    <span class="start" (click)="voto=true" (mouseover)="puntuacion=1" [ngClass]="{'start-select': puntuacion > 0,'start-Unselect': puntuacion < 0}">★</span>
                    <span class="start" (click)="voto=true" (mouseover)="puntuacion=2" [ngClass]="{'start-select': puntuacion > 1,'start-Unselect': puntuacion <= 1}">★</span>
                    <span class="start" (click)="voto=true" (mouseover)="puntuacion=3" [ngClass]="{'start-select': puntuacion > 2,'start-Unselect': puntuacion <= 2}">★</span>
                    <span class="start" (click)="voto=true" (mouseover)="puntuacion=4" [ngClass]="{'start-select': puntuacion > 3,'start-Unselect': puntuacion <= 3}">★</span>
                    <span class="start" (click)="voto=true" (mouseover)="puntuacion=5" [ngClass]="{'start-select': puntuacion > 4,'start-Unselect': puntuacion <= 4}">★</span>
                </div>
                <div class="col-10 m-auto satisfecho">
                    <div>Muy insatisfecho</div>
                    <div>Muy satisfecho</div>
                </div>
                <div class="btn-omitir">
                    Omitir
                </div>
            </div>

            <div class="box-rating mx-auto mbox-rating pt-4" *ngIf="voto==true">
                <div class="mt-4">
                    <img src="assets/img/ico-ok (2).svg" style="height: 80px;" class="mb-4">
                    <h3>Gracias por tu opinión</h3>
                </div>
                <div class="mt-5">
                </div>
                <div class="col-10 m-auto mt-5 pt-4">
                    <div>
                        <button id="menup" type="button" class="btn btn-primary btn-lg " style="width: 350px;"
                            (click)="cerrarsesion()"> Cerrar sesión </button>
                    </div>
                    <div>
                        <button id="CerrarX" type="button" class="btn btn-outline-primary btn-lg mt-3"
                            style="width: 350px;" (click)="iranicio()" data-dismiss="modal">
                            Ir al inicio </button>
                    </div>

                </div>

            </div>


        </div>
    </div>
    <!-- <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="continuar()">Continuar</button>
            </div>
        </div>
    </div> -->
</div>