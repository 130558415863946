<!-- <div style="color: black; position: relative; top: 50px;">
    <div style="position: relative; left: 1100px;">
        <span style="position: relative; left: 130px;">Pieza 1/18</span><br>
        <h2 style="position: relative; left: 50px;">Casillero Abierto</h2>
        <span>En la ilustración se indica ubicación de casillero</span>
    </div>
    <div style="position: relative; left: 1100px; top: 20px;">
        <h5 style="position: relative; left: 120px;">Puerta</h5>
        <span style="position: relative; left: 130px;">N°15</span><br>
        <span style="position: relative; top: 30px;">Retira la encomienda y cierra la puerta</span>
    </div>
    <img src="./assets/img/casillero.PNG" style="width: 200px; position: relative; left: 1150px; top: 80px;">
    <img src="./assets/img/ordenTrabajo.PNG" style="width: 250px; position: relative; left: 920px; top: 430px;">
    <div style="position: relative; left: 1050px; top: 300px;">
        <span style="color: gray; cursor: pointer;" (click)="imprimirEtiqueta2()"><u>Reimprimir etiqueta</u></span>
        <span style="position: relative; right: 50px; bottom: 200px;">Orden de trabajo encomienda:</span>
        <button (click)="cargaCorrecta()" style="position: relative; right: 400px; top: 60px;">Encomienda retirada correctamente</button>
    </div>
</div> -->


<div class="wrapper">
    <div class="container mt-45">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h3 class="mt-5">Pieza 1/18</h3>
                <h1 class="display-3 font-weight-semibold">Casillero abierto</h1>
                <h3>En la ilustración se indica ubicación de casillero.</h3>
            </div>
            <div class="col-auto mx-auto">
                <div class="btn-light p-3 pl-5 pr-5 border-lg pb-1">
                    <h3>Puerta</h3>
                    <h1>N° {{pedido.idcasillero}}</h1>
                </div>

            </div>
            <div class="col-12 text-center">
                <h3>Carga la encomienda y cierra la puerta.</h3>
            </div>
            <div class="col-12">
                <app-locker [Admin]="Admin" [DatosPedido]="pedido" (changeStep)="GetLocker($event)">
                </app-locker>
            </div>


            <!-- <div class="col-12 text-center mt-4">
                <h3>N° Orden de trabajo</h3>
                <div class="row mt-3">
                    <div class="col-6 mt-1 rounded shadow mx-auto" style="height: 150px;">
                        <img src="assets/img/ordenTrabajo.PNG" alt="" style="width: 100%; background-size: cover;">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="botonera-navegacion" style="bottom: 20px;">
        <div class="row">
            <div class="col-8 mx-auto">
                <a>
                    <h4 class="font-weight-normal mt-4" (click)="imprimirEtiqueta('Imprimiendo')" style="text-decoration: underline;">Reimprimir etiqueta</h4>
                </a>
            </div>
        </div>

    </div> -->
</div>
