import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-encomienda-disponible',
  templateUrl: './encomienda-disponible.component.html',
  styleUrls: ['./encomienda-disponible.component.scss']
})
export class EncomiendaDisponibleComponent implements OnInit {
  datosprc;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
  }

  volver()
  {
    this.router.navigate(['login']);
  }

  retirarEncomienda()
  {
    
    this.router.navigate(['retirarEncomiendaPuerta']);
  }

  pagarEncomienda()
  {
    this.router.navigate(['pagarEncomiendaRetiro']);
  }

}
