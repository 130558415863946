import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { Movimiento } from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Totem } from 'src/app/core/models/totem';

@Component({
  selector: 'app-reporte-por-fecha',
  templateUrl: './reporte-por-fecha.component.html',
  styleUrls: ['./reporte-por-fecha.component.scss'],
})
export class ReportePorFechaComponent implements OnInit {
  
  constructor() 
  {

  }

  async ngOnInit() 
  {
    
  }
}
