<div class="mt-5 ml-5">
    <h6>Enviar encomienda > ... Seleccionar Casillero > <strong> Tamaño Casillero</strong></h6>
</div>
<div class="wrapper">
    <div class="container">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-3">Escoge el tamaño<br> del locker</h1>
                <h3 class="font-weight-normal">La tarifa dependerá del tamaño del<br> casillero que vayas a utilizar.
                </h3>
                <div class="col-12 mx-auto mt-5 mb-2 box-animado-encomienda-1">
                    <img class="inside-pop1" src="assets/img/box.svg">
                </div>
                <h5>Tu encomienda es de 60X20x20cm</h5>
            </div>
            <div class="col-auto mx-auto mt-5 mb-4">
                <div class="btn-light p-4 pl-5 pr-5 border-lg">
                    <h3>Orden de transporte</h3>
                    <h1>568531457452</h1>
                </div>
            </div>
            <div class="col-10 mx-auto">
                <div class="row mb-3">
                    <div class="col-1  mt-4">

                    </div>
                    <div class="col-4 text-center  mt-4">
                        <h6>Dimensiones</h6>
                    </div>
                    <div class="col-4  mt-4 text-center">
                        <h6>Casilleros disponibles</h6>
                    </div>
                </div>
                <label class="container-radio w-100 mb-2">
                    <div class="row">
                        <div class="col-1 border-right mt-4" (click)="escogertama(30)">
                            <input type="radio" checked="checked" name="radio">
                            <span class="ml-1 checkmark"></span>
                        </div>
                        <div class="col-4 border-right mt-4">
                            30x30x30cm
                        </div>
                        <div class="col-4 border-right mt-4">
                            5 Casilleros
                        </div>
                    </div>

                </label>

                <label class="container-radio w-100  mb-2 none-borded">
                    <div class="row">
                        <div class="col-1 border-right mt-4" (click)="escogertama(60)">
                            <input type="radio" name="radio">
                            <span class="ml-1 checkmark"></span>
                        </div>
                        <div class="col-4 border-right mt-4">
                            60x30x30cm
                        </div>
                        <div class="col-4 border-right mt-4">
                            1 Casilleros
                        </div>
                    </div>

                </label>
                <label class="container-radio w-100  mb-2 none-borded">
                    <div class="row">
                        <div class="col-1 border-right mt-4" (click)="escogertama(90)">
                            <input type="radio" name="radio">
                            <span class="ml-1 checkmark"></span>
                        </div>
                        <div class="col-4 border-right mt-4">
                            90x30x30cm
                        </div>
                        <div class="col-4 border-right mt-4">
                            2 Casilleros
                        </div>
                    </div>
                </label>
                <label class="container-radio w-100  mb-2 none-borded">
                    <div class="row">
                        <div class="col-1 border-right mt-4" (click)="escogertama(120)">
                            <input type="radio" name="radio">
                            <span class="ml-1 checkmark"></span>
                        </div>
                        <div class="col-4 border-right mt-4">
                            120x30x30cm
                        </div>
                        <div class="col-4 border-right mt-4">
                            2 Casilleros
                        </div>
                    </div>
                </label>

            </div>

        </div>
    </div>
    <div class="botonera-navegacion mt-2">
        <div class="row">
            <div class="col-8 mx-auto">

                <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="continuar()">Continuar</button>


            </div>
        </div>

    </div>
</div>

<!-- 
<div style="color: black; position: relative; left: 950px; top: 100px;">
    <div>
        <h1>Escoge el tamaño del casillero</h1><br>
        <h3>La tarifa dependerá del tamaño del casillero que vayas a utilizar</h3><br>
        <span><b>Tu encomienda es de 20x20x20</b></span>
    </div>
    <div>
        <h2>Valor a pagar</h2><br>
        <h2>$5.000</h2>
    </div>
    <table>
        <tr>
          <th style="position: relative; right: 60px;">Dimensiones</th>
          <th style="position: relative; right: 40px;">Casilleros Disponibles</th>
          <th style="position: relative; right: 20px;">Valor</th>
        </tr><br>
        <tr>
            <td style="position: relative; right: 60px;">
                <input type="radio" name="chico" value="chico" checked="checked"> 30x30x30cm<br>
            </td>
          <td style="position: relative; right: 40px;">5 Casilleros</td>
          <td style="position: relative; right: 20px;">$ 5.000</td>
        </tr><br>
        <tr>
            <td style="position: relative; right: 60px;">
                <input type="radio" name="mediano" value="mediano"> 60x30x30cm<br>
            </td>
          <td style="position: relative; right: 40px;">4 Casilleros</td>
          <td style="position: relative; right: 20px;">$ 7.000</td>
        </tr><br>
        <tr>
            <td style="position: relative; right: 60px;">
                <input type="radio" name="grande" value="grande"> 90x30x30cm<br>
            </td>
          <td style="position: relative; right: 40px;">4 Casilleros</td>
          <td style="position: relative; right: 20px;">$ 8.000</td>
        </tr>
    </table>
    <br>
    <button (click)="continuar()">CONTINUAR</button>
</div>
 -->