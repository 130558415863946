<div class="d-flex flex-column marginlockr zoomlocker">
    <div class="row separacionL" *ngIf="Admin == 'Cargacli' || Admin == 'Admin'">
        <div *ngFor="let columns of Locker let indexi = index" class="columnalocker border-t border-b">
            <div *ngFor="let casillero of columns.Casilleros let indexj = index" [ngClass]="{'border-f': indexi == 0,'border-l': indexi == Locker.length-1}">
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)" *ngIf="casillero.ocupado" [ngClass]="{ 'CaOXS': casillero.tama == 'xs','CaOS': casillero.tama == 's','CaOM': casillero.tama == 'm','CaOL': casillero.tama == 'l','CaOXL': casillero.tama == 'xl','CaEquipo': casillero.tama == 'xxl'}">
                    <div class="idcasi">{{casillero.id}}</div>
                    <!-- <div class="tamacasi">{{casillero.Alto}}x30x30cm</div> -->
                </div>
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)" *ngIf="!casillero.ocupado" [ngClass]="{ 'CaXS': casillero.tama == 'xs','CaS': casillero.tama == 's','CaM': casillero.tama == 'm','CaL': casillero.tama == 'l','CaXL': casillero.tama == 'xl','CaEquipo': casillero.tama == 'xxl'}">
                    <div class="idcasi">{{casillero.id}}</div>
                    <!-- <div class="tamacasi">{{casillero.Alto}}x30x30cm</div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="row separacionL mt-0" *ngIf="Admin != 'Cargacli'  && Admin != 'Admin' ">
        <div *ngFor="let columns of Locker let indexi = index" class="columnalocker border-t border-b">
            <div *ngFor="let casillero of columns.Casilleros let indexj = index" [ngClass]="{'border-f': indexi == 0,'border-l': indexi == Locker.length-1}">
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)" *ngIf="casillero.ocupado" [ngClass]="{ 'CaOXS2': casillero.tama == 'xs','CaOS2': casillero.tama == 's','CaOM2': casillero.tama == 'm','CaOL2': casillero.tama == 'l','CaOXL2': casillero.tama == 'xl','CaEquipo2': casillero.tama == 'xxl'}">
                    <div class="idcasi idcasi2">{{casillero.id}}</div>
                </div>
                <div class="casilleroLocker" (click)="AbrirLocker(casillero.id)" *ngIf="!casillero.ocupado" [ngClass]="{ 'CaXS2': casillero.tama == 'xs','CaS2': casillero.tama == 's','CaM2': casillero.tama == 'm','CaL2': casillero.tama == 'l','CaXL2': casillero.tama == 'xl','CaEquipo2': casillero.tama == 'xxl'}">
                    <div class="idcasi idcasi2">{{casillero.id}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex cntr-stado-locker" *ngIf="Admin == 'Cargacli'">
        <div class="d-flex w-50 align-items-center">
            <div class="Libre">
            </div>
            <div class="font-weight-bold">
                Disponible
            </div>
        </div>

        <div class="d-flex w-50 align-items-center">
            <div class="Ocupado">
            </div>
            <div class="font-weight-bold">
                No Disponible
            </div>
        </div>
    </div>

    <div class="d-flex cntr-stado-locker-retiro" *ngIf="Admin == 'RetiroCarrier'">
        <div class="d-flex w-50 align-items-center">
            <div class="Libre">
            </div>
            <div class="font-weight-bold">
                Casillero Disponible para retiro
            </div>
        </div>

        <div class="d-flex w-50 align-items-center">
            <div class="Ocupado">
            </div>
            <div class="font-weight-bold">
                Casillero no Disponible
            </div>
        </div>
    </div>
</div>