import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-encomiendas-aretirar-plazo-cumplido',
  templateUrl: './encomiendas-aretirar-plazo-cumplido.component.html',
  styleUrls: ['./encomiendas-aretirar-plazo-cumplido.component.scss']
})
export class EncomiendasARetirarPlazoCumplidoComponent implements OnInit {

  casillero;
  Admin;

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.Admin = false;
  }

  continuar()
  {
    this.router.navigate(['casilleroAbiertoPlazoCumplido']);
  }

  volver()
  {
    this.router.navigate(['identManualPlazoCumplido']);
  }

  async GetLocker(e: any) {
    
    
    this.casillero = e.IdPuerta;
    await this.sweetAlertService.swalInfo("Casillero seleccionado"+this.casillero);

  }

}
