<div class="wrapper">
  <div class="container mt-5">
      <div class="row text-center">
          <div class="col-12 text-center">
              <h1 class="display-3 font-weight-semibold mt-5 mb-5">¿Qué quieres hacer?</h1>
              <div class="row mt-5 pt-5">
                  <div class="col-6 pl-5" (click)="entregarEncomienda()">
                      <a>
                          <div class="card shadow-sm box-btn">
                              <div class="row">
                                  <div class="col-7 mt-5 mx-auto">
                                      <img style="width: inherit;" src="assets/img/entregar.svg">

                                  </div>
                                  <div class="col-8 mx-auto mt-4 mb-5">
                                      <h3>Enviar encomienda</h3>
                                  </div>
                              </div>
                          </div>
                      </a>
                  </div>
                  <div class="col-6 pr-5" (click)="retirarEncomienda()">
                      <a>
                          <div class="card shadow-sm box-btn">
                              <div class="row">
                                  <div class="col-7 mt-5 mx-auto">
                                      <img style="width: inherit;" src="assets/img/retirar.svg">

                                  </div>
                                  <div class="col-8 mx-auto mt-4 mb-5">
                                      <h3>Retirar encomienda</h3>
                                  </div>
                              </div>

                          </div>
                      </a>
                  </div>
              </div>

          </div>


      </div>
  </div>
</div>

<!-- <div class="row">
    <button (click)="cerrarSesion()" style="position: relative; left: 2100px; top: 30px;">Cerrar Sesión</button>
    <div class="col-12 text-center mt-5">
      <h1 style="color: black;">¿QUÉ QUIERES HACER?</h1>
      <div class="row mt-5">
        <div class="row" style="position: relative; left: 820px; top: 180px">
            <a  (click)="entregarEncomienda()">
                <div style="color: black;">Entregar Encomienda</div>
                <img src="./assets/img/entregar.png" style="width: 400px;">
            </a>
            <a  (click)="retirarEncomienda()" style="position: relative; left: 150px;">
                <div style="color: black;">Retirar Encomienda</div>
                <img src="./assets/img/retirar.png" style="width: 330px;">
            </a>
        </div>
      </div>
    </div>
    <button (click)="volver()" style="position: relative; left: 1220px; width: 150px; top: 350px;">VOLVER</button>
</div> -->
