<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div style="position: relative; left: 1100px; font-size: 30px; font-weight: bold;">
        <label for="" style="color: black;">CHILEXPRESS</label>
    </div>
    <label for="" style="color: black; position: relative; left: 1010px;">-------------------------------------------------------------------------</label>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Actualizar Portador</label><br>
            <input type="text" disabled placeholder="POST" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ActualizarPortador()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">GetPortador</label><br>
            <input [formControl]="portador" id="portador" placeholder="Portador" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="GetPortador()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Crear Cabecera Carro</label><br>
            <input type="text" disabled placeholder="POST" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="CrearCabeceraCarro()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Crear Detalle Carro</label><br>
            <input type="text"  disabled placeholder="NO FUNCIONA" style="width: 300px;">
            <button style="position: relative; left: 200px;" disabled>ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Actualizar Cabecera</label><br>
            <input type="text" disabled placeholder="POST" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ActualizarCabecera()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Valida TTC</label><br>
            <input type="text" [formControl]="numot" id="numot"  placeholder="Número OT" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ValitTCC()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Lista Coberturas</label><br>
            <input type="text" [formControl]="type" id="type" placeholder="TIPO" style="width: 300px;"><br>
            <input type="text" [formControl]="regionCode" id="regionCode" placeholder="REGION" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ListaCoberturas()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Cotizador</label><br>
            <input type="text" [formControl]="ciudadOrigen" id="ciudadOrigen" placeholder="Ciudad Orgen" style="width: 300px;"><br>
            <input type="text" [formControl]="ciudadDestino" id="ciudadDestino" placeholder="Ciudad Destino" style="width: 300px;"><br>
            <input type="text" [formControl]="codProducto" id="codProducto" placeholder="Codigo producto" style="width: 300px;"><br>
            <input type="text" [formControl]="peso" id="peso" placeholder="Peso" style="width: 300px;"><br>
            <input type="text" [formControl]="largo" id="largo" placeholder="Largo" style="width: 300px;"><br>
            <input type="text" [formControl]="ancho" id="ancho" placeholder="Ancho" style="width: 300px;"><br>
            <input type="text" [formControl]="alto" id="alto" placeholder="Alto" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="Cotizador()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Servicios Adicionales</label><br>
            <input type="text" [formControl]="codProducto" id="codProducto" placeholder="Codigo producto" style="width: 300px;"><br>
            <input type="text" [formControl]="ciudadOrigen" id="ciudadOrigen" placeholder="Ciudad Orgen" style="width: 300px;"><br>
            <input type="text" [formControl]="ciudadDestino" id="ciudadDestino" placeholder="Ciudad Destino" style="width: 300px;">   <br>        
            <input type="text" [formControl]="peso" id="peso" placeholder="Peso" style="width: 300px;"><br>
            <input type="text" [formControl]="alto" id="alto" placeholder="Alto" style="width: 300px;"><br>
            <input type="text" [formControl]="ancho" id="ancho" placeholder="Ancho" style="width: 300px;"><br>
            <input type="text" [formControl]="largo" id="largo" placeholder="Largo" style="width: 300px;"><br>
            <input type="text" [formControl]="codFormaPago" id="codFormaPago" placeholder="Cod Forma Pago" style="width: 300px;"><br>
            <input type="text" [formControl]="valorDeclarado" id="valorDeclarado" placeholder="Valor Declarado" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ServiciosAdicionales()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Obtener Pagos</label><br>
            <input type="text" [formControl]="idcarro" id="idcarro" placeholder="Id Carro" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ObtenerPagos()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Obtener Transaccion</label><br>
            <input type="text" [formControl]="idcarro" id="idcarro" placeholder="Id Carro" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ObtenerTransaccion()">ENVIAR</button>
        </div>
    </div>  
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Actualizar Usuario</label><br>
            <input type="text" disabled placeholder="POST " style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ActualizarUsuario()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Validar Usuario</label><br>
            <input type="text" disabled placeholder="POST" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="ValidarUsuarios()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Obtener Boleta</label><br>
            <input type="text" placeholder="Tipo Documento" style="width: 300px;"><br>
            <input type="text" placeholder="Número Documento" style="width: 300px;"><br>
            <select name="ESTADOS">
                <option value="true" style="color: green;">true</option>
                <option value="false" style="color: red;">false</option>
            </select>
            <button style="position: relative; left: 200px;" (click)="ObtenerBoleta()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Generar Boleta</label><br>
            <input type="text" disabled placeholder="POST" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="GenerarBoleta()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Token</label><br>
            <input type="text" [formControl]="GlsUsuario" id="GlsUsuario" placeholder="GLS Usuario" style="width: 300px;"><br>
            <input type="text" [formControl]="CodSistema" id="CodSistema" placeholder="Codigo sistema" style="width: 300px;"><br>
            <input type="text" [formControl]="Version" id="Version" placeholder="Version" style="width: 300px;">
            <button style="position: relative; left: 200px;" (click)="Token()">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Recepcion OT</label><br>
            <input type="text" disabled placeholder="POST" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Carga Recorrido</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Carga Vehiculo</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Consultar Oficina</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Transferencia OT</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Retencion OT</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">GenTriada</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Validacion Carrier</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>
    <div class="row">
        <div style="position: relative; left: 750px;">
            <label for="" style="color: black; font-weight: bold;">Consulta Detalle OT</label><br>
            <input type="text" placeholder="Ingrese los datos" style="width: 300px;">
            <button style="position: relative; left: 200px;">ENVIAR</button>
        </div>
    </div> 
    <hr size="5px" width="50%" align="center" color="red"/>
    <br>

</div>