<div class="mt-5 ml-5">
    <h6>Enviar Encomienda > Escanear OT > <strong> Imprimir Etiqueta</strong></h6>
</div>

<div class="wrapper">
    <div class="container ">
        <div class="row text-center">
            <div class="col-12 text-center">
                <h1 class="display-3 font-weight-semibold mt-5">Escanea e ingresa</h1>
                <h3 class="font-weight-normal">Escanea el código QR que se generó en la App o la etiqueta Chilexpress enviada a tu email en el<br> lector e ingresa la encomienda.</h3>
                <a>
                    <h4 class="font-weight-normal" (click)="scanNumOrdenTransporte()" style="text-decoration: underline;">Ingresa con número de Orden de Transporte</h4>
                </a>

                <h4 class="mt-5 pt-5">Número de OT</h4>
                <h1 class="mt-n2">{{datosprc["not"]}}</h1>
                <div class="col-6 mx-auto mt-5 box-animado-encomienda">
                    <img style="height: 120px;" class="inside-pop" src="assets/img/box.svg">

                </div>
                <table class="mx-auto mb-5">
                    <tr>
                        <td class="text-left" style="width: 200px">
                            <h5>Dimensiones</h5>

                        </td>
                        <td class="text-left">
                            <h5>{{datosprc["glsDimensiones"]}}</h5>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">
                            <h5>Destino</h5>

                        </td>
                        <td class="text-left">
                            <h5>{{datosprc["destino"]}}</h5>
                        </td>
                    </tr>

                </table>

                <a>
                    <h4 class="font-weight-normal" style="text-decoration: underline;" (click)="imprimirEtiqueta('imprimiendo')">Imprimir etiqueta</h4>
                </a>


            </div>


        </div>
    </div>
    <div class="botonera-navegacion">
        <div class="row">
            <div class="col-8 mx-auto">
                <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="Siguiente()">Continuar</button>
            </div>
        </div>
    </div>

    <div class="text-center justify-content-around opciones-menu">
        <p>Menu navegación (solo para revision de flujo)</p>
        <div (click)="Errorimpresion()">ERROR DE IMPRESORA</div>
    </div>
</div>

<!-- <button (click)="volver()" style="position: relative; left: 1600px; top: 50px;">CERRAR SESIÓN</button>
<div class="row" style="position: relative; top: 100px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea e ingresa</div>
        <span style="color: black; position: relative; left: 860px;">Escanea el código QR que se generó en la app o la etiqueta Chilexpress enviada a tu email en el lector e ingresa la encomienda</span>
      </div>
    </div>
    <a (click)="scanNumOrdenTransporte()" style="position: relative; left: 1130px; cursor: pointer; top: 30px;">
        <span style="color: blue;">Ingresar con número de orden de transporte</span> 
    </a>
    <img src="./assets/img/paquete.PNG" style="position: relative; left: 900px; top: 60px;">
    <span style="color: blue; position: relative; left: 730px; top: 400px; cursor: pointer;" (click)="imprimirEtiqueta()">Imprimir etiqueta</span> 
    <div>
        <button (click)="continuar()" style="position: relative; left: 620px; top: 500px;">CONTINUAR</button><br>
        <span style="color: blue; position: relative; left: 1000px; top: 600px; cursor: pointer;" (click)="pago()">Pieza requiere pago</span>
    </div>
</div> -->