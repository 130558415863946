import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router'
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-pin-validacion',
  templateUrl: './pin-validacion.component.html',
  styleUrls: ['./pin-validacion.component.scss']
})
export class PinValidacionComponent implements OnInit {
  Enconfig: boolean;
  numberbutton;

  constructor(private router: Router, private EmitTecladoService: EmitTecladoService, private sweetAlertService: SweetAlertService) { }

  shoekb;
  tipok;
  subscription: Subscription = new Subscription();
  codigo = new FormControl('');
  tipoAccion="";
  MigaPan="";

  ngOnInit(): void {
    this.Enconfig = true;
    this.shoekb = true;
    this.ConfigurarTeclado();
    var esto = this;

    this.tipoAccion = sessionStorage.getItem("TipoAccion");
    if (this.tipoAccion == "EntregaCli") {
      this.MigaPan = "Enviar Encomienda"
    }
    if (this.tipoAccion == "RetiroCli") {
      this.MigaPan = "Retiro Encomienda"
    } 
    if (this.tipoAccion == "CargaCarrier") {
      this.MigaPan = "Cargar Encomienda"
    } 
 
  }

  volver() {
    this.router.navigate(['menuOpciones']);
  }

  async email() {
    var respPin = await this.sweetAlertService.ReEnviarPinValidacionCHE();
    if (respPin == true) {
      this.sweetAlertService.ReenviandoPinChe();
      await this.delay(5000);
      this.sweetAlertService.swalClose();
      //this.router.navigate(['encomiendaDisponible']);
    }
    
  }

  async DondeOrden(){
    var resp = await this.sweetAlertService.DondeEncuentroPIN();
  }

  async continuar()
  {
    if(this.codigo.value == 123)
    { 
        this.router.navigate(['encomiendaDisponible']);
      //console.log("NUMERO", this.codigo.value);
    }
    else if(this.codigo.value === '')
    {
      await this.sweetAlertService.PinnoAdmitidaCHE();
    }
    else if(this.codigo.value !== 123)
    {
      await this.sweetAlertService.PinnoAdmitidaCHE();
    }
  }

  desplegarteclado(): void {
    this.shoekb = true;
  }

  borar() {
    if (this.codigo.value != "") {
      this.codigo.setValue(this.codigo.value.substring(0, this.codigo.value.length - 1))
    }
  }

  setnota(número) {
    this.codigo.setValue(this.codigo.value + número);
  }


  ConfigurarTeclado() {
    this.numberbutton = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", ""];
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
