<!-- <div class="row">
    <div class="col-12 text-center mt-5">
      <img src="./assets/img/insertar.gif">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Por favor inserte su carnet de identidad</div>
      </div>
    </div>
    <div class="row" style="position: relative; left: 1050px; top: 70px;">
    <button (click)="GetPortador()" style="position: relative; right: 60px;">PRESIONE PARA CONTINUAR</button>
    <button (click)="volver()" style="position: relative; right: 30px;">VOLVER</button>
    <button (click)="cedulaError()">ERROR DE LECTURA</button>
    </div>
</div> -->

<div class="wrapper" *ngIf="TipoAuth == 'cedula'">
    <div class="container cedula-class">
        <div class="row text-center">
            <div class="col-12">

                <p class="subtitle">Inserta tu cédula de identidad<br> en la ranura.</p>
            </div>
            <div class="col-12">
                <img src="assets/img/animacion-cedula.gif">
            </div>
        </div>
    </div>
    <div class="text-center justify-content-around opciones-menu">
        <p>Menu navegación (solo para revision de flujo)</p>
        <div (click)="GetPortador()">PRESIONE PARA CONTINUAR</div>
        <div (click)="volver()">VOLVER</div>
        <div (click)="cedulaError()">ERROR DE LECTURA</div>
    </div>

</div>

<div class="wrapper" *ngIf="TipoAuth == 'huella'">
    <div class="container cedula-class">
        <div class="row text-center">
            <div class="col-12">

                <p class="subtitle">Apoya tu dedo índice<br> en el lector.</p>
            </div>
            <div class="col-12 ">
                <img class="mt-n5 huella" src="assets/img/animacion-huella.gif">
            </div>

        </div>
    </div>
    <div class="text-center justify-content-around opciones-menu">
        <p>Menu navegación (solo para revision de flujo)</p>
        <div (click)="continuar()">PRESIONE PARA CONTINUAR</div>
        <div (click)="volver()">VOLVER</div>
        <div (click)="HuellaError()">ERROR DE LECTURA</div>
    </div>
</div>