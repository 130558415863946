import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-qr-escaneado2',
  templateUrl: './qr-escaneado2.component.html',
  styleUrls: ['./qr-escaneado2.component.scss']
})
export class QrEscaneado2Component implements OnInit {

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.sweetAlertService.swalClose();
  }

  scanQr()
  {
    this.router.navigate(['numOrdenTransporte']);
  }

  piezaCorrecta()
  {
    this.router.navigate(['casilleroAbierto2']);
  }

  encomiendaSinAsignar()
  {
    this.router.navigate(['qrEscaneado2']);
  }

  finalizarCarga()
  {
    this.router.navigate(['escanearQRFinalizado']);
  }

}
