import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Router } from '@angular/router';
import {login} from '../../models/login';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private token: string;
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private Router: Router
    ) { }

    async login(request: login): Promise<any> {
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['api_efectivo']}/Usuario/Login`;
      //const url = "http://172.16.32.56/efectivo/api/Usuario/Login"+this.login
      try {
        return await this.httpClient.post(url, request, { headers: this.headers })
          .toPromise();
      } catch (error) {
        return {
          status: true,
          code: 200,
          message: 'Error al ejecutar la petición.',
        };
      }
    }

    logout() {
        this.token = '';
        sessionStorage.removeItem('token_api_usabilidad');
        sessionStorage.removeItem('expToken_api_usabilidad');
        sessionStorage.removeItem('refreshToken_api_usabilidad');
        sessionStorage.removeItem('autenticado');
        this.Router.navigate(['/login']);
      }

    leerToken(): void {
        if (sessionStorage.getItem('token_api_usabilidad') && sessionStorage.getItem('refreshToken_api_usabilidad')) {
          this.token = sessionStorage.getItem('token_api_usabilidad');
        } else {
          this.token = '';
        }
      }

    estaAutenticado(): boolean {
        return sessionStorage.getItem('autenticado') === 'true' ? true : false;
      }

      // Almacena en el sessionStorage el token
  guardarTokenStorage(token: string): void {
    this.token = token;
    sessionStorage.setItem('token_api_usabilidad', token);
  }
}
