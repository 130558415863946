import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class PruebaService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }

    async administracionZonas(adminZ): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionZonas`
        try {
            var resp = await this.httpClient.post(url, adminZ, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionRegiones(adminR): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionRegiones`
        try {
            var resp = await this.httpClient.post(url, adminR, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionComunas(adminC): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionOficinas`
        try {
            var resp = await this.httpClient.post(url, adminC, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionOficinas(adminO): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionOficinas`
        try {
            var resp = await this.httpClient.post(url, adminO, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionClientes(adminCli): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionClientes`
        try {
            var resp = await this.httpClient.post(url, adminCli, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionUsuarios(adminU): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionUsuarios`
        try {
            var resp = await this.httpClient.post(url, adminU, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionUsuOficinas(adminUsuO): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionUsuOficinas`
        try {
            var resp = await this.httpClient.post(url, adminUsuO, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionLockers(adminL): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionLockers`
        try {
            var resp = await this.httpClient.post(url, adminL, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionCasilleros(adminCas): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/AdministracionCasilleros`
        try {
            var resp = await this.httpClient.post(url, adminCas, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionGetDatos(adminGetD): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/GetDatos`
        try {
            var resp = await this.httpClient.post(url, adminGetD, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async administracionUpdEstados(adminUpdE): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Administrar/UpdEstados`
        try {
            var resp = await this.httpClient.post(url, adminUpdE, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async servicio11(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async servicio12(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async servicio13(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async servicio14(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async servicio15(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    //--- manejo de locker 
    async casillerosDisponibles(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Info/CantidadTotalDisponible?idOficina=${API_URL['idoficina']}&idLocker=${API_URL['idlocker']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async tipoCasilletodisp(tipo): Promise<any> {
     console.log("Consultar tipo Casillero disp");
     
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Info/CasillerosDisponibles?idOficina=${API_URL['idoficina']}&idLocker=${API_URL['idlocker']}&TipoCasillero=${tipo}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async InsertHistorial(OTbd): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Historial/InsertHistorial`
        try {
            var resp = await this.httpClient.post(url, OTbd, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async CargaPedidoLOcker(OTbd): Promise<any> {
        console.log("Consultar cargar pedidos");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Flujo/RealizarCarga`
        try {
            var resp = await this.httpClient.post(url, OTbd, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async RetirarPedidoLOcker(OTbd): Promise<any> {
        console.log("Consultar retirar pedidos");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Flujo/RealizarRetiro?qr=${OTbd}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async RetirarEmpleado(): Promise<any> {
        console.log("Consultar pedidos a retirar Carrier");
        
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Info/RetirarEmpleado?idOficina=${API_URL['idoficina']}&idLocker=${API_URL['idlocker']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async RetirarCliente(): Promise<any> {
        console.log("Consultar pedidos a retirar CLiente");
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_nueva']}/Info/RetirarCliente?idOficina=${API_URL['idoficina']}&idLocker=${API_URL['idlocker']}`
        try {
            var resp = await this.httpClient.get(url, { headers: this.headers }).toPromise();
            //console.log("respuesta de servicio: ", resp);
            return resp
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }


}