<a class="text-decoration-none" (click)="change()">
  <div class="row">
    <div class="col-12 text-center mt-5">
      <img src="./assets/img/touch.gif">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower">Toque la pantalla para continuar</div>
        <h2 style="color: black; position: relative; left: 1200px;">SEGUNDO FLUJO</h2>
      </div>
    </div>
  </div>
</a>

<div class="btn-izq" (click)="izqbtn()">

</div>

<div class="btnder btn-izq" (click)="derbtn()">

</div>


