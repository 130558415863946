import { ChilexpressService } from 'src/app/core/services/chilexpress/chlexpress.service';
import { Router } from '@angular/router'
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MocService } from 'src/app/core/services/miscellaneous/moc.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-cedula',
  templateUrl: './cedula.component.html',
  styleUrls: ['./cedula.component.scss']
})
export class CedulaComponent implements OnInit {

  portador: any;
  subscription: Subscription = new Subscription();

  constructor(
    private chilexpressService: ChilexpressService, 
    private router: Router, 
    private sweetAlertService: SweetAlertService,
    private mocService: MocService) { }

  TipoAuth = "cedula";
  finger;
  Rutcliente;
  statusAuth;

  ngOnInit(): void {
    this.initMoc();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //Metodos para autenticar


  async initMoc() {
    var url = await this.mocService.geturl();
    this.subscription = this.mocService.init(url).subscribe(
      (resp: any) => {
        const respJson = JSON.parse(resp.data);

        if (respJson.Error.Code === 0) {
          this.validaAction(respJson);
        } else {
          this.sweetAlertService.ErrorCarnet();
        }

      }, (err) => {
        //console.log(err.data);
        var respuesta = JSON.parse(err.data);
        //console.log(respuesta);
        this.sweetAlertService.ErrorCarnet();
      }
    );
  }

  validaAction(response: any) {
    switch (response.Action) {
      case 100:
        this.Rutcliente = response.Rut;
        localStorage.setItem('RutCliente', response.Rut.replace('-', ''));
        break;
      case 101:
        this.finger = response.Finger;
       // this.view = true;
       this.TipoAuth = "huella";
        break;
      case 102:
        this.validaResult(response);
        break;
      default:
        break;
    }
  }

  validaResult(response: any) {
    switch (response.Result) {
      case 'S':
        this.statusAuth = '3';

        //this.sweetAlertService.swalSuccess("Huella leida correctamente");
        this.continuar();
        break;
      case 'N':
        this.statusAuth = '2';
        this.sweetAlertService.ErrorHuella();
        break;
      default:
        break;
    }
  }




  //-----------------------


  async GetPortador()
  {
    var filtros = {
      portador: 77170488
    }

    //var resp = await this.chilexpressService.getPortador(filtros);
    var resp = true;
    if(resp)
    {
      this.TipoAuth = "huella";
    }
    
  }

  volver()
  {
    this.router.navigate(['']);
  }

  cedulaError()
  {
        this.sweetAlertService.ErrorCarnet();
  }

  HuellaError()
  {
        this.sweetAlertService.ErrorHuella();
  }

  continuar()
  {
    sessionStorage.setItem('autenticado',"true");
    this.router.navigate(['autenticado']);
  }



}