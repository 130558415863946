<div class="mt-5 ml-5">
  <h4>{{MigaPan}}> Escanear OT> <strong> Ingresar PIN</strong></h4>
</div>
<div class="wrapper">
  <div class="container mt-5">
      <div class="row text-center">
          <div class="col-12 text-center">
              <h1 class="display-3 font-weight-semibold mt-5">Ingresa tu código de acceso</h1>
              <a >
                  <h4 class="font-weight-normal" style="text-decoration: underline;" (click)="DondeOrden()">¿Dónde encuentro mi pin de validación?</h4>
              </a>
          </div>
          <div class="col-6 mx-auto mt-5">
            <form>

              <div class="form-group">
                <!-- <label for="exampleInputEmail1"><b>Label del input</b></label> -->
                <input type="text" [formControl]="codigo" class="form-control form-control-lg text-center" placeholder="Clave de Acceso">
                <!-- <small class="form-text">We'll never share your email with anyone else.</small> -->
              </div>
    
            </form>
            <div class="row col-11 pl-2 mx-auto">
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(1)">
                  1
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(2)">
                  2
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(3)">
                  3
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(4)">
                  4
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(5)">
                  5
                </div>
    
              </div>
              <div class="col-4">
               <div class="tecla-key btn-light" (click)="setnota(6)">
                  6
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(7)">
                  7
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(8)">
                  8
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="setnota(9)">
                  9
                </div>
    
              </div>
              <div class="col-4 offset-4">
                <div class="tecla-key btn-light" (click)="setnota(0)">
                  0
                </div>
    
              </div>
              <div class="col-4">
                <div class="tecla-key btn-light" (click)="borar()">
                  <img style="height: 23px;margin-bottom: 5px;" src="assets/img/backspace.svg">
                </div>
    
              </div>
            </div>
          </div>
    
    
        </div>
      </div>
  <div class="botonera-navegacion">
      <div class="row">
          <div class="col-8 mx-auto">
              <a>
                  <h4 class="font-weight-normal" (click)="email()" style="text-decoration: underline;">¿Desea reenviar su pin de validación?</h4>
              </a>
              <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="continuar()">Continuar</button>
              <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100" (click)="volver()">Volver</button>

          </div>
      </div>

  </div>
</div>


<!-- <div class="d-flex tecladoshow" *ngIf="shoekb">
    <div class="col-12 centrar-teclado" >
        <!-- Teclado 
        <div class="caja-botones col-12">
            <div class=" boton col-4" *ngFor="let number of numberbutton; index as i; last as last">
                <div *ngIf="!last" class="contenido-boton text-center animated bounceIn" (click)="setnota(i)">
                    {{number}}</div>
                <div *ngIf="last" class="contenido-boton text-center button-borrar bgi-del"
                    (click)="borar()"><div><i class="pi pi-chevron-left"></i></div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <div class="row">
      <div class="col-12 text-center mt-5">
        <div class="row mt-5">
          <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Ingresa tu código de acceso</div>
        </div>
        <div style="color: black; position: relative; right: 30px; top: 30px;">¿Dónde encuentro mi pin de validación?</div>
        <div style="position: relative; right: 20px; top: 60px;">
          <input type="text" placeholder="ingrese código" [formControl]="codigo">
        </div>
      </div>
  </div>
  <div class="row">
    <div style="color: blue; position: relative; left: 1150px; top: 730px; cursor: pointer;" (click)="email()">¿Desea reenviar su pin de validación?</div>
    <button style="width: 330px; position: relative; left: 650px; top: 800px;" (click)="continuar()">PRESIONE PARA CONTINUAR</button>
    <button style="width: 330px; position: relative; left: 700px; top: 800px;" (click)="volver()">VOLVER</button>
  </div> -->