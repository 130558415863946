<!-- <div style="position: relative; top: 50px;">
    <div class="col-12 text-center mt-5">
      <div class="row mt-5">
        <div class="col-12 display-1 animated bounceIn infinite slower" style="color: black;">Escanea el código QR</div>
        <span style="color: black; position: relative; left: 860px;">Escanea el código QR pegado en la encomienda o ingrese su número de orden de transporte para cargar la pieza </span>
      </div>
    </div>
    <img src="./assets/img/leerCodigoQR.PNG" style="width: 330px; position: relative; left: 1050px;">
    <div style="color: black; position: relative; left: 1100px;">
        <h3>Nombre OC</h3><br>
        <h2>Locker Bandera</h2>
    </div>
    <div style="position: relative; left: 1050px; top: 100px;">
        <button (click)="scanQr()" style="position: relative; left: 50px;">CARGA MANUAL DE PRODUCTO</button><br>
        <button (click)="finalizarCarga()" style="position: relative; left: 50px; top: 60px;">FINALIZAR CARGA DE PIEZAS</button>
    </div>
    <div style="position: relative; left: 1050px; top: 200px;">
        <button (click)="piezaCorrecta()" style="position: relative; left: 50px;">CASILLERO ASIGNADO CORRECTAMENTE</button><br>
        <button (click)="encomiendaSinAsignar()" style="position: relative; left: 50px; top: 60px;">PIEZA NO TIENE CASILLERO ASIGNADO</button>
    </div>
</div> -->

<div class="wrapper">
  <div class="container mt-5">
      <div class="row text-center">
          <div class="col-12 text-center">
              <h1 class="display-3 font-weight-semibold mt-5">Escanea la etiqueta</h1>
              <h3 class="font-weight-normal">Escanea la etiqueta pegada en la encomienda o ingrese su número de orden de transporte<br> para cargar pieza</h3>
              <img class="mt-5 pt-5 inside-img" src="assets/img/box-entrega.png" style="width: 65%; background-size: cover;">
          </div>


      </div>
  </div>
  <div class="botonera-navegacion">
      <div class="row">
          <div class="col-8 mx-auto">
              <button type="button" class="btn btn-primary btn-lg mt-4 w-100" (click)="scanQr()">Carga manual de producto</button>
              <button type="button" class="btn btn-outline-primary btn-lg mt-4 w-100" (click)="finalizarCarga()">Finalizar carga de piezas</button>
          </div>
      </div>

  </div>

</div>