import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-encomienda-aretirar',
  templateUrl: './encomienda-aretirar.component.html',
  styleUrls: ['./encomienda-aretirar.component.scss']
})
export class EncomiendaARetirarComponent implements OnInit {

  casillero;
  Admin;
  detallescarro = []

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    
    this.detallescarro = JSON.parse(localStorage.getItem("detallesCarro"));
    //console.log(this.detallescarro );
    
    this.Admin = "Precarga";
  }

  continuar()
  {
    this.router.navigate(['casilleroAbiertoRetiro']);
  }

  volver()
  {
    this.router.navigate(['identManualDescarga']);
  }

  async GetLocker(e: any) {
    
    
    this.casillero = e.IdPuerta;
    await this.sweetAlertService.swalInfo("Casillero seleccionado"+this.casillero);

  }

}
