import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-reportar-problema',
  templateUrl: './reportar-problema.component.html',
  styleUrls: ['./reportar-problema.component.scss']
})
export class ReportarProblemaComponent implements OnInit {

  Enconfig: boolean;
  numberbutton;

  constructor(private router: Router, private emitTecladoService: EmitTecladoService, private sweetAlertService: SweetAlertService) { }

  shoekb;
  tipok;
  subscription: Subscription = new Subscription();
  rut = new FormControl('');
  clave = new FormControl('');

  ngOnInit(): void {
    this.Enconfig = true;
    this.shoekb = true;
    this.ConfigurarTeclado();
    var esto = this;
  }

  volver() {
    this.router.navigate(['menuOpciones2']);
  }

  async enviar() {
    if (this.rut.value) {
      this.sweetAlertService.EnvioProbleReporte();
      await this.delay(5000);
      this.sweetAlertService.swalClose();
      this.router.navigate(['menuOpciones2']);
      //console.log("NUMERO", this.rut.value);
    }
    else if (this.rut.value === '') {
      await this.sweetAlertService.DatosnoAdmitidaCHE()
    }
  }

  desplegarteclado(): void {
    this.shoekb = true;
  }

  borar() {
    if (this.rut.value != "") {
      this.rut.setValue(this.rut.value.substring(0, this.rut.value.length - 1))
    }
  }

  setnota(número) {
    this.rut.setValue(this.rut.value + número)
  }

  ConfigurarTeclado() {
    this.numberbutton = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "a", "s", "d", "f", "g", "h", "j", "k", "l", "ñ", "z", "x", "c", "v", "b", "n", "m", "-", ""];
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
