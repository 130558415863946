import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-seleccionar-locker-revisar-casillero',
  templateUrl: './seleccionar-locker-revisar-casillero.component.html',
  styleUrls: ['./seleccionar-locker-revisar-casillero.component.scss']
})
export class SeleccionarLockerRevisarCasilleroComponent implements OnInit {

  casillero;
  Admin;

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.Admin = "Admin";
  }

  continuar()
  {
    this.router.navigate(['escanearQRFinalizado']);
  }

  async GetLocker(e: any) {
    
    
    this.casillero = e.IdPuerta;
    await this.sweetAlertService.swalInfo("Casillero seleccionado"+this.casillero);

  }

}
