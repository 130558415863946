import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-escoger-tamano-casillero',
  templateUrl: './escoger-tamano-casillero.component.html',
  styleUrls: ['./escoger-tamano-casillero.component.scss']
})
export class EscogerTamanoCasilleroComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  continuar()
  {
    this.router.navigate(['seleccionarCasillero']);
  }
  escogertama(tama){
    localStorage.setItem('TipoCasSelt',tama);
  }


}
