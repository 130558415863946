import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-seleccionar-casillero',
  templateUrl: './seleccionar-casillero.component.html',
  styleUrls: ['./seleccionar-casillero.component.scss']
})
export class SeleccionarCasilleroComponent implements OnInit {

  casillero;
  Admin;
  TipoCasSelt

  constructor(private router: Router, private sweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.TipoCasSelt = localStorage.getItem("TipoCasSelt")
    this.Admin = "Cargacli";
  }

  continuar()
  {
    this.router.navigate(['encomiendaIngresada']);
  }

  volver()
  {
    this.router.navigate(['tamanoCasillero']);
  }

  async GetLocker(e: any) {
    this.casillero = e.IdPuerta;
    if (e.step== "Pedidohecho") {
      this.router.navigate(['encomiendaIngresada']);
    } 
    else if (e.step== "CambioCasillero") {
      this.router.navigate(['tamanoCasillero']);
    } 
    else if (e.step== "PedidoError") {
      this.router.navigate(['menuOpciones']);
    } 
  }

}
