import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio2',
  templateUrl: './inicio2.component.html',
  styleUrls: ['./inicio2.component.scss']
})
export class Inicio2Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    sessionStorage.setItem('autenticado',"true");
  }

  continuar()
  {
    this.router.navigate(['menuOpciones2']);
  }

  volver()
  {
    this.router.navigate(['login2']);
  }


}
