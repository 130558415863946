import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChilexpressService } from '../../core/services/chilexpress/chlexpress.service';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-menu-opciones',
  templateUrl: './menu-opciones.component.html',
  styleUrls: ['./menu-opciones.component.scss']
})
export class MenuOpcionesComponent implements OnInit {

  datosprc;
  DatosConfig = {};

  constructor(private router: Router, private ChilexpressService: ChilexpressService, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
    this.datosprc = JSON.parse(localStorage.getItem("datosproc"));
    this.CrearCabeceraCarro();
  }

  volver() {
    this.router.navigate(['autenticado']);
  }

  cerrarSesion() {
    this.router.navigate(['login']);
  }

  entregarEncomienda() {
    sessionStorage.setItem("TipoAccion", "EntregaCli");
    this.router.navigate(['escanearQR']);
  }

  retirarEncomienda() {
    sessionStorage.setItem("TipoAccion", "RetiroCli");
    this.router.navigate(['numOrdenTransporte']);
  }

  async CrearCabeceraCarro() {
    this.DatosConfig = await this.ChilexpressService.GetConfig();
    var crearcarro = {
      "carro": {
        "idCarro": 0,
        "idPortador": 0,// rut de la persona en locker
        "tipoOperacion": 0,//se crea con 0 y se actualiza luego la cabecera
        "codOficina": this.DatosConfig["CodOfilocal"],
        "estado": 1,
        "registroGrabacion": "",
        "folioRetiro": 0
      }
    }
    var respcar = await this.ChilexpressService.crearCabeceraCarro(crearcarro);
    if (respcar["status"] == true) {
      this.datosprc["idcarro"] = respcar["data"][0]["idCarro"];
      //console.log(this.datosprc);
      localStorage.setItem("datosproc",JSON.stringify(this.datosprc));

    }
  }

}
